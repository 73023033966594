import React, { useContext, useState } from 'react'
import style from './Quality.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import iso1 from '../../assets/images/download.webp'
import iso2 from '../../assets/images/images.webp'
import iso3 from '../../assets/images/snapedit_1736670961138.webp'
import iso4 from '../../assets/images/1675598209557.webp'
import sacLogo from '../../assets/images/Sac_logo.webp'
import certificate1 from '../../assets/images/ISO_9001[1].webp'
import certificate2 from '../../assets/images/ISO_45001[1].webp'
import certificate3 from '../../assets/images/ISO_14001[1].webp'
import certificate4Riyadh from '../../assets/images/شهادة_المركز_السعودي_للاعتماد_-_مختبر_الرياض[1].webp'
import certificate4Khobar from '../../assets/images/شهادة_المركز_السعودي_ساك_-_مختبر_الخبر[1].webp'

export default function Quality() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
    const [certificate, setCertificate] = useState(null)
    const [objectives, setObjectives] = useState(false)
    const [policy, setPolicy] = useState(false)
    const [code, setCode] = useState(false)

    const close = () =>{
      setCertificate(null)
    }

  return <>
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5 overflow-hidden">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p data-aos="zoom-in" className='position-relative line text-center fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('qualityHeader')}</p>
          <div data-aos="fade-up" data-aos-delay="200" className='d-flex'>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
            <span className='text-black px-2'>/</span>
            <p className='text-secondary'>{site==='company'? t('navSafety'):t('navQualityLabs')}</p>
          </div>
        </div>
      </div>  
      
                                        {/* content */}
      <div className="container-lg w-90 mx-auto">
        <div className='pt-sm-5 pt-3 mt-3 fs-5 mb-5 fw-medium'>
          <h3 data-aos="fade-up" data-aos-delay="600" className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.quality')}</h3>
          <p data-aos="fade-up" data-aos-delay="800" className='text-justify'>{t('quality.text1')}<br />{t('quality.text2')}</p>
          
          <div className="row align-items-center justify-content-center gy-4 mx-0 pt-3">
            <div data-aos="fade-up" data-aos-delay="350" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal text-justify'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <button onClick={ () =>setCertificate(certificate1)} className='btn border-0 scale text-hover cardd fs-5 fw-bold text-decoration-underline'>{t('quality.iso1Name')}</button>
                  {t('quality.iso1Sentence')}
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay="650" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso1} alt="ISO 9001:2015" />
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="450" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal text-justify'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <button onClick={ () =>setCertificate(certificate2)} className='btn border-0 scale text-hover cardd fs-5 fw-bold text-decoration-underline'>{t('quality.iso2Name')}</button>
                  {t('quality.iso2Sentence')}
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay="750" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso2} alt="ISO45001-2018" />
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="450" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal text-justify'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <button onClick={ () =>setCertificate(certificate3)} className='btn border-0 scale text-hover cardd fs-5 fw-bold text-decoration-underline'>{t('quality.iso3Name')}</button>
                  {t('quality.iso3Sentence')}
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay="750" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso3} alt="ISO 14001" />
              </div>
            </div>
          </div>
          {site==='company'? '':<>
            <div data-aos="fade-up" data-aos-delay="550" data-aos-offset="0" data-aos-easing="ease-in-back" className='py-4'>
              <p>
                <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span className='fw-bold text-decoration-underline'>{t('quality.iso4Name')}</span>
              </p>
              <p className='fw-normal text-justify'>
                <i className='fa-solid fa-check opacity-0 d-sm-inline d-none'></i>
                <span className='fw-bold text-decoration-underline'>{t('quality.iso4Text')}</span>{t('quality.iso4Sentence')}
              </p>
            </div>
            <div className="row align-items-center justify-content-around gx-5">
              <div data-aos="zoom-in" data-aos-delay="750" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-sm-5">
                <div>
                  <img loading='lazy' className='w-100' src={iso4} alt="ISO 17025 -2017" />
                </div>
              </div>
              <div data-aos="zoom-in" data-aos-delay="750" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-sm-4">
                <div>
                  <img loading='lazy' className='w-100' src={sacLogo} alt="sac logo" />
                </div>
              </div>
            </div>
          </>}
        </div>
      </div> 
      <div className="bg-light">
        <div className="container-lg w-90 mx-auto py-5 fs-5 lh-lg fw-medium d-flex flex-column">
          <p data-aos="fade-up" data-aos-delay="350" className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.qualitySystem')}</p>
          {/* <Link to={'/FR-QM-04_QUALITY OBJECTIVE_2024.pdf'} target='_blank' className='text-hover trans3'>
            <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
            <span>{t('quality.objectives')}</span>
          </Link> */}
          <div data-aos="zoom-in" data-aos-delay="450" className="w-fit">
            <button className={`text-hover scale ${objectives===false? '':'text-main'} trans3 border-0 bg-transparent p-0`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" onClick={() => setObjectives(!objectives)} aria-expanded={objectives} aria-controls="collapseExample">
              <i className={`fa fa-chevron-circle-down text-main ${language==='en'? 'pe-2':'ps-2'} ${objectives===false? '':'rotateX'}`}></i>
              <span className='fw-semibold'>{t('quality.objectives.name')}</span>
            </button>
            <div className="collapse fs-6 fw-normal" id="collapseExample1">
              <p className={`card card-body bg-transparent border-0 ${language==='en'? 'ps-4':'pe-4'}`}>{t('quality.objectives.text')} <br />{t('quality.objectives.text2')}</p>
              <ul className='list-style-disc'>
                <li className='py-1'>{t('quality.objectives.1')}</li>
                <li className='py-1'>{t('quality.objectives.2')}</li>
                <li className='py-1'>{t('quality.objectives.3')}</li>
                <li className='py-1'>{t('quality.objectives.4')}</li>
                <li className='py-1'>{t('quality.objectives.5')}</li>
              </ul>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-delay="650" className="w-fit">
            <button className={`text-hover scale ${policy===false? '':'text-main'} trans3 border-0 bg-transparent p-0`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" onClick={() => setPolicy(!policy)} aria-expanded={policy} aria-controls="collapseExample">
              <i className={`fa fa-chevron-circle-down text-main ${language==='en'? 'pe-2':'ps-2'} ${policy===false? '':'rotateX'}`}></i>
              <span className='fw-semibold'>{t('quality.policy.name')}</span>
            </button>
            <div className="collapse fs-6" id="collapseExample2">
              <p className={`card card-body bg-transparent border-0 ${language==='en'? 'ps-4':'pe-4'}`}>{t('quality.policy.text')}<br />{t('quality.policy.text')}</p>
              <ul className='list-style-disc'>
                <li className='py-1'>{t('quality.policy.1')}</li>
                <li className='py-1'>{t('quality.policy.2')}</li>
                <li className='py-1'>{t('quality.policy.3')}</li>
                <li className='py-1'>{t('quality.policy.4')}</li>
                <li className='py-1'>{t('quality.policy.5')}</li>
                <li className='py-1'>{t('quality.policy.6')}</li>
                <li className='py-1'>{t('quality.policy.7')}</li>
                <li className='py-1'>{t('quality.policy.8')}</li>
                <li className='py-1'>{t('quality.policy.9')}</li>
              </ul>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-delay="850" className="w-fit">
            <button className={`text-hover scale ${code===false? '':'text-main'} trans3 border-0 bg-transparent p-0`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" onClick={() => setCode(!code)} aria-expanded={code} aria-controls="collapseExample">
              <i className={`fa fa-chevron-circle-down text-main ${language==='en'? 'pe-2':'ps-2'} ${code===false? '':'rotateX'}`}></i>
              <span className='fw-semibold'>{t('quality.code.name')}</span>
            </button>
            <div className="collapse fs-6" id="collapseExample3">
              <div className={`card card-body bg-transparent border-0 ${language==='en'? 'ps-4':'pe-4'}`}>
                <span className='fw-bold text-decoration-underline'>{t('quality.code.intro')}</span>
                <p>{t('quality.code.text')}</p>
                <div className="row px-0 gy-2 pt-3">
                  <div className="col-lg-5">
                    <span className='fw-bold text-decoration-underline'>{t('quality.code.1.header')}</span>
                      <ul className='list-style-disc'>
                        <li className='py-1'>{t('quality.code.1.1')}</li>
                        <li className='py-1'>{t('quality.code.1.2')}</li>
                        <li className='py-1'>{t('quality.code.1.3')}</li>
                        <li className='py-1'>{t('quality.code.1.4')}</li>
                        <li className='py-1'>{t('quality.code.1.5')}</li>
                        <li className='py-1'>{t('quality.code.1.6')}</li>
                      </ul>
                  </div>
                  <div className="col-lg-7">
                    <span className='fw-bold text-decoration-underline'>{t('quality.code.2.header')}</span>
                    <ul className='list-style-disc'>
                      <li className='py-1'>{t('quality.code.2.1')}</li>
                      <li className='py-1'>{t('quality.code.2.2')}</li>
                      <li className='py-1'>{t('quality.code.2.3')}</li>
                      <li className='py-1'>{t('quality.code.2.4')}</li>
                      <li className='py-1'>{t('quality.code.2.5')}</li>
                      <li className='py-1'>{t('quality.code.2.6')}</li>
                      <li className='py-1'>{t('quality.code.2.7')}</li>
                      <li className='py-1'>{t('quality.code.2.8')}</li>
                    </ul>
                  </div>
                  <div className="col-lg-5">
                    <span className='fw-bold text-decoration-underline'>{t('quality.code.3.header')}</span>
                    <ul className='list-style-disc'>
                      <li className='py-1'>{t('quality.code.3.1')}</li>
                      <li className='py-1'>{t('quality.code.3.2')}</li>
                      <li className='py-1'>{t('quality.code.3.3')}</li>
                      <li className='py-1'>{t('quality.code.3.4')}</li>
                    </ul>
                  </div>
                  <div className="col-lg-7">
                    <span className='fw-bold text-decoration-underline'>{t('quality.code.4.header')}</span>
                    <ul className='list-style-disc'>
                      <li className='py-1'>{t('quality.code.4.1')}</li>
                      <li className='py-1'>{t('quality.code.4.2')}</li>
                      <li className='py-1'>{t('quality.code.4.3')}</li>
                      <li className='py-1'>{t('quality.code.4.4')}</li>
                      <li className='py-1'>{t('quality.code.4.5')}</li>
                    </ul>
                  </div>
                </div>
                <p>{t('quality.code.text2')}</p>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <div className="container-lg w-90 mx-auto py-5 fs-5 fw-semibold">
          <p data-aos="fade-up" data-aos-delay="300" data-aos-offset="0" data-aos-easing="ease-in-back" className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.certificates')}</p>
          <div className="row g-md-5 gy-4">
            <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
              <button onClick={ () =>setCertificate(certificate1)} className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate1')}</span>
              </button>
            </div>
            <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
              <button onClick={ () =>setCertificate(certificate2)} className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate2')}</span>
              </button>
            </div>
            <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
              <button onClick={ () =>setCertificate(certificate3)} className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate3')}</span>
              </button>
            </div>
          </div>
          {site==='company'? '':<>
            <p className='text-black fw-bold pt-5'>
              <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
              <span>{t('quality.certificateSac.text')}</span>
            </p>
            <div className="row g-md-5 gy-4 pt-3">
              <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
                <button onClick={ () =>setCertificate(certificate4Riyadh)} className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>{t('quality.certificateSac.riyadh')}</button>
              </div>
              <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
                <button onClick={ () =>setCertificate(certificate4Khobar)} className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>{t('quality.certificateSac.khobar')}</button>
              </div>
              <div data-aos="zoom-out" data-aos-offset="0" data-aos-delay="300" data-aos-easing="ease-in-back" className="col-md-4">
                <button className='btn scale btn-main2 shadow px-2 py-4 w-100 h-100 trans3'>{t('quality.certificateSac.madinah')}</button>
              </div>
            </div>
          </>}
        </div>
    </div>  
    {certificate===null? '':<div className={`position-fixed py-3 top-0 bottom-0 start-0 end-0 bg-black-70 ${style.zhightest}`}>
      <div className="container-lg mx-auto px-lg-0 px-md-3 px-0 h-100">
        <button type="button" onClick={close} className={`btn-close btn-close-white shadow-none position-absolute top-0 p-3 ${language==='en'? 'start-0':'end-0'}`} aria-label="Close"></button>
        <div className='row align-items-center justify-content-center h-100'>
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 h-100 pt-4 pb-2">
            <img className='w-100 h-100' src={certificate} alt="certificate" />
          </div>
        </div>
      </div>
    </div>}
  </>
}
