import React, { useContext } from 'react'
// import style from './Chemical.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chemical1 from '../../assets/images/pic2.webp'
import chemical2 from '../../assets/images/pic4.webp'
import chemical3 from '../../assets/images/pic6.webp'
import chemical4 from '../../assets/images/pic1.webp'
import chemical5 from '../../assets/images/pic3.webp'
import chemical6 from '../../assets/images/pic7.webp'

export default function Chemical() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)

  const chemicalImages = [
    chemical1, chemical2, chemical3, chemical4, chemical5, chemical6
  ]

  return <>
  <div dir={language==='en'? '':'rtl'} className="header-img py-5">
    <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
      <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navChemical')}</p>
    </div>
  </div> 
    <div dir={language==='en'? '':'rtl'} className='container-md w-90 fs-5 mx-auto pb-5'>
      <p className='pt-5 pb-4 fw-bold text-justify'>{t('chemical.text')}</p>
      <ul className='list-style-disc w-100 pb-4'>
        <li className='py-1 text-justify'>{t('chemical.1')}</li>
        <li className='py-1 text-justify'>{t('chemical.2')}</li>
        <li className='py-1 text-justify'>{t('chemical.3')}</li>
        <li className='py-1 text-justify'>{t('chemical.4')}</li>
      </ul>
      <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={1600}
        autoplaySpeed= {1600}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={3}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {chemicalImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
          <img loading='lazy' className='w-100 rounded-3' src={image} alt="chemical test" />
        </div>)}
      </Slider>
    </div>
  </>
}
