import React, { useContext } from 'react'
import style from './About.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LangContext } from '../../components/context/LangContext'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import 'swiper/css/pagination'
import 'swiper/swiper-bundle.css'
import { EffectFade , Autoplay ,Pagination } from 'swiper/modules'
import img1 from '../../assets/images/WhatsApp Image 2024-10-02 at 17.51.43_88522000.webp'
import img2 from '../../assets/images/IMG-20230420-WA0097.webp'
import img3 from '../../assets/images/فحص وتقييم المباني صورة 22.webp'
import img4 from '../../assets/images/image00050.webp'
import organizationCompanyAr from '../../assets/images/White and Blue Minimalist Organizational Chart Graph (10).webp'
import organizationCompanyEn from '../../assets/images/White and Blue Minimalist Organizational Chart Graph (11).webp'
import organizationLabsAr from '../../assets/images/White and Blue Minimalist Organizational Chart Graph (14) (1).webp'
import organizationLabsEn from '../../assets/images/White and Blue Minimalist Organizational Chart Graph (13)_enhanced.webp'
import eng1 from '../../assets/images/م. حاتم العثمان.webp'
import eng2 from '../../assets/images/م. نادر الإخوان.webp'
import eng3 from '../../assets/images/م. محمد اليوسف.webp'
import eng4 from '../../assets/images/م. محمد الصليبي.webp'
import eng5 from '../../assets/images/م. إسلام طلعت.webp'
import eng6 from '../../assets/images/د. محمد صبحي.webp'
import architectural from '../../assets/images/architec (1).webp'
import hydrological from '../../assets/images/water-level.webp'
import gis from '../../assets/images/tripod.webp'
import supervision from '../../assets/images/prototype.webp'
import infrastructure from '../../assets/images/blueprint (1).webp'
import transport from '../../assets/images/junction.webp'
import electrical from '../../assets/images/smart-meter.webp'
import evaluation from '../../assets/images/building.webp'
import telescope from '../../assets/images/telescope.webp'
import rocket from '../../assets/images/rocket (3).webp'
import target from '../../assets/images/target.webp'
import values from '../../assets/images/values.webp'
import control from '../../assets/images/security.webp'
import construction from '../../assets/images/drilling-dig.webp'
import house from '../../assets/images/house.webp'
import { Parallax } from 'react-scroll-parallax'
import { useMediaQuery } from 'react-responsive'

export default function About() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
    const isMd = useMediaQuery({ minWidth: 768 })
    
  return <>

  <div dir={language==='en'? '':'rtl'}>
                                      {/* header */}
    <div className="header-img py-5 overflow-hidden">
      <div className='container-lg w-90-md w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p data-aos="zoom-in" className='position-relative line fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{site==='company'? t('engineeringConsultancy'):t('engineeringLaboratories')}</p>
        <div data-aos="fade-up" data-aos-delay="200" className='d-flex'>
          <Link to={'/'} className='text-hover trans3'>{t('navHome')}</Link>
          <span className='text-black px-2'>/</span>
          <p className='text-secondary'>{t('about')}</p>
        </div>
      </div>
    </div>  

                                  {/* why us message */}
    {site==='company'? <section id='whyUs' className="container-lg w-90 mx-auto pt-lg-4">
      <p data-aos="fade-up" data-aos-delay="600" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-4 fw-bold text-main2 line3 px-0 pt-5 mt-lg-4 mt-0 w-fit`}>{t('about')} :</p>
      <p data-aos="fade-up" data-aos-delay="800" className='fs-5 pt-5 pb-3 text-justify'>{t('aboutText1')} <br/> {t('include')}</p>
      <div className="row gx-5 gy-3 py-3 justify-content-center">
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/architectural'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={architectural} alt={t('navArchitectural')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navArchitectural')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/hydrological'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={hydrological} alt={t('navHydrological')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navHydrological')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/gis'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={gis} alt={t('navGIS')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navGIS')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/supervision'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={supervision} alt={t('navSupervision')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navSupervision')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/infrastructure'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={infrastructure} alt={t('navInfrastructure')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navInfrastructure')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/transport'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={transport} alt={t('navTransport')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navTransport')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/electrical'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={electrical} alt={t('navElectrical')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navElectrical')}</p>
            </div>
          </Link>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-lg-3 col-md-4 col-sm-6 cardd">
          <Link to={'/services/evaluation'} className={`border w-100 h-100 bg-light shadow-hover rounded-3 d-flex align-items-center justify-content-around py-3 text-main-light`}>
            <div className="w-25 text-center scale2">
              <img loading='lazy' className='w-100 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={evaluation} alt={t('navEvaluation')} />
            </div>
            <div className={`border-black ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 ${language==='en'? 'ps-3':'pe-3'}`}>
              <p className='text-main2 fw-bold'>{t('navEvaluation')}</p>
            </div>
          </Link>
        </div>
      </div>
      
      <p data-aos="fade-up" data-aos-delay="500" className='fs-5 py-4 text-justify'>{t('aboutText2')}</p>
    </section>:''}

                                    {/* content */}
    <section id='ourMessage' className={`${site==='company'? 'bg-light mt-lg-5 pb-5':'pb-0'} pt-5`}>
    <div className="container-md w-90 mx-auto">
      <div className="row px-0 gx-lg-5 gx-4 gy-5 mt-0">
        <div className={`${site==='company'? 'col-12':'col-md-6'} mt-md-5 mt-0`}>
          <div>
          {site==='company'? <>
          <p data-aos="fade-up" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-4 fw-bold text-main2 line3 px-0 w-fit`}>{t('managerWord')}</p>
          <p data-aos="fade-up" data-aos-delay="200" className='fs-5 pt-5 text-justify'>{t('managerWordSentence')}</p>
          </>
          :<>
            <h3 data-aos="fade-up" className='position-relative line2 pb-3'>{t('aboutHeaderLaboratories')}</h3>
            <p data-aos="fade-up" data-aos-delay="200" className='pt-5 pb-3 text-secondary text-justify'>{t('aboutSentenceLaboratories')}</p>
            </>}
            <div data-aos="fade-up" data-aos-delay="400" className="d-flex mt-5">
              <div className={`${language==='en'? 'me-3':'ms-3'}`}>
                <img loading='lazy' className='w-4' src={telescope} alt="telescope" />
              </div>
              <p><span className='fw-semibold'>{site==='company'? t('navOurVision'):t('navVision')} : </span>{site==='company'? t('visionSentenceCompany'):t('visionSentenceLabs')}</p>
            </div>
            <div data-aos="fade-up" data-aos-delay="600" className="d-flex my-4">
              <div className={`${language==='en'? 'me-3':'ms-3'}`}>
                <img loading='lazy' className='w-4' src={site==='company'? rocket:target} alt="rocket" />
              </div>
              <p><span className='fw-semibold'>{site==='company'? t('navMessage'):t('navGoals')} : </span>{site==='company'? t('OurMessageCompany'):t('goalsSentenceLabs')}</p>
            </div>
            <div data-aos="fade-up" data-aos-delay="800" className="d-flex mb-5">
              <div className={`${language==='en'? 'me-3':'ms-3'}`}>
                <img loading='lazy' className='w-4' src={site==='company'? target:values} alt="target" />
              </div>
              <p><span className='fw-semibold'>{site==='company'? t('navGoals'):t('navValues')} : </span>{site==='company'? t('goalsSentenceCompany'):t('valuesSentenceLabs')}</p>
            </div>
            {site==='company'? '':<Link data-aos="zoom-out" data-aos-delay="1000" to={'/projects/all'} className='btn px-4 py-3 mt-4 fs-5 text-white btn-main2 fw-semibold'><p>{t('ourProjects')}</p></Link>}
          </div>
        </div>
        {site==='company'? '':<><div className="col-md-6">
          <div className={`position-relative
             ${style.heightDivImages}`}>
            <Parallax translateX={isMd? [10, 28]:[0, 20]} smooth={true} speed={0.2} className={`shadow-lg parallax-element position-absolute ${language==='en'? 'z-1':'z-n1'} start-0 top-0`}>
              <img loading='lazy' className={`${style.imageSize} rounded-3`} src={img3} alt="Almekyal Alsaudi" />
            </Parallax>
            <Parallax translateX={isMd? [8, -10]:[0, -20]} smooth={true} speed={0.2} className={`shadow-lg parallax-element position-absolute ${language==='en'? 'z-n1':'z-1'} end-0 ${style.topImage}`}>
              <img loading='lazy' className={`${style.imageSize} rounded-3`} src={img4} alt="Almekyal Alsaudi" />
            </Parallax>
          </div>
        </div></>}
      </div>
    </div>
    </section>
                                    {/* growth */}
    {site==='company'? <section id='growth' className="bg-black-80 position-relative pt-lg-5 pb-5 text-white">
          <div className="about-header-img position-absolute top-0 bottom-0 start-0 end-0 z-n1"></div>
          <div className='container-lg w-90 mx-auto py-5'>
            <p data-aos="fade-up" data-aos-delay="200" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-2 fw-bold d-flex line3 mb-5 w-fit`}>{t('navGrowth')}</p>
            <div className='row gx-2 px-0 justify-content-center pt-3'>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <p className='fw-semibold fs-4 cairo-font'>2012</p>
                    <p className='pb-5 fw-light'>{t('growth1')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <p className='fw-semibold fs-4 cairo-font'>2013</p>
                    <p className='pb-5 fw-light'>{t('growth2')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="800">
                    <p className='fw-semibold fs-4 cairo-font'>2016</p>
                    <p className='pb-5 fw-light'>{t('growth3')}</p>
                    </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="1000">
                    <p className='fw-semibold fs-4 cairo-font'>2018</p>
                    <p className='pb-5 fw-light'>{t('growth4')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="1200">
                    <p className='fw-semibold fs-4 cairo-font'>2018</p>
                    <p className='pb-5 fw-light'>{t('growth5')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
            </div>
            <div className={`row justify-content-center gx-2 px-0 pt-5 border-top border-bottom flex-row-reverse ${language==='en'? 'border-l border-end':'border-r border-start'} border-light border-opacity-50 border-3`}>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="1400">
                    <p className='fw-semibold fs-4 cairo-font'>2020</p>
                    <p className='pb-5 fw-light'>{t('growth6')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="1600">
                    <p className='fw-semibold fs-4 cairo-font'>2021</p>
                    <p className='pb-5 fw-light'>{t('growth7')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="1800">
                    <p className='fw-semibold fs-4 cairo-font'>2021</p>
                    <p className='pb-5 fw-light'>{t('growth8')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="2000">
                    <p className='fw-semibold fs-4 cairo-font'>2023</p>
                    <p className='pb-5 fw-light'>{t('growth9')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div className="col-2 text-center">
                <div className='position-relative h-100 d-flex flex-column align-items-center'>
                  <div data-aos="fade-up" data-aos-delay="2200">
                    <p className='fw-semibold fs-4 cairo-font'>2024</p>
                    <p className='pb-5 fw-light'>{t('growth10')}</p>
                  </div>
                  <svg className='position-absolute top-90' xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 50">
                    <circle className='stroke-white' cx="25" cy="25" r="10" stroke="white" stroke-width="2" fill='white' />
                    <line className='stroke-white' x1="25" y1="5" x2="25" y2="45" stroke="white" stroke-width="1" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
      </section>:''}

                                  {/* departments */}
    {site==='labs'? <section dir='ltr' id='navDepartments' className='bg-light mt-lg-5 pt-lg-5 py-5'>
    <div className='container-md w-90 mx-auto mt-5'>
      <p className='position-relative text-center line fs-1 fw-bold text-main2 d-flex align-items-center justify-content-center mt-3 mb-5'>{t('departments')}</p>
      <Swiper id='departments' className="d-flex justify-content-between pb-5 cursor-grab"
          modules={[EffectFade, Autoplay, Pagination]}
          slidesPerView={1}
          slidesPerGroup={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            992: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={40}
          direction="horizontal"
          speed={2000}
          pagination={{
            clickable: true,
          }}
          >
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>01.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-trowel-bricks fs-1 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader1')}</h5>
                  <p className='text-secondary'>{t('departmentText1')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>02.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <img loading='lazy' className='circle-icon d-flex align-items-center justify-content-center p-2' src={control} alt="quality control" />
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader2')}</h5>
                  <p className='text-secondary'>{t('departmentText2')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>03.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <img loading='lazy' className='circle-icon d-flex align-items-center justify-content-center p-2' src={construction} alt="construction" />
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader3')}</h5>
                  <p className='text-secondary'>{t('departmentText3')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>04.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <img loading='lazy' className='circle-icon d-flex align-items-center justify-content-center p-2' src={house} alt="house" />
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader4')}</h5>
                  <p className='text-secondary'>{t('departmentText4')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>05.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-flask fs-1 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader5')}</h5>
                  <p className='text-secondary'>{t('departmentText5')}</p>
                </div>
              </div>
            </SwiperSlide>
      </Swiper>
    </div>
    </section>:''}

                                  {/* organization */}
    <section id='aboutOrganization' className='container-lg w-90 mx-auto border-top border-light pt-lg-4 pb-5'>
      <p data-aos="fade-up" data-aos-delay="600" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-3 fw-bold text-main2 d-flex align-items-center line3 pt-4 mt-lg-5 mt-0 mb-4 w-fit`}>{site==='company'? t('aboutCompanyOrganization'):t('aboutOrganization')}</p>
      <div data-aos="zoom-in" data-aos-delay="600" data-aos-duration="2000" className={`${style.w90} mx-auto ${site==='company'? 'px-xl-5 px-lg-4':''}`}>
        <img loading='lazy' className='w-100 px-xl-5 px-lg-4' src={site==='company'? language==='en'? organizationCompanyEn:organizationCompanyAr:language==='en'? organizationLabsEn:organizationLabsAr} alt="Almekyal labs organization" />
      </div>
    </section>

                                  {/* important engineers */}
    {site==='labs'? <section id='importantEngineers' className="bg-light pt-lg-5 mt-5">
    <div className='container-lg w-90 mx-auto py-5'>
    <p className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-2 fw-bold text-main2 d-flex line3 mb-4 w-fit`}>{t('importantEngineers')}</p>
      <div className="row g-5 pt-5">

                      {/* engineer1 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng1')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng1JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng1} alt={t('eng1')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng1About')}</p>
            </div>
          </div>
        </div>
                      {/* engineer2 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng2')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng2JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng2} alt={t('eng2')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng2About')}</p>
            </div>
          </div>
        </div>
                      {/* engineer3 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng3')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng3JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng3} alt={t('eng3')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng3About')}</p>
            </div>
          </div>
        </div>
                      {/* engineer4 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng4')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng4JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng4} alt={t('eng4')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng4About')}</p>
            </div>
          </div>
        </div>
                      {/* engineer5 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng5')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng5JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng5} alt={t('eng5')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng5About')}</p>
            </div>
          </div>
        </div>
                      {/* engineer6 */}
        <div className="col-md-6">
          <div className="engineer row gy-4 gx-xl-2 gx-1 align-items-end">
            <div className="col-xl-7 col-md-6 col-sm-7">
              <div className='fw-bold'>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="700" className='pb-3'>{t('eng6')}</p>
                <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="800">{t('eng6JobTitle')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-5">
              <div>
                <img data-aos="zoom-in" data-aos-delay="700" data-aos-duration="3000" className='w-100' loading='lazy' src={eng6} alt={t('eng6')} />
              </div>
            </div>
            <div className="col-12">
              <p data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1000" className='text-justify'>{t('eng6About')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>:''}
  </div>

  </>
}
