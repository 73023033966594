import React, { useContext } from 'react'
// import style from './Supervision.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import project1 from '../../assets/images/IMG-20230802-WA0103.webp'
import project3 from '../../assets/images/IMG-20241121-WA0042.webp'
import management1 from '../../assets/images/SCHEDULE  A01_1.webp'
import management2 from '../../assets/images/Schedule 02.webp'
import management3 from '../../assets/images/Schedule_03.webp'
import management4 from '../../assets/images/sc 01_1.webp'
import supervise1 from '../../assets/images/IMG-20230802-WA0092.webp'
import supervise2 from '../../assets/images/WhatsApp Image 2024-09-30 at 10.50.00_913617f5.webp'
import supervise3 from '../../assets/images/اشراف 3.webp'
import { Link } from 'react-router-dom'

export default function Supervision() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    
    const data = [
      {
        id: '1',
        name:'الأشراف على أعمال البنية التحتية للمخطط رقم 4002',
        images: project1
      },
      {
        id: '2',
        name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3926',
        images: ''
      },
      {
        id: '3',
        name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3978',
        images: project3
      },
      {
        id: '4',
        name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3079',
        images: ''
      },
    ]
     const management = [management1, management2, management4, management3]
     const supervise = [supervise1, supervise2, supervise3]
  
  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navSupervision')}</p>
      </div>
    </div>  
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('supervision.text1')}</p>
      <p className='pt-2 pb-4 fs-5 lh-lg text-justify'>{t('supervision.text2')}<br />{t('supervision.text3')}</p>
    </div> 
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <p className='pt-5 fs-5 fw-bold'>{t('include')}</p>
        <ul className={`pt-4 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.1')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.2')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.3')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.4')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.5')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.6')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.7')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.8')}</p>
          </li>
          <li className='py-2 text-justify'>
            <p className='fw-medium'>{t('supervision.list.9')}</p>
          </li>
        </ul>
        <div className="d-flex justify-content-center">
          <p className='pt-2 mb-2 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
        </div>
        <div className='d-flex justify-content-end py-3'>
          <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
        </div>
        <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {data.map((item) => (
          <div key={item.id} className='px-2'>
            <Link className='position-relative project trans3'>
              <div className='vw-20'>
                <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt={item.name} />
              </div>
              <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                <button className={`btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center justify-content-end ${language==='en'? 'flex-row-reverse':''}`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i>{t('details')}</button>
              </div>
            </Link>
          </div>
        ))}
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('supervision.text4')}</p>
      <p className='pt-2 pb-4 fs-5 lh-lg text-justify'>{t('supervision.text5')}</p>
    </div> 
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <p className='pt-5 fs-5 fw-bold'>{t('include')}</p>
        <ul className={`pt-4 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-2'>
            <p className='fw-medium'>{t('supervision.list2.1')}</p>
          </li>
          <li className='py-2'>
            <p className='fw-medium'>{t('supervision.list2.2')}</p>
          </li>
          <li className='py-2'>
            <p className='fw-medium'>{t('supervision.list2.3')}</p>
          </li>
          <li className='py-2'>
            <p className='fw-medium'>{t('supervision.list2.4')}</p>
          </li>
          <li className='py-2'>
            <p className='fw-medium'>{t('supervision.list2.5')}</p>
          </li>
        </ul>
        <p className='py-4 fs-5 fw-bold'>{t('supervision.text6')}</p>
        <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {management.map((item ,index) => (
          <div key={index} className='px-2 portrait overflow-hidden'>
            <img loading='lazy' className='w-100 object-cover' src={item} alt="management" />
          </div>
        ))}
        </Slider>
        <p className='py-4 fs-5 fw-bold'>{t('supervision.text7')}</p>
        <Slider
        autoplay= {3}
        infinite={false}
        arrows={false}
        dots={false}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ]}
      >
      {supervise.map((item ,index) => (
          <div key={index} className='px-2 portrait overflow-hidden'>
            <img loading='lazy' className='w-100 object-cover' src={item} alt="management" />
          </div>
        ))}
        </Slider>
      </div>
    </div>
  </div>
  </>
}

