import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import clientLabsG1 from '../../assets/images/الهيئة السعودية للمياه.webp'
import clientLabsG2 from '../../assets/images/مدن.webp'
import clientLabsG3 from '../../assets/images/وزارة الشؤون البلدية والاسكان.webp'
import clientLabsG4 from '../../assets/images/وزارة البيئة والزراعة.webp'
import clientLabsG5 from '../../assets/images/وزارة النقل.webp'
import clientLabsG6 from '../../assets/images/وزارة الدفاع.webp'
import clientLabsG7 from '../../assets/images/وزارة التعليم.webp'
import clientLabsG8 from '../../assets/images/امانة منطقة الرياض.webp'
import clientLabsG9 from '../../assets/images/امانة المدينة المنورة.webp'
import clientLabsG10 from '../../assets/images/امانة المنطقة الشرقية.webp'
import clientLabsG11 from '../../assets/images/العاصمة المقدسة.webp'
import clientLabsG12 from '../../assets/images/قوات+أمن+المنشآت+2.webp'
import clientLabsG13 from '../../assets/images/1200px-رئاسة_أمن_الدولة.webp'
import clientLabsG14 from '../../assets/images/الشركة الوطنية للاسكان.webp'
import clientLabsG15 from '../../assets/images/الكهرباء.webp'
import clientLabsG16 from '../../assets/images/شعار_شركة_المياه_الوطنية_2021.webp'
import clientLabsG17 from '../../assets/images/الحديدية.webp'
import clientLabsG18 from '../../assets/images/الجبيل وينبع.webp'
import clientLabsP1 from '../../assets/images/شعار روشن.webp'
import clientLabsP2 from '../../assets/images/شعار سيسترا.webp'
import clientLabsP3 from '../../assets/images/شعار ايجيك.webp'
import clientLabsP4 from '../../assets/images/شعار المشاريع الاولى.webp'
import clientLabsP5 from '../../assets/images/وثبة.webp'
import clientLabsP6 from '../../assets/images/الاعمار والتنسيق.webp'
import clientLabsP7 from '../../assets/images/كاب فرانس.webp'
import clientLabsP8 from '../../assets/images/فريسينيه.webp'
import clientLabsP9 from '../../assets/images/المهنا.webp'
import clientLabsP10 from '../../assets/images/مكتب البعد التصميمي.webp'
import clientLabsP11 from '../../assets/images/شركة-مجموعة-العمرو-للاستشارات-الهندس.webp'
import clientLabsP12 from '../../assets/images/مركز-الشرق-للاستشارات-الهندسية.webp'
import clientLabsP13 from '../../assets/images/ميثاق.webp'
import clientLabsP14 from '../../assets/images/مكتب المهندس سمير ابو غلية.webp'
import clientLabsP15 from '../../assets/images/الرشودي.webp'
import clientLabsP16 from '../../assets/images/عمرانيات.webp'
import clientLabsP17 from '../../assets/images/اساس ايناس.webp'
import clientLabsP18 from '../../assets/images/الدارة.webp'
import clientLabsP19 from '../../assets/images/مجموعة تعاهدات القابضة.webp'
import clientLabsP20 from '../../assets/images/زوايا العقارية.webp'
import clientLabsP21 from '../../assets/images/نجوم السلام.webp'
import clientLabsP22 from '../../assets/images/الشرفة.webp'
import clientLabsP23 from '../../assets/images/البواردي.webp'
import clientCompanyG1 from '../../assets/images/أمانة المنطقة الشرقية.webp'
import clientCompanyG2 from '../../assets/images/أمانة منطقة الرياض.webp'
import clientCompanyG3 from '../../assets/images/أمانة منطقة المدينة المنورة.webp'
import clientCompanyG4 from '../../assets/images/الجمارك السعودية.webp'
import clientCompanyG5 from '../../assets/images/الخطوط الحديدية السعودية.webp'
import clientCompanyG6 from '../../assets/images/الهيئة الملكية للجبيل وينبع.webp'
import clientCompanyG7 from '../../assets/images/الهيئة الملكية لمدينة الرياض.webp'
import clientCompanyG8 from '../../assets/images/رئاسة أمن الدولة.webp'
import clientCompanyG9 from '../../assets/images/وزارة البلديات والإسكان.webp'
import clientCompanyG10 from '../../assets/images/وزارة البيئة والمياه والزراعة.webp'
import clientCompanyG11 from '../../assets/images/وزارة التجارة.webp'
import clientCompanyG12 from '../../assets/images/وزارة التعليم.webp'
import clientCompanyG13 from '../../assets/images/وزارة الثقافة.webp'
import clientCompanyG14 from '../../assets/images/وزارة الحرس الوطني.webp'
import clientCompanyG15 from '../../assets/images/وزارة الدفاع.webp'
import clientCompanyG16 from '../../assets/images/وزارة النقل.webp'
import clientCompanyP1 from '../../assets/images/البحر الأحمر العالمية.webp'
import clientCompanyP2 from '../../assets/images/البيع أو التأجير على الخارطة.webp'
import clientCompanyP3 from '../../assets/images/الشركة السعودية للكهرباء.webp'
import clientCompanyP4 from '../../assets/images/الشركة السعودية للنقل الجماعي.webp'
import clientCompanyP5 from '../../assets/images/القدية.webp'
import clientCompanyP6 from '../../assets/images/الهيئة السعودية للمياه.webp'
import clientCompanyP7 from '../../assets/images/جامعة شقراء.webp'
import clientCompanyP8 from '../../assets/images/شركة الاتصالات السعودية.webp'
import clientCompanyP9 from '../../assets/images/شركة المياه الوطنية.webp'
import clientCompanyP10 from '../../assets/images/شركة بوابة الدرعية المحدودة.webp'
import clientCompanyP11 from '../../assets/images/شركة تطوير للمباني (TBC).webp'
import clientCompanyP12 from '../../assets/images/مجموعة روشن.webp'
import clientCompanyP13 from '../../assets/images/مدن.webp'
import clientCompanyP14 from '../../assets/images/مشروع الرياض الخضراء.webp'
import clientCompanyP15 from '../../assets/images/نيوم.webp'
import clientCompanyP16 from '../../assets/images/مصرف الإنماء.webp'
import partner1 from '../../assets/images/sweco-removebg-preview.webp'
import partner2 from '../../assets/images/snapedit_1736426469342-removebg-preview.webp'
import partner3 from '../../assets/images/sbs.webp'
import partner4 from '../../assets/images/snapedit_1736426476501-removebg-preview.webp'
import partner5 from '../../assets/images/snapedit_1736426502155-removebg-preview (1).webp'

export default function Clients() {
    
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
    
    const clientsLabsGov = [
      {
        name:t('clientsLabsGov.client1'),
        image:clientLabsG1
      },
      {
        name:t('clientsLabsGov.client2'),
        image:clientLabsG2
      },
      {
        name:t('clientsLabsGov.client3'),
        image:clientLabsG3
      },
      {
        name:t('clientsLabsGov.client4'),
        image:clientLabsG4
      },
      {
        name:t('clientsLabsGov.client5'),
        image:clientLabsG5
      },
      {
        name:t('clientsLabsGov.client6'),
        image:clientLabsG6
      },
      {
        name:t('clientsLabsGov.client7'),
        image:clientLabsG7
      },
      {
        name:t('clientsLabsGov.client8'),
        image:clientLabsG8
      },
      {
        name:t('clientsLabsGov.client9'),
        image:clientLabsG9
      },
      {
        name:t('clientsLabsGov.client10'),
        image:clientLabsG10
      },
      {
        name:t('clientsLabsGov.client11'),
        image:clientLabsG11
      },
      {
        name:t('clientsLabsGov.client12'),
        image:clientLabsG12
      },
      {
        name:t('clientsLabsGov.client13'),
        image:clientLabsG13
      },
      {
        name:t('clientsLabsGov.client14'),
        image:clientLabsG14
      },
      {
        name:t('clientsLabsGov.client15'),
        image:clientLabsG15
      },
      {
        name:t('clientsLabsGov.client16'),
        image:clientLabsG16
      },
      {
        name:t('clientsLabsGov.client17'),
        image:clientLabsG17
      },
      {
        name:t('clientsLabsGov.client18'),
        image:clientLabsG18
      },
    ]
    const clientsLabsPri = [
      {
        name:t('clientsLabsPri.client1'),
        image:clientLabsP1
      },
      {
        name:t('clientsLabsPri.client2'),
        image:clientLabsP2
      },
      {
        name:t('clientsLabsPri.client3'),
        image:clientLabsP3
      },
      {
        name:t('clientsLabsPri.client4'),
        image:clientLabsP4
      },
      {
        name:t('clientsLabsPri.client5'),
        image:clientLabsP5
      },
      {
        name:t('clientsLabsPri.client6'),
        image:clientLabsP6
      },
      {
        name:t('clientsLabsPri.client7'),
        image:clientLabsP7
      },
      {
        name:t('clientsLabsPri.client8'),
        image:clientLabsP8
      },
      {
        name:t('clientsLabsPri.client9'),
        image:clientLabsP9
      },
      {
        name:t('clientsLabsPri.client10'),
        image:clientLabsP10
      },
      {
        name:t('clientsLabsPri.client11'),
        image:clientLabsP11
      },
      {
        name:t('clientsLabsPri.client12'),
        image:clientLabsP12
      },
      {
        name:t('clientsLabsPri.client13'),
        image:clientLabsP13
      },
      {
        name:t('clientsLabsPri.client14'),
        image:clientLabsP14
      },
      {
        name:t('clientsLabsPri.client15'),
        image:clientLabsP15
      },
      {
        name:t('clientsLabsPri.client16'),
        image:clientLabsP16
      },
      {
        name:t('clientsLabsPri.client17'),
        image:clientLabsP17
      },
      {
        name:t('clientsLabsPri.client18'),
        image:clientLabsP18
      },
      {
        name:t('clientsLabsPri.client19'),
        image:clientLabsP19
      },
      {
        name:t('clientsLabsPri.client20'),
        image:clientLabsP20
      },
      {
        name:t('clientsLabsPri.client21'),
        image:clientLabsP21
      },
      {
        name:t('clientsLabsPri.client22'),
        image:clientLabsP22
      },
      {
        name:t('clientsLabsPri.client23'),
        image:clientLabsP23
      },
    ]

    const clientsCompanyGov = [
      {
        name:t('clientsCompanyGov.client1'),
        image:clientCompanyG1
      },{
        name:t('clientsCompanyGov.client2'),
        image:clientCompanyG2
      },{
        name:t('clientsCompanyGov.client3'),
        image:clientCompanyG3
      },{
        name:t('clientsCompanyGov.client4'),
        image:clientCompanyG4
      },{
        name:t('clientsCompanyGov.client5'),
        image:clientCompanyG5
      },{
        name:t('clientsCompanyGov.client6'),
        image:clientCompanyG6
      },{
        name:t('clientsCompanyGov.client7'),
        image:clientCompanyG7
      },{
        name:t('clientsCompanyGov.client8'),
        image:clientCompanyG8
      },{
        name:t('clientsCompanyGov.client9'),
        image:clientCompanyG9
      },{
        name:t('clientsCompanyGov.client10'),
        image:clientCompanyG10
      },{
        name:t('clientsCompanyGov.client11'),
        image:clientCompanyG11
      },{
        name:t('clientsCompanyGov.client12'),
        image:clientCompanyG12
      },{
        name:t('clientsCompanyGov.client13'),
        image:clientCompanyG13
      },{
        name:t('clientsCompanyGov.client14'),
        image:clientCompanyG14
      },{
        name:t('clientsCompanyGov.client15'),
        image:clientCompanyG15
      },{
        name:t('clientsCompanyGov.client16'),
        image:clientCompanyG16}
    ]
       
    const clientsCompanyPri = [
      {
        name:t('clientsCompanyPri.client1'),
        image:clientCompanyP1
      },{
        name:t('clientsCompanyPri.client2'),
        image:clientCompanyP2
      },{
        name:t('clientsCompanyPri.client3'),
        image:clientCompanyP3
      },{
        name:t('clientsCompanyPri.client4'),
        image:clientCompanyP4
      },{
        name:t('clientsCompanyPri.client5'),
        image:clientCompanyP5
      },{
        name:t('clientsCompanyPri.client6'),
        image:clientCompanyP6
      },{
        name:t('clientsCompanyPri.client7'),
        image:clientCompanyP7
      },{
        name:t('clientsCompanyPri.client8'),
        image:clientCompanyP8
      },{
        name:t('clientsCompanyPri.client9'),
        image:clientCompanyP9 
      },{
        name:t('clientsCompanyPri.client10'),
        image:clientCompanyP10 
      },{
        name:t('clientsCompanyPri.client11'),
        image:clientCompanyP11 
      },{
        name:t('clientsCompanyPri.client12'),
        image:clientCompanyP12 
      },{
        name:t('clientsCompanyPri.client13'),
        image:clientCompanyP13 
      },{
        name:t('clientsCompanyPri.client14'),
        image:clientCompanyP14 
      },{
        name:t('clientsCompanyPri.client15'),
        image:clientCompanyP15 
      },{
        name:t('clientsCompanyPri.client16'),
        image:clientCompanyP16}
    ]

    const partnersCompany = [
      {
        name:t('partners.1'),
        image:partner1,
      },
      {
        name:t('partners.2'),
        image:partner2,
      },
      {
        name:t('partners.3'),
        image:partner3,
      },
      {
        name:t('partners.4'),
        image:partner4,
      },
      {
        name:t('partners.5'),
        image:partner5,
      },
    ]
       
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return <>
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5 overflow-hidden">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p data-aos="zoom-in" className='position-relative line fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('clientsHeader')}</p>
          <div data-aos="fade-up" data-aos-delay="200" className='d-flex'>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
            <span className='text-black px-2'>/</span>
            <p className='text-secondary'>{t('navClients')}</p>
          </div>
        </div>
      </div>  

                                        {/* content */}
      <div className="container-lg w-90 mx-auto">
        {site==='company'? <div className='pt-sm-5 pt-3 mt-3 fs-5 fw-medium text-justify'>
          <p data-aos="fade-up" data-aos-delay="400">{t('clientTextCompany1')}</p><br />
          <p data-aos="fade-up" data-aos-delay="600">{t('clientTextCompany2')}</p><br />
          <p data-aos="fade-up" data-aos-delay="600" >{t('clientTextCompany3')}</p>
        </div>:''}
        <div className='overflow-hidden'>
          <p data-aos={language==='en'? "fade-right":"fade-left"} data-aos-delay="800" data-aos-duration="1500" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-2 fw-bold text-main2 d-flex align-items-center pt-5 mt-3 mb-5 line3 w-fit`}>{t('navGovernment')}</p>
          <div className="row align-items-center justify-content-center g-4 pt-3">
          {(site==='company'? clientsCompanyGov:clientsLabsGov).map((client, index) => <div key={index} data-aos="zoom-in-up" data-aos-delay={`${(index + 1) * 100}`} className="col-lg-2 col-md-3 col-sm-4 col-3">
              <div className='d-flex flex-column justify-content-between align-items-center text-center'>
                <div>
                  <img loading='lazy' className='w-100' src={client.image} alt={client.name} />
                </div>
                <p className='text-black fw-bold small pt-3'>{client.name}</p>
              </div>
            </div>)}
          </div>
        </div>
        <div className='overflow-hidden'>
          <p data-aos={language==='en'? "fade-right":"fade-left"} data-aos-delay="800" data-aos-duration="1500" className={`${language==='en'? '':' justify-content-start'} text-center position-relative fs-2 fw-bold text-main2 d-flex align-items-center line3 pt-5 mt-3 mb-5 w-fit`}>{t('navPrivate')}</p>
          <div className="row align-items-center justify-content-center g-4 pt-3 pb-5">
          {(site==='company'? clientsCompanyPri:clientsLabsPri).map((client, index) => <div key={index} data-aos="zoom-in-up" data-aos-delay={`${(index + 8) * 100}`} data-aos-duration="1300" className="col-lg-2 col-md-3 col-sm-4 col-3">
              <div className='d-flex flex-column justify-content-between align-items-center text-center'>
                <div>
                  <img loading='lazy' className='w-100' src={client.image} alt={client.name} />
                </div>
                <p className='text-black fw-bold small pt-3'>{client.name}</p>
              </div>
            </div>)}
          </div>
        </div>
      </div>
      {site==='company'? <div className=' bg-light pb-5 fs-5 fw-medium'>
        <div className="container-lg w-90 mx-auto d-flex flex-column align-items-center">
          <p data-aos="fade-up" data-aos-delay="1500" className={`position-relative fs-2 fw-bold text-main2 pt-5 mt-3 mb-5 line3 w-fit`}>{t('ourPartners')}</p>
          <p data-aos="fade-up" data-aos-delay="1700" className='text-justify'>{t('clientTextCompany4')}<br />{t('clientTextCompany5')}</p>
        </div>
        <div className="row align-items-end justify-content-center g-4 mx-0 py-5">
          {partnersCompany.map((partner, index) => <div key={index} data-aos="zoom-in-up" data-aos-delay={`${(index + 15) * 100}`} data-aos-duration="1400" className="col-lg-2 col-md-3 col-4">
              <div className='d-flex flex-column justify-content-between align-items-center text-center'>
                <div>
                  <img loading='lazy' className='w-100' src={partner.image} alt={partner.name} />
                </div>
                <p className='text-black fw-bold small pt-3'>{partner.name}</p>
              </div>
            </div>)}
          </div>
      </div>:''}
    </div>
  </>
}
