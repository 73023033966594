import React, { useContext } from 'react'
import notfoundCompany from '../../assets/images/Picsart_24-12-26_12-42-59-579 (1).webp'
import notfoundLabs from '../../assets/images/—Pngtree—alert page not found_6671386 (1).webp'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'

export default function NotFound() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext);
  const site = sessionStorage.getItem('desiredPage')

  return <>
  {site===null? <h1 dir={language==='en'? '':'rtl'} className='border-bottom bg-light px-4 vh-9 pb-1 position-sticky top-0 z-3 mb-0 d-flex align-items-center shadow fs-4 text-main2 fw-bold w-100'>{t('siteName')}</h1>:''}
  <div className={site===null? 'h-79 d-flex flex-column justify-content-center':'py-5 mt-3'}>
    <div className="row px-0 gx-0 justify-content-center">
      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-10">
        <div>
          <img loading='lazy' className='w-100 px-lg-4 px-5' src={site==='company'? notfoundCompany:notfoundLabs} alt="not found" />
        </div>
      </div>
    </div>
    <div className='text-center text-main2'>
      <p className='display-4 fw-bold'>{t('notfoundHeader')}</p>
      <p className='py-3'>{t('notfoundText')}</p>
      <Link className='btn btn-main3' to={'/'}>{t('notfoundBtn')}</Link>
    </div>
  </div>
  </>
}
