import React from 'react'
import { Navigate } from 'react-router-dom'

export default function CompanyRoute(props) {

  if(sessionStorage.getItem('desiredPage')==='company'){
    return props.children
  }else{
    return <Navigate to={'/services'}/>
  }
}
