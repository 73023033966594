import React, { useContext } from 'react'
// import style from './Architectural.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import projectDesign1 from '../../assets/images/مشروع اس1.webp'
import projectDesign2 from '../../assets/images/ELEVATION 1.webp'
import projectDesign3 from '../../assets/images/مشروع اس5.webp'
import projectDesign4 from '../../assets/images/مبنى الحياة الفطرية_3 - Photo.webp'
import projectDesign5 from '../../assets/images/00001.webp'
import projectDesign6 from '../../assets/images/ابو فيصل.webp'
import projectUrban1 from '../../assets/images/المخطط المعتمد نهائي 3943 بتاريخ 19-17-2023-Model.webp'
import projectUrban2 from '../../assets/images/مخطط ركاز حي السعادة المعدل نهااائي-Model.webp'
import projectUrban3 from '../../assets/images/نهائي مخطط رقم 3978 بتاريخ 04 - 08 - 2024 (2)-Model.webp'
import projectUrban4 from '../../assets/images/ابوبندر.webp'
import projectUrban5 from '../../assets/images/تصميم حضري - حدائق-Layout1.webp'
import { Link } from 'react-router-dom'

export default function Architectural() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    
  const data = [
    {
      id: '1',
      name:'تصميم مبنى الدفاع المدني بالمدينة المنورة',
      images: projectDesign1
    },
    {
      id: '2',
      name:'إعادة تصميم واجهات مباني الجمارك بمعبر البطحاء',
      images: projectDesign2
    },
    {
      id: '3',
      name:'تصميم جامع الأمير سلطان بن عبد العزيز آل سعود وملحقاته ',
      images: projectDesign3
    },
    {
      id: '4',
      name:'تصميم مكاتب إدارة التراخيص للحياة الفطرية (مركز الإيواء المؤقت بمنطقة جازان)',
      images: projectDesign4
    },
    {
      id: '5',
      name:'تصميم مجموعة من محطات الوقود',
      images: projectDesign5
    },
    {
      id: '6',
      name:'تصميم العديد من الفلل والعمائر السكنية',
      images: projectDesign6
    },
  ]
    
  const data2 = [
    {
      id: '1',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3978 بحي الرمال بالرياض',
      images: projectUrban1
    },
    {
      id: '2',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3957 بحي السعادة بالرياض ',
      images: projectUrban2
    },
    {
      id: '3',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3943 بحي الجنادرية بالرياض',
      images: projectUrban3
    },
    {
      id: '4',
      name:'تخطيط مدينة صناعية بمحافظة الخرج',
      images: projectUrban4
    },
    {
      id: '5',
      name:'التصميم الحضري',
      images: projectUrban5
    },
  ]
  
  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navArchitectural')}</p>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 pb-4 fs-5 lh-lg text-justify'>{t('architectural.text')}</p>
    </div> 
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('architectural.design.name')}</p>
        <ul className={`pt-4 pb-md-4 fs-5 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-3 text-justify'>
            <p><span className='fw-bold'>{t('architectural.design.1.name')}</span>{t('architectural.design.1.text')}</p>
          </li>
          <li className='py-3 text-justify'>
            <p><span className='fw-bold'>{t('architectural.design.2.name')}</span>{t('architectural.design.2.text')}</p>
          </li>
          <li className='py-3 text-justify'>
            <p><span className='fw-bold'>{t('architectural.design.3.name')}</span>{t('architectural.design.3.text')}</p>
          </li>
          <li className='py-3 text-justify'>
            <p><span className='fw-bold'>{t('architectural.design.4.name')}</span>{t('architectural.design.4.text')}</p>
          </li>
          <li className='py-3 text-justify'>
            <p><span className='fw-bold'>{t('architectural.design.5.name')}</span>{t('architectural.design.5.text')}</p>
          </li>
        </ul>
        <div className="d-flex justify-content-center">
          <p className='pt-2 mb-2 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
        </div>
        <div className='d-flex justify-content-end py-3'>
          <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
        </div>
        <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {data.map((item) => (
          <div key={item.id} className='px-2'>
            <Link className='position-relative project trans3'>
              <div className='vw-20'>
                <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt="project" />
              </div>
              <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                <button className={`btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center justify-content-end ${language==='en'? 'flex-row-reverse':''}`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i>{t('details')}</button>
              </div>
            </Link>
          </div>
        ))}
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-5'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('architectural.urban.name')}</p>
      <p className='pt-3 fs-5 text-justify'>{t('architectural.urban.text')}</p>
      <ul className={`pt-4 pb-md-4 fs-5 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
        <li className='py-3 text-justify'>
          <p><span className='fw-bold'>{t('architectural.urban.1.name')}</span>{t('architectural.urban.1.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p><span className='fw-bold'>{t('architectural.urban.2.name')}</span>{t('architectural.urban.2.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p><span className='fw-bold'>{t('architectural.urban.3.name')}</span>{t('architectural.urban.3.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p><span className='fw-bold'>{t('architectural.urban.4.name')}</span>{t('architectural.urban.4.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p><span className='fw-bold'>{t('architectural.urban.5.name')}</span>{t('architectural.urban.5.text')}</p>
        </li>
      </ul>
        <div className="d-flex justify-content-center">
          <p className='pt-2 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
        </div>
        <div className='d-flex justify-content-end py-3'>
          <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
        </div>
        <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {data2.map((item) => (
          <div key={item.id} className='px-2'>
            <Link className='position-relative project trans3'>
              <div className='vw-20'>
                <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt="project" />
              </div>
              <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                <button className={`btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center justify-content-end ${language==='en'? 'flex-row-reverse':''}`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i>{t('details')}</button>
              </div>
            </Link>
          </div>
        ))}
        </Slider>
    </div>
  </div>
  </>
}
