import React, { useContext } from 'react'
// import style from './Gis.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from './../context/LangContext';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import project1 from '../../assets/images/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import project2 from '../../assets/images/WhatsApp Image 2023-04-01 at 15.50.49.webp'
import project3 from '../../assets/images/IMG-20230420-WA0097.webp'
import device from '../../assets/images/Picture14.webp'
import device2 from '../../assets/images/Picture12.webp'
import device3 from '../../assets/images/Picture13.webp'
import side1 from '../../assets/images/GPR1.webp'
import side2 from '../../assets/images/IMG-20230420-WA0097.webp'
import side3 from '../../assets/images/TimePhoto_20220612_083053.webp'
import side4 from '../../assets/images/WhatsApp Image 2022-07-26 at 9.14.31 AM (1).webp'
import side5 from '../../assets/images/WhatsApp Image 2023-04-01 at 15.26.51.webp'
import side6 from '../../assets/images/WhatsApp Image 2023-04-03 at 10.30.52.webp'
import { Link } from 'react-router-dom';

export default function Gis() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
  
    const data = [
      {
        id: '1',
        name:'أعمال رفع المساحة وتثبيت بتر حدودية لوكالة الثروة السمكية التابعة لوكالة الزراعة بمنطقة مكة المكرمة',
        images: project1
      },
      {
        id: '2',
        name:'اعمل الرفع المساحي وإنشاء بتر حدودية لتحديد ميدان تدريب للحرس الوطني',
        images: project2
      },
      {
        id: '3',
        name:'أعمال التخطيط والرفع المساح وتبتير الأراضي للمخططات السكنية لأعمال خاصة بوزارة البلديات والإسكان',
        images: project3
      },
    ]

    const workSide = [side1, side2, side3, side4, side5, side6]
      
  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navGIS')}</p>
      </div>
    </div> 
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 pb-4 fs-5 lh-lg text-justify'>{t('gis.text')}</p>
    </div> 
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <p className='pt-5 fs-5 fw-bold'>{t('include')}</p>
        <ul className={`pt-4 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.1.text')}</p>
          </li>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.2.text')}</p>
          </li>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.3.text')}</p>
          </li>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.4.text')}</p>
          </li>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.5.text')}</p>
          </li>
          <li className='py-3'>
            <p className='fw-medium'>{t('gis.6.text')}</p>
          </li>
        </ul>
        <p className='py-4 fs-5 fw-bold'>{t('gis.text2')}</p>
        <Slider
        autoplay= {3}
        infinite={false}
        arrows={false}
        dots={false}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ]}
      >
          <div className='px-2 text-center fw-bold'>
            <div>
              <img loading='lazy' className='w-100' src={device} alt={t('gis.devices.1')} />
            </div>
            <p className='pt-3'>{t('gis.devices.1')}</p>
          </div>
          <div className='px-2 text-center fw-bold'>
            <div>
              <img loading='lazy' className='w-100' src={device2} alt={t('gis.devices.2')} />
            </div>
            <p className='pt-3'>{t('gis.devices.2')}</p>
          </div>
          <div className='px-2 text-center fw-bold'>
            <div>
              <img loading='lazy' className='w-100' src={device3} alt={t('gis.devices.3')} />
            </div>
            <p className='pt-3'>{t('gis.devices.3')}</p>
          </div>
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pt-4 pb-5'>
      <p className='py-4 fs-5 fw-bold'>{t('gis.text3')}</p>
      <Slider
        autoplay
        infinite={true}
        dots
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={4}
        slidesToScroll={3}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
          {workSide.map((item, index) => <div key={index} className='px-2'>
            <img loading='lazy' className='w-100' src={item} alt='' />
          </div>)}
        </Slider>
    </div>
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pt-4 pb-5'>
        <div className="d-flex justify-content-center">
          <p className='pt-2 mb-2 fs-4 text-justify text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
        </div>
        <div className='d-flex justify-content-end py-3'>
          <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
        </div>
        <Slider
        autoplay= {3}
        infinite={false}
        arrows={false}
        dots={false}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ]}
      >
      {data.map((item) => (
          <div key={item.id} className='px-2'>
            <Link className='position-relative project trans3'>
              <div className='vw-20'>
                <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt="project" />
              </div>
              <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                <button className={`btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center`}>{t('details')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
              </div>
            </Link>
          </div>
        ))}
        </Slider>
      </div>
    </div>
  </div>
  </>
}
