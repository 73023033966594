import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute(props) {

  if(sessionStorage.getItem('desiredPage')){
    return props.children
  }else{
    return <Navigate to={'/select-site'}/>
  }
}
