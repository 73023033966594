import { createContext, useState } from "react";

export let DesiredPageContext = createContext()

export default function DesiredPageContextProvider({children}){

    const [desiredPage, setDesiredPage] = useState(sessionStorage.getItem('desiredPage'))
    const site = sessionStorage.getItem('desiredPage')

     return <DesiredPageContext.Provider value={{desiredPage, setDesiredPage, site}}>
          {children}
     </DesiredPageContext.Provider>
}