import React, { useContext, useEffect } from 'react'
// import style from './Blogs.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import { Link } from 'react-router-dom'
import new1 from '../../assets/images/الخبر الأول.jpeg.webp'
import new2 from '../../assets/images/الخبر الثاني.webp'
import new3 from '../../assets/images/الهيئة السعودية للمدن الصناعية ومناطق التقنية.webp'

export default function Blogs() {
      
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
                
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

  return <>
  <div dir={language==='en'? '':'rtl'}>
                                      {/* header */}
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line text-center fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('newsText3')}</p>
        <div className='d-flex'>
          <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
          <span className='text-black px-2'>/</span>
          <p className='text-secondary'>{t('navMediaCenter')}</p>
        </div>
      </div>
    </div>  
    
                                      {/* content */}
    <div className='container-lg w-90 mx-auto'>
      <div className='pt-sm-5 pt-3 mt-3 mb-5 fw-medium'>
          <h3 className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('latestNews.text')}</h3>
        <div className={`mb-5 mt-1 row gx-lg-5 gy-5 gx-3 justify-content-center`}>
          <div className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new1} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>12/12/2024</p>
                <p className={`fw-medium py-2 lh-lg text-justify ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.1')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new2} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>29/12/2024</p>
                <p className={`fw-medium py-2 lh-lg text-justify ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.2')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new3} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>12/12/2024</p>
                <p className={`fw-medium py-2 lh-lg text-justify ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.3')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
}
