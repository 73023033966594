import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(languageDetector)
.use(initReactI18next)
.init({
    debug: true,
    lng:"ar",
    resources: {
        ar: {
            translation: {
                siteName:'المكيال السعودي',
                greeting:"أهلاً بكم في المكيال السعودي",
                text:".نسعد بزيارتكم ونفيدكم بأنه جاري العمل على تطوير الموقع",
                contact:":للتواصل",
                infoName:"شركة المكيال السعودي للاستشارات الهندسية",
                labName:"مختبرات المكيال السعودي الهندسية",
                mail:" :البريد الإلكتروني",
                phone:" :الجوال",
                more:"المزيد",
                back:'الرجوع للصفحة الرئيسية',
                details:"عرض التفاصيل",
                detailService:"تفاصيل الخدمة",
                all:"جميع المشاريع",
                forMore:'للمزيد',
                allProjects:"الكل",
                seeMore:"رؤية المزيد",
                readMore:"قراءة المزيد",
                send:"إرسال",
                location:'الرياض - حي القدس',
                //////nav
                textSelection:"المكيال السعودي هي مجموعة تتكون من شركة المكيال السعودي للاستشارات الهندسية ومختبرات المكيال السعودي للتشييد والبناء مرخصة ومعتمدة لدى العديد من الجهات الحكومية وشبه الحكومية وتمارس أعمالها وفق ضوابط ومقاييس عالية ويعمل بها نخبة من الكفاءات الفنية في كافة الفروع، ويمكن الحصول على المزيد من المعلومات عبر الضغط على اسم المنشأة أسفل الصفحة",
                lab1:'مختبرات المكيال السعودي',
                lab2:'لفحص التربة ومواد البناء',
                company1:'شركة المكيال السعودي',
                company2:'للاستشارات الهندسية',
                navHome:'الرئيسية',
                about:'من نحن',
                services:'خدماتنا',
                projects:'مشاريعنا',
                projectsLabs:'المشاريع والأعمال',
                navClients:'عملاؤنا',
                // navClients
                navGovernment:'جهات حكومية',
                navPrivate:'جهات خاصة',
                navSafety:'سياسيات الجودة والسلامة',
                navQualityLabs:'التطوير والجودة',
                navTrainingLabs:'التدريب ونقل المعرفة',
                navWorkshops:'ورش العمل ونقل المعرفة',
                navMediaCenter:'المركز الإعلامي',
                contactUs:'تواصل معنا',
                navPhoneT:'رقم الجوال',
                navLocationT:'العنوان',
                navEmailT:'البريد الإلكتروني',
                navProfile:'تحميل البروفايل',
                // navAbout
                navMessage:'رسالتنا',
                navOurMessage:'رؤية الشركة',
                navWhyUs:'من نحن',
                navGrowth:'النمو والتطور',

                // navServicesLabs
                navGeotechnical:'الهندسة الجيوتقنية ودراسات التربة',
                navSoil:'اختبارات التربة ومواد البناء والإنشاء',
                navChemical:'اختبارات التحليل الكيميائي للتربة والمياه',
                navBuilding:'اختبارات فحص المباني والمنشآت',
                navStudies:'الدراسات الفنية الخاصة للتربة',
                // navServicesCompany
                navArchitectural :'الهندسة المعمارية',
                navHydrological :'هندسة المياه والدراسات الهيدرولوجية',
                navGIS:'الأعمال المساحية ونظم المعلومات الجغرافية',
                navSupervision :'الإشراف على التنفيذ وإدارة المشاريع',
                navInfrastructure :'تصاميم البنية التحتية',
                navTransport :'هندسة النقل والمرور',
                navElectrical :'الهندسة الكهربائية',
                navEvaluation:'دراسة تقييم المباني والمنشآت',
                // navServiceSoil
                navSoilAggregateTest: 'اختبارات التربة ومواد الركام',
                navAsphaltTest: 'اختبارات الإسفلت',
                navLiquidBitumenTest: 'اختبارات البيتومين السائل',
                navConcreteTest: 'اختبارات الخرسانة وتصميم الخلطات الخرسانية',
                navBlockTileTest: 'اختبارات البلوك والبلاط والإنترلوك والبردورات',
                // navProjects
                navGeotechnicalProject:'الهندسة الجيوتقنية',
                navQualityProject:'ضبط الجودة لمشاريع البنية التحتية',
                navBuildingProject:'فحص المباني والمنشآت',
                //////footer
                footerAddress:'عنوان المقر الرئيسي',
                footerWorkHours:'أوقات العمل',
                footerWelcome:'مرحبا بك في المقر الشركة',
                footerWeekCompany:'الاحد - الخميس',
                footerWeekLabs:'السبت - الخميس',
                footerWeekTimeCompany: '7:30 صباحاً - 5:00 مساءً',
                footerWeekTimeLabs: '8:00 صباحاً - 4:00 مساءً',
                footerSaturday:'السبت',
                footerFriday:'الجمعة',
                footerHoliday:'عطلة',
                footerCompany:'عن الإستشارات',
                footerCompanyAbout:'شركة المكيال السعودي الهندسية، مكتبكم الهندسي المعتمد في قلب الرياض، يُعد وجهتكم الأولى للحصول على استشارات هندسية متكاملة ومتخصصة',
                footerLabs:'عن المختبرات',
                footerLabsAbout:'شركة المكيال السعودي الهندسية، مكتبكم الهندسي المعتمد في قلب الرياض، يُعد وجهتكم الأولى للحصول على اختبارات هندسية متكاملة ومتخصصة',
                footerLinks:'روابط مهمه',
                ///////header
                // headerNameEngineering:'الهندسية',
                headerCompanyEngineering:'شركة المكيال السعودي',
                headerNameCompany:'للإستشارات الهندسية',
                headerNameLabs:'المختبرات',
                headerSentence:'.نلتزم بتقديم حلول هندسية مبتكرة وشاملة تلبي احتياجات عملائنا وتساهم في تحقيق رؤيتهم',
                headerAboutCompany:'تأسست شركة المكيال السعودي للاستشارات الهندسية في عام 1432 هـ. كشركة هندسية وطنية تقدم كافة خدمات الاستشارات الهندسية وتشمل خدماتنا الإشراف على المشاريع، التصاميم الإنشائية والمعمارية، هندسة المساحة والطبوغرافيا، هندسة المياه، الهندسة البيئية، بالإضافة إلى الهندسة الكهربائية.نتميز بفريق متكامل من المتخصصين ذوي الخبرة والكفاءة العالية في كافة مجالات الاستشارات الهندسية.نحن نؤمن بأهمية تقديم حلول متكاملة ومبتكرة تلبي احتياجات عملائنا وتعزز من جودة وكفاءة المشاريع الهندسية. نسعى باستمرار إلى تحقيق التميز من خلال الالتزام بأعلى معايير الجودة والمعرفة المتجددة.',
                headerAboutLabs:'هي مجموعة مختبرات تشييد وبناء وطنية رائدة في مجال فحص التربة واختبارات المواد الإنشائية (تربة - خرسانة – أسفلت...)، مواد البناء والدراسات الجيوتقنية والدراسات الفنية الخاصة في مجال هندسة التربة والأساسات، واختبارات فحص وتقييم المباني والمنشآت وتقديم الاستشارات الفنية في هذه المجالات. يمتلك المكيال السعودي أفضل الكفاءات والكوادر الفنية وأحدث التجهيزات والتقنيات والمعدات المخبرية في مجال الاختبارات والفحص، ويطبق أحدث البرامج والتطبيقات في مجال المختبرات الهندسية على مستوى المملكة العربية السعودية.',
                ///////servicesLab
                service3LabSentence:'تشمل خدماتنا اختبارات التربة ومواد الركام، اختبارات الإسفلت، اختبارات البيتومين السائل، اختبارات الخرسانة وتصميم الخلطات الخرسانية، بالإضافة إلى اختبارات البلوك والبلاط والإنترلوك والبردورات',
                service2LabSentence:'تشمل خدمات التحليل الكيميائي تحديد الأس الهيدروجيني (pH)، وتحديد نسبة الكبريتات، وتحديد نسبة الكلوريدات، بالإضافة إلى قياس ملوحة التربة والإجمال الكلي للأملاح الذائبة.',
                service1LabSentence:'متخصص في التحريات الحقلية واختبارات التربة باستخدام حفارات متطورة. يقدم تقارير شاملة تتضمن تحليل التربة، تحديد منسوب المياه الجوفية، وحلول للمشاريع',
                service4LabSentence:'تشمل الخدمات أخذ عينات الخرسانة القلبية، الكشف عن حديد التسليح، اختبار مقاومة المواد، وتجربة تحميل السقف.',
                service5LabSentence:'تشمل الخدمات أخذ عينات الخرسانة القلبية، الكشف عن حديد التسليح، اختبار مقاومة المواد، وتجربة تحميل السقف.',
                aggregateTesting:'اختبارات الركام',
                ///////servicesCompany
                service1CompanySentence:'يشمل التخطيط العمراني (السكنية، التجارية، الصناعية، وتخطيط الحدائق) والتصميم المعماري للمشاريع الحكومية أو السكنية أو التجارية',
                service2CompanySentence:'نقدم خدمات شاملة في الدراسات الهيدرولوجية ودرء مخاطر السيول، باستخدام نمذجة متقدمة لتصميم قنوات التصريف الحضري وتحليل الطبوغرافيا والخصائص المورفولوجية.',
                service3CompanySentence:'نقدم خدمات مساحية تشمل الرفع الطبوغرافي، إنشاء نقاط تحكم، واستخدام تقنيات مثل الطائرات المسيرة (درون) وجهاز GPR. يتم تحويل البيانات إلى رسومات رقمية وإدخالها في نظم المعلومات الجغرافية (GIS)',
                service4CompanySentence:'نقدم خدمات شاملة تشمل دراسة المخططات الفنية، تقدير الكميات، وتحديد التكاليف مع الإشراف على التنفيذ لضمان الكفاءة. كما نشرف على تنفيذ البنية التحتية (طرق، مياه، صرف، كهرباء) مع إعداد تقارير دورية',
                service5CompanySentence:'نقدم خدمات دراسات وتصاميم هندسية للبنية التحتية تشمل شبكات الطرق، المياه، الصرف الصحي، والسيول، مع الإشراف على التنفيذ وحساب الكميات والأعمال وفق أعلى المعايير الهندسية',
                service6CompanySentence:'نقدم خدمات استشارية لمشاريع الطرق تشمل جميع مراحل ما قبل التنفيذ والتنفيذ والتشغيل، مع التركيز على حركة المركبات والمشاة باستخدام أحدث البرمجيات للتحليل والتصميم',
                service7CompanySentence:'نقدم خدمات متكاملة تشمل تصميم شبكات الجهد المنخفض والمتوسط، وإنارة الطرق وفق متطلبات الجهات المختصة، وتصاميم المشاريع السكنية والتجارية والحكومية حسب كود البناء السعودي. كما نشرف على إنشاء وصيانة شبكات التوزيع',
                service8CompanySentence:'نقدم خدمات تقييم السمة الإنشائية للمباني لضمان سلامتها واستمرار الاستثمار فيها بأمان، بما في ذلك تأهيل المباني، معالجة العيوب الإنشائية، وإطالة عمر المنشآت',
                ///////Achievements
                achievements:'إنجازاتنا',
                experience:'عام من الخبرة',
                million:'مليون متر مربع',
                land:'أرض',
                entity:'جهة',
                buildings:'مبنى ومنشآت',
                kilometer:'كلم طولي',
                project:'مشروع',
                achieveProjects:'مشاريع',
                years:'سنوات',
                companies:'جهة خاصة',
                plans:'مخطط حكومي',
                landsPlans:'أراضي ومخططات',
                reports:'تقرير ودراسات',
                towers:'برج ومباني مرتفعة',
                bridges:'جسر ونفق',
                technical:'دراسة فنية',
                test:'دراسة',
                // Company Achievements
                companyAchieve1:'في مجال تقديم الاستشارات الهندسية',
                companyAchieve2:'حكومية وشبه حكومية وخاصة يعمل معها المكيال السعودي',
                companyAchieve3:'تم تصميمها لاستخدامات متنوعة (سكني – تجاري – حكومي)',
                companyAchieve4:'تم رفعها مساحياً بواسطة فرق محترفة',
                companyAchieve5:'خام تم تخطيطها',
                companyAchieve6:'تم تصميمها كشبكات مياه وصرف صحي',
                companyAchieve7:'الإشراف على أعمال الكهرباء والمرتبطة بالشركة السعودية للكهرباء',
                companyAchieve8:'طرق رئيسية وفرعية تم تصميمها',
                companyAchieve9:'خرسانية تم فحصها وتقييمها',
                companyAchieve10:'تم الإشراف عليه',
                companyAchieve11:'تم دراستها هيدرولوجياً وتم إعداد دراسة تصريف ودرء مخاطر السيول والأمطار لها',
                // Labs Achievements
                labsAchieve1:'في مجال المختبرات الهندسية',
                labsAchieve2:'حكومية وشبه حكومية  معتمدين لديها',
                labsAchieve3:'معتمدين لديها',
                labsAchieve4:'تمت دراستها',
                labsAchieve5:'سكنية تم عمل جسات ودراسات تربة لها',
                labsAchieve6:'تم إنجازها',
                labsAchieve7:'تمت دراسة تربة تأسيسها',
                labsAchieve8:'تمت دراسته فنياً',
                labsAchieve9:'لفحص المباني والمنشأت',
                labsAchieve10:'تم عمل جسات ودراسة تربة لها',
                labsAchieve11:'ضبط جودة ومواد',
                labsAchieve12:'كشف تكهفات أرضية',
                ///////projects
                projectTextCompany:'بعض من مشاريع شركة المكيال السعودي للاستشارات الهندسية',
                projectTextLabs:'بعض من مشاريع مختبرات المكيال السعودي لفحص التربة ومواد البناء',
                projectHeader:'نحو مستقبل مشرق مع كل مشروع ننجزه',
                projectsIntro1:'مختبرات المكيال السعودية نفذت العديد من المشاريع النوعية في مجال اختبار ودراسة التربة والأساسات، والدراسات الجيوتكنيكية، واختبارات مواد التربة، والأسفلت، والخرسانة، ومواد البناء، بالإضافة إلى فحص وتقييم المباني',
                projectsIntro2:'سنذكر فيما يلي بعض أهم المشاريع ومجالات العمل',

                projectDetails:{
                    customer: 'الجهة المالكة :',
                    workScope: 'نطاق عمل :',
                    geographicalScope:'النطاق الجغرافي :',
                    executionYear:'سنة التنفيذ :',
                    startDate:'بداية التنفيذ :',
                    status:'الحالة :',
                },
                related:'مشاريع ذات الصلة',
                //projects labs
                projects1:{
                    1:{
                        name: 'عمل جسات تربة لأراضي ومخططات في بلديات المحور الجنوبي في منطقة الرياض',
                        customer: 'أمانة منطقة الرياض الوكالة المساعدة لمشاريع بلديات منطقة الرياض',
                        workScope: 'عمل جسات تربة لأراضي ومخططات في بلديات المحور الجنوبي في منطقة الرياض',
                        geographicalScope:'لعمل المشروع والبلديات المستفيدة من المشروع بلديات المحور الجنوبي لمنطقة الرياض - الخرج الدلم حوطه بني تميم الحلوة الحريق الافلاج البديع الأحمر السليل وادي الدواسر',
                        description: '',
                    },
                    2:{
                        name: 'مشروع أعمال جسات وفحص التربة لمشروع الوديان شمال الدرعية مساحة المخطط 7,000,000 متر مربع عدد الجسات 2795 جسة',
                        customer: 'شركة الوديان العقارية السعودية',
                        workScope: 'عمل جسات تربة وأعداد دراسة تربة لمخطط الوديان واعتماد الدراسة في أمانة الرياض',
                        geographicalScope:'مخطط الوديان شمال مدينة الدرعية طريق صلبوخ – منطقة الرياض',
                        description: '',
                    },
                    3:{
                        name: 'دراسة التربة للمشاريع التعليمية بمدينة جدة',
                        customer: 'شركة تطوير للمباني TBC',
                        workScope: 'أعمال جسات وفحص التربة لعدى مواقع مدارس في مدينة جدة وإعداد الدراسة الجيوتقنية لها',
                        description: '',
                    },
                    4:{
                        name: 'أعمال اختبارات وفحص التربة سدرة 2 – بمدينة الرياض',
                        customer: 'شركة روشن العقارية',
                        workScope: 'أعمال جسات وفحص تربة واختبارات حقلية ومعملية في موقع أعمال سدرة 2 شمال الرياض',
                        description: '',
                    },
                    5:{
                        name: 'اختبارات المواد في مدينة الجبيل الصناعية',
                        customer: 'الهيئة الملكية بالجبيل وينبع',
                        workScope: 'إجراءات اختبارات المواد لتأكيد الجودة لمشاريع تتبع للهيئة الملكية في مدينة الجبيل الصناعية لمدة خمس سنوات',
                        status:'جاري التنفيذ',
                        description: '',
                    },
                    6:{
                        name: 'اختبارات التربة والمواد واعمال ضبط جودة لمشاريع البنية التحتية في مدينة الرياض – اتفاقية إطارية',
                        customer: 'مركز مشاريع البنية التحتية – أمانة مدينة الرياض',
                        workScope: 'أعمال اختبارات التربة والخرسانة والاسفلت لضبط جودة تنفيذ مشاريع الطرق والخدمات والبنية التحتية (مياه – صرف صحي – كهرباء) بمدينة الرياض',
                        status:'مستمر ضمن إطار عمل المختبرات المعتمدة من قبل أمانة مدينة الرياض',
                        description: '',
                    },
                    7:{
                        name: 'اختبارات دراسة وتقييم الحالة الإنشائية لقصر الملك خالد والمباني المحيطة به في محمية الملك خالد الملكية',
                        customer: 'هيئة تطوير محمية الإمام عبد العزيز بن محمد الملكية',
                        workScope: 'إجراء الاختبارات والفحوصات لتقييم الحالة الإنشائية لقصر الملك خالد والمباني والملحقات المحيطة به في محمية الملك خالد',
                        description: '',
                    },
                    8:{
                        name: 'دراسة الهيكل الإنشائي لمواقع متعددة بمدينة الملك عبد العزيز العسكرية بتبوك',
                        customer: 'الأشغال العسكرية – وزارة الدفاع',
                        workScope: 'إجراء اختبارات وفحوصات وعمل دراسة للهيكل الإنشاء لمواقع متعددة بمدينة الملك عبد العزيز العسكرية بتبوك',
                        geographicalScope:'منطقة تبوك',
                        description: '',
                    },
                    9:{
                        name: 'مشروع تقييم إنشائي لمبنى العزاب بالمركز الرئيسي للمؤسسة العامة لتحلية المياه المالحة والمبنى الإداري بالتخصصي في الرياض',
                        customer: 'الهيئة السعودية للمياه (المؤسسة العامة لتحلية المياه المالحة سابقاً)',
                        workScope: 'تقييم إنشائي وأعداد دراسة بالحالة الإنشائية',
                        geographicalScope:'مدينة الرياض',
                        description: '',
                    },
                },

                //projects company
                projects2:{

                },

                //////form
                contactHeader:{
                    text1:'نحن هنا لمساعدتك!',
                    text2:'يسعدنا تواصلك معنا للإجابة على استفساراتك وتقديم الدعم الهندسي اللازم لمشاريعك. ',
                    text3:'لا تترددوا في التواصل معنا عبر الوسائل التالية:',
                    text4C:'كما يمكنكم زيارتنا في مقرنا خلال ساعات العمل، من الأحد إلي الخميس من الساعة الثامنة صباحاً إلى الساعة الخامسة مساءاً.',
                    text4L:'كما يمكنكم زيارتنا في مقرنا خلال ساعات العمل، من السبت إلي الخميس من الساعة الثامنة صباحاً إلى الساعة الرابعة مساءاً.',
                    text5:'أو إرسال استفساراتكم عبر نموذج التواصل التالي: ',
                    text6:'يسعدنا خدمتكم ونتطلع إلى تعاون مثمر يحقق أهدافكم الهندسية بكل دقة واحترافية.',
                    contactForm:'نموذج التواصل',
                    linkedIn:'لينكدان',
                },
                firstName:'الأسم الأول',
                yourFirstName:'اسمك الأول',
                lastName:'الأسم الأخير',
                yourLastName:'اسمك الأخير',
                formEmail:'البريد الإلكتروني',
                yourEmail:'بريدك الإلكتروني',
                formPhone:'الهاتف',
                yourPhone:'هاتفك',
                subject:'الموضوع',
                yourSubject:'علي سبيل المثال : الدعم',
                message:'الرسالة',
                yourMessage:'رسالتك',
                fullName:'الأسم الكامل',
                yourFullName:'اسمك الكامل',
                companyName:'اسم الشركة/ المؤسسة',
                optional:'اختياري',
                serviceDetails:'تفاصيل الخدمة المطلوبة',
                attachedFiles: "الملفات المرفقة",
                architecturalDesign: "تصميم معماري",
                infrastructureConsulting: "استشارات بنية تحتية",
                projectSupervision: "اشراف على المشاريع",
                environmentalConsulting: "استشارات بيئية",
                electricalProjectSupervision: "إشراف على مشاريع الكهرباء",
                other: "أخرى (يرجى التوضيح)",
                select:'حدد',
                projectSite:'الموقع الجغرافي للمشروع',
                site:'المدينة / المنطقة',
                additionalNotes:'ملاحظات إضافية',
                anyInformation:'أي معلومات أخري تود إضافتها',
                favoriteContact:'كيفية التواصل المفضلة',
                emailContact: "بريد إلكتروني",
                phoneContact: "اتصال هاتفي",
                meetingContact: "اجتماع شخصي",
                ///////news
                newsText1:'سنبقيك على اطلاع بأحدث أخبار المكيال السعودي',
                newsText2:'تصفح أكثر الأخبار شهرة لهذا الشهر',
                newsText3:'كن على اطلاع بآخر أخبار وإنجازات المكيال السعودي',
                latestNews:{
                    text:'آخر أخبار مختبرات المكيال السعودي:',
                    1:'حصل مختبر المكيال السعودي في الخبر ( فرع المنطقة الشرقية )  على شهادة الاعتماد من المركز السعودي للاعتماد ( ساك) برقم  N-T-00517  وفق متطلبات المواصفة القياسية  ISO /IEC 17025:2017   الخاصة بالمتطلبات العامة للجودة لكفاءة مختبرات الفحص والمعايرة .',
                    2:'حصل مختبر المكيال السعودي في الخبر ( فرع المنطقة الشرقية )  على رخصة لممارسة استخدام المصادر الإشعاعیة للقیاس والاستكشاف – رخصة رقم  10739ER-01 من هيئة الرقابة النووية و الإشعاعية في المملكة العربية السعودية .',
                    3:'تم ترسية مشروع إدارة أعمال ضبط جودة واختبارات الأعمال التنفيذية في مبادرة الواحات والمدن الصناعية الأخرى  على المكيال السعودي من قبل الهيئة السعودية للمدن الصناعية ومناطق التقنية .',
                },

                ///////departments
                departments: "الأقسام والتخصصات",
                departmentHeader1: "اختبارات مواد البناء والإنشاء",
                departmentText1: "فحص جودة المواد لضمان مطابقتها للمعايير والمواصفات.",
                departmentHeader2: "مشاريع ضبط الجودة",
                departmentText2: "مراقبة الجودة في المشاريع لضمان التنفيذ وفق الاشتراطات الهندسية.",
                departmentHeader3: "الهندسة الجيوتقنية والدراسات الفنية",
                departmentText3: "تحليل التربة والأساسات لتقديم حلول هندسية دقيقة.",
                departmentHeader4: "اختبارات فحص المباني والمنشآت",
                departmentText4: "تقييم سلامة وأداء المباني لضمان الاستدامة والأمان.",
                departmentHeader5: "وحدة التحاليل الكيميائية للمياه والتربة",
                departmentText5: "لمعرفة خصائص المواد الكيميائية في التربة والمياه وأثرها على أساسات المنشآت.",
                  
                ///////branches
                branches:'فروع مختبرات المكيال السعودي',
                // branchesTextCompany:'نسعى لتقديم خدمات استشارية هندسية احترافية بأعلى المعايير',
                // branchesTextLabs:'نسعى لتقديم خدمات اختبارات هندسية احترافية بأعلى المعايير',
                branch1:'المقر الرئيسي – مدينة الرياض',
                branch1Detailed:' مدينة الرياض - حي القدس',
                branch2:'فرع المدينة المنورة',
                branch2Detailed:'2978 خولة بنت حذيفة بن اليمان، حي الروابي، المدينة المنورة 42381، المملكة العربية السعودية',
                branch3:'فرع المنطقة الشرقية – الخبر',
                branch3Detailed:'شارع النجاح، حي ابن سينا، الخبر 34627، المملكة العربية السعودية.',
                branch4:'فرع المنطقة الشرقية – الجبيل',
                branch4Detailed:'ميناء الملك فهد الصناعي، الجبيل 35525، المملكة العربية السعودية',

                // About Page 
                engineeringConsultancy: "نحن نقدم استشارات هندسية متخصصة لتحويل مشاريعك إلى واقع.",
                engineeringLaboratories: "مختبراتنا الهندسية توفر اختبارات دقيقة لدعم قراراتك الهندسية.",
                ourProjects:'مشاريعنا',
                visionSentenceLabs:'شغف الريادة والارتقاء في الأداء والطموح لوصول المكيال السعودي إلى مكانة مرموقة في مجال هندسة التربة والمواد ومختبرات التشييد والبناء.',
                goalsSentenceLabs:'أن نكون اسمًا رائدًا في مجال مختبرات التشييد والبناء على مستوى المملكة والشرق الأوسط.',
                valuesSentenceLabs:'قيمنا تحدد سلوكنا وهو العمل بجهد ومهنية عالية وتطبيق أعلى معايير الجودة في تقديم خدماتنا.',
                visionSentenceCompany:'أن نكون شركاء موثوقين في بناء المشاريع النوعية، عبر تقديم خدمات هندسية متكاملة تلبي تطلعات عملائنا وتواكب التطور السريع في قطاع الهندسة والإنشاءات والوصول إلى مكانة رائدة في القطاع الهندسي بالمملكة العربية السعودية',
                OurMessageCompany:'تقديم خدمات هندسية متكاملة بجودة عالية واحترافية، تركز على تلبية احتياجات عملائنا وتحقق رؤاهم وتساهم في تطوير بنية تحتية مستدامة تواكب تطلعات رؤية المملكة 2030',
                goalsSentenceCompany:'أن يكون المكيال السعودي أسماً موثوقاً به ومصدر رائداً للحلول الهندسية في المملكة العربية السعودية من خلال الالتزام الكامل مع العملاء والريادة في الابتكار، كما أننا نعمل على تطوير مهارات فريقنا بشكل مستمر لضمان تقديم خدمات متفوقة واحترافية في مجال الاستشارات الهندسية',
                aboutHeaderEngineering: 'تقديم حلول بناء هندسية مبتكرة مع الالتزام بالتميز',
                aboutHeaderLaboratories: 'تعزيز معايير البناء من خلال مختبرات هندسية متقدمة',
                aboutSentenceEngineering: 'نعتقد أن النجاح في البناء يعتمد على التعاون والفهم المشترك. البناء ليس مجرد تجميع للهياكل؛ بل هو بناء حلول مستدامة وعملية تلبي احتياجات عملائنا والمجتمع.',
                aboutSentenceLaboratories: 'نعتقد أن الدقة والموثوقية هما المفتاح لنجاح الهندسة، ونعمل عن كثب مع عملائنا لضمان أن كل اختبار وتحليل يلتزم بأعلى المعايير. مختبراتنا مجهزة بأحدث التقنيات لدعم عملية البناء وجودة المواد.',
                aboutOrganization: 'المخطط التنظيمي',
                aboutCompanyOrganization: 'الهيكل التنظيمي للشركة',
                navVision:'الرؤية',
                navGoals:'أهدافنا',
                navValues:'قيمنا',
                navOurVision:'رؤيتنا',
                aboutText1:'شركة المكيال السعودي للاستشارات الهندسية هي شركة هندسية وطنية تم إنشاؤها في عام 1432 هـ. تتميز الشركة بفريق متكامل من المتخصصين ذوي الخبرة والكفاءة العالية في كافة مجالات الاستشارات الهندسية.',
                urbanPlanning:'التخطيط العمراني',
                urbanDesign:'التصميم العمراني',
                aboutText2:'منذ البداية، عملنا مع القطاعين العام والخاص، وشاركنا في تنفيذ مشاريع هامة مع جهات حكومية مثل وزارة الصحة، وزارة النقل، وزارة البيئة والمياه والزراعة، والعديد من الجهات الأخرى. كان لهذه الشراكات أثر كبير في تعزيز خبرتنا وسمعتنا، حيث نجحنا في الجمع بين الخبرة والدقة والاحترافية.',
                managerWord:'كلمة المدير العام للشركة :',
                managerWordSentence:'نحن في المكيال السعودي ملتزمون مهنياً بتطبيق أفضل المعايير وأحدث التقنيات في مجال الخدمات الهندسية، كما نطمح لتقديم هذه الخدمات بمستوى أداء عالي وجودة مميزة وذلك ليتمكن شركائنا من الاستفادة من خبراتنا لإتمام متطلباتهم، كما أننا نقدم خدماتنا في كافة المجالات الهندسية بواسطة نخبة مختارة من المتخصصين والخبراء وفق الأسس والمعايير العالمية ونسعى دوما للتطور وذلك للمساهمة في بناء مستقبل مبهر.',

                // about growth
                growth1 : 'تأسيس المكيال السعودي',
                growth2 : 'تقديم خدمات هندسية لجهات حكومية',
                growth3 : 'إنشاء مختبرات المكيال السعودي الهندسية',
                growth4 : 'تطبيق نظام إدارة الجودة الأيزو 9001 ونظام الصحة والسلامة المهنية الأيزو 45001',
                growth5 : 'التوسع وفتح فروع جديدة في مناطق متعددة بالمملكة',
                growth6 : 'تأهيل المكيال السعودي في مشاريع نوعية لدى جهات حكومية',
                growth7 : 'حصول مختبرات المكيال السعودي على شهادة الأيزو 17025',
                growth8 : 'تصنيف المكيال السعودي بـ “الدرجة الأولى"',
                growth9 : 'الانضمام إلى تحالف مع مكاتب أجنبية للعمل في مشاريع كبرى',
                growth10 : 'العمل مع الهيئات الملكية في أعمال التصميم وضبط الجودة',

                // about important engineers
                importantEngineers: 'أهم الكوادر في المكيال السعودي',
                eng1:'م. حاتم العثمان',
                eng1JobTitle:'المدير التنفيذي لمختبرات المكيال السعودي',
                eng1About:'مهندس متخصص في مجال المختبرات الهندسية والهندسة الجيوتقنية، يتمتع بخبرة كبيرة تمتد لأكثر من 22 عام في تأسيس وتطوير وإدارة المختبرات الهندسية، حاصل على درجة البكالوريوس في الهندسة المدنية – تخصص هندسة جيوتقنية 2002 م من جامعة حلب - سوريا، عمل مهندس مواد ثم مديراً لقسم التربة والمواد في شركة دار الخليج للهندسة وساهم في إنشاء وتطوير المختبرات فيها، كما عمل محاضراً في معهد الطرق للتدريب في هندسة التربة والمواد في الفترة من 2004 إلى 2011، تولى منصب مدير عام مشاريع مؤسسة نايس للمقاولات حتى عام 2015. بعد ذلك عمل على تأسيس وإدارة مختبر دار الفحوصات من عام 2015 حتى 2018. انتقل بعد ذلك إلى المكيال السعودي حيث عمل على تطوير مختبرات المكيال السعودي وإنشاء عدة فروع في المملكة، مع التركيز على تحقيق أعلى معايير الجودة في الفحوصات الهندسية، يعمل حالياً مديراً تنفيذياً لمجموعة مختبرات المكيال السعودي.',
                
                eng2: 'م. نادر الإخوان',
                eng2JobTitle: 'مدير مختبر الرياض - ورئيس قسم الدراسات الفنية والهندسة الجيوتقنية',
                eng2About: 'حاصل على درجة البكالوريوس في الهندسة المدنية تخصص الهندسة الجيوتقنية من جامعة حمص في سوريا، ويمتلك خبرة تزيد عن 26 عامًا في مجالات الدراسات الفنية، الهندسة الجيوتقنية، بدأ مسيرته المهنية في المؤسسة العامة للإسكان في سوريا، قبل أن ينتقل إلى المملكة العربية السعودية في عام 1998 للعمل في شركة المختبرات السعودية، حيث شغل مناصب متعددة حتى تولى إدارة قسم الهندسة الجيوتقنية. أسهم في تأسيس مختبر شركة أديما كمدير تنفيذي تحت مظلة المركز العربي للاستشارات الهندسية، انضم إلى مجموعة المكيال السعودي الهندسية في عام 2022، ويشغل حالياً منصب رئيس قسم الدراسات الفنية والهندسة الجيوتقنية.',

                eng3: 'م. محمد اليوسف',
                eng3JobTitle: 'المدير الإقليمي لمختبر المدينة المنورة',
                eng3About: 'حاصل على درجة البكالوريوس في الجيولوجيا الهندسية من جامعة حلب عــــــــــام 2000 م.  عمل في شركة عبد المحسن الخرافي الكويتية في مشاريع الطرق والجسور في سوريا لغاية 2003 م _  ثم  في شركة محمد كوناش التركية مديراً لمختبر المواد في مشروع استصلاح أراضي شمال سوريا لغاية عام 2005 م ثم انتقل بعد  ذلك للعمل في مشروع في مكتب  الرشيد لهندسة التربة  والمواد في جمهورية جيبوتي  كمهندس مقيم لمشروع حفر آبار المياه الجوفية  ،  انتقل في عام 2006إلى الرياض بالمملكة  العربية السعودية حيث عمل في شركة دار الخليج للهندسة في مشروع مختبر المدينة المنورة  واستمر فيها لغاية  عام  2014 م  ، ثم عمل في الشركة العربية للمختبرات والتربة مديراً لفرع المدينة المنورة واستمر لعام 2019 م . ويعمل من 2019 ولغاية الآن مديراً لمختبر المكيال السعودي - فرع المدينــــة المنورة.',

                eng4: 'م. محمد علي الصليبي',
                eng4JobTitle: 'المدير الإقليمي لمختبرات المنطقة الشرقية ',
                eng4About: 'حاصل على درجة البكالوريوس في الجيولوجيا عام 2001 م من جامعة اللاذقية في سوريا. يمتلك خبرة تمتد لأكثر من 20 سنة في مجال اختبارات المواد والتربة. بدأ مسيرته المهنية في الشركة السورية للطرق السريعة - المنطقة الوسطى- كمهندس مواد في مشروع أوتوستراد حمص- تدمر. ثم انتقل للعمل في المملكة العربية السعودي عام 2005 م في شركة دار الخليج للهندسة كمدير لمختبر الشركة في الرياض حتى عام 2010 م. ثم عمل في شركة عبد العالي العجمي للمقاولات كمدير لمختبرات الشركة حتى عام 2022 م.  ويعمل من تاريخ 2023 م وحتى الأن مديراً لفرع شركة المكيال السعودي في المنطقة الشرقية.',

                eng5: 'م. إسلام طلعت',
                eng5JobTitle: 'مدير مختبر فرع الجبيل',
                eng5About: 'خريج كلية الهندسة المدنية جامعة الإسكندرية 2004، يمتلك خبرة تمتد لأكثر من 20 سنة في  القطاع الهندسي،  عمل في مجموعة طلعت مصطفى  لمدة عام ثم انتقل للعمل في المملكة العربية السعودية حيث عمل في شركة المبطي للمقاولات لمدة ثلاث سنوات ثم عمل في مجال الخدمات والاستشارات الهندسية في المنطقة الشرقية وفي عام 2016 عمل في مجال المختبرات الهندسية حيث عمل في  دار الخليج للهندسة مديراً لقسم التربة والمواد بالرياض حتى عام 2020 م ،  ثم مديراً لمختبر GHL في الجبيل حتى  2023م ، يعمل حالياً في مختبرات المكيال السعودي مديراً المختبر الجبيل، ومدير تطوير الاعمال في المنطقة الشرقية .',

                eng6: 'د. محمد صبحي',
                eng6JobTitle: 'مدير قسم الهندسة الجيوتقنية – مختبر المدينة المنورة',
                eng6About: 'حاصل على درجة الدكتوراه في الهندسة الجيوتقنية من جامعة طنطا عام 2021، ودرجة البكالوريوس في الهندسة الإنشائية بتقدير امتياز مع مرتبة الشرف عام 2010. بدأ مسيرته الأكاديمية كمعيد في قسم الهندسة المدنية بكلية الهندسة في جامعة طنطا، ثم عمل مدرسًا في قسم الهندسة الإنشائية، متخصصًا في أبحاث التربة وهندسة الأساسات. تدرج في العمل الوظيفي في العديد من الشركات الخاصة بمجال دراسات التربة وهندسة الأساسات في مصر، ثم انتقل للعمل في المملكة العربية السعودية كمدير لقسم الهندسة الجيوتقنية والأساسات بشركة محمد عمر الجزار للاستشارات الهندسية، وحالياً يدير قسم الهندسة الجيوتقنية والأساسات بفرع المدينة المنورة لمختبر المكيال السعودي.',

                servicesHeader: 'نقدم خدمات لدعم مسيرتك ومساعدتك على الازدهار',
                ////////Services Page Labs
                servicesText1:'مختبرات المكيال السعودي للتشييد والبناء',
                servicesText2:'تقدم مجموعة من خدمات الفحص والاختبار التي تغطي مجالات واسعة في قطاع التشييد والبناء واختصاص الهندسة الجيوتقنية وهندسة المواد',

                // service1
                geoHeader:{
                    section1:{
                        text1:'تعتبر الهندسة الجيوتقنية أحد أهم مجالات العمل والتخصص في مختبرات المكيال السعودي.',
                        text2:'لدى المكيال السعودي تجهيزات ومعدات كاملة لإجراء جميع التحريات الحقلية والاختبارات الجيوتقنية المعملية،بما في ذلك من حفارات أخذ العينات على أعماق مختلفة وتحديد منسوب المياه حيث يمتلك قسم الهندسة الجيوتقنية عدد من الحفارات والمثبتة على شاحنات ويمكنها حفر الجسات وأخذ العينات في جميع انحاء المملكة ، واعداد التقرير الفني المتكامل واعطاء الحلول والتوصيات الفنية.',
                    },
                    section2:{
                        text:'يعمل على هذه الحفارات عدد من الطواقم الفنية متمثلة بحفارين محترفين ومساعدين حفارين وعمال مدربين ويشرف على فرق العمل الحقلية عدد من الجيولوجيين المختصين ومهندسين جيوتكنيك ذوي خبرة .',
                    },
                    section3:{
                        text:'تقوم منهجية دراسات فحص التربة على العناصر التالية :',
                        1:{
                            header:'اهداف دراسة التربة :',
                            text:'تهدف الدراسة إلى تحديد : خصائص وطبيعة طبقات التربة في المخطط, ووصف تربة الموقع السطحية و طبقات التربة تحت السطحية على كامل أعماق الجسات , وتحديد جهد تحمل التربة الحدي والمسموح به ، وتحديد عمق التأسيس ونوع الأساسات المناسب ،كذلك الهبوطات المتوقعة والتفاضلية ، ومنسوب البساط المائي في حال وجوده ، ويتضمن التقرير كافة التجارب والاختبارات الحقلية والمخبرية، وخصائص ونوعية ناتج تربة الحفر وأعمال الردم ونوعية الخرسانة المستعملة في الأساسات والتوصيات الفنية والمقترحات الهندسية اللازمة.',
                        },
                        2:{
                            header:'تحديد عدد الجسات وأعماقها و توزيع الجسات :',
                            text:'توزيع الجسات على كامل مخطط المشروع على شكل شبكة كل 50 متر جسة, وبمعدل 2500 متر مربع جسة واحدة وذلك حسب اشتراطات الدراسات الجيوتقنية لمخططات السكنية الصادر عن وزارة الشئون البلدية والقروية. أما عمق الجسات فقد حددت المواصفات بأن عمق الجسات هو 10 متر في التربة ، وفي حال الطبقات الصخرية فإنه يتم الوصول للطبقات الصخرية المستمرة والحفر فيها من 3-5 متر ( أي اختراق الطبقات الصخرية من 3- 5 متر ) حسب صلابة . يتم تحديد عدد الجسات وتوزعها حسب مواصفات الدراسات الجيوتقنية الصادرة من وزارة الشئون البلدية والقروية.',
                        },
                        3:{
                            header:'دراسة جيولوجية المنطقة :',
                            text:'وفيه يتم تحديد الطبيعة الجيولوجية لمنطقة المشروع والتكوين الجيولوجي السائد والنطاق الجيولوجي ( الصفيحة العربية – الدرع العربي ... إلخ ) بالاعتماد على الخرائط الجيولوجية المعتمدة',
                        },
                        4:{
                            header:'تحديد النطاق الزلزالي :',
                            text:'ويتم تحديد المنطقة الزلزالية والنطاق الزلزالي ومعامل النطاق الزلزالي وتصنيف التربة والصخر زلزالياً .',
                        },
                        5:{
                            header:'التحريات الحقلية المنفذة :',
                            text1:'تم الحفر بواسطة آليات حفر دورانية نوع موبايل دريل Drilling Machine Mobile ، وباستخدام عدد من الحفارات ، حيث تم أخذ العينات الترابية السطحية وحفظها في عينات بلاستيكية وجلبها للمختبر لإجراء التجارب المخبرية عليها ، أما العينات الصخرية فتم جمعها بواسطة صناديق بلاستيكية وخشبية مخصصة لذلك حسب كل كور مستخرج بحيث يسهل توصيف العينات الصخرية عند كل عمق في الحقل وقياس معاملات جودة الصخر RQD والغطاء الصخري الكلي TCR . تم توصيف كل عينة أو صندوق كورات الجسة ( عينات الصخر اللبية ) بلوحة ورقية (Label) يحدد فيه بيانات المشروع ورقم الجسة وتاريخها ورقم الحفار واسم المشغل ليسهل تدوين معلوماتها ومن ثم رسم القطاع الطولي للجسة Borehole Log والذي يوصف كامل مقطع الجسة المنفذة وطبيعة وخصائص طبقات التربة والصخور وأعماق كل منها .',
                            text2:'كما تشمل التحريات الحقلية وجود بساط للمياه تحت السطحية من عدمه وعمقه في حال وجوده .',
                        },
                        6:{
                            header:'اختبارات الحقلية والمعملية :',
                            list:{
                                1:'اختبار المحتوى المائي للعينات (نسبة الرطوبة) (ASTM D – 2216)',
                                2:'اختبار التدرج الحبيبي للتربة (ASTM D – 422)',
                                3:'اختبارات حدود اتربرغ (حد السيولة وحد اللدونة ) (ASTM D – 4318)',
                                4:'اختبار القص المباشر (ASTM D 3080) - ( للعينات الترابية )',
                                5:'اختبار الضغط غير المحصور للعينات الصخرية (ASTM D 7012)',
                                6:'اختبار تحديد اقصى كثافة جافة للتربة( Proctor ) - ( ASTM D -1557)',
                                7:'اختبار نسبة تحميل كاليفورنيا California Bearing Ratio – CBR',
                                8:'اختبارات التحليل الكيميائي لعينات التربة',
                                9:'جدول تصنيف التربة بنظام الآشتو AASHTO',
                                10:'مخططات تصنيف التربة وفق النظام العالمي الموحد USCS',
                            },
                        },
                        7:{
                            header:'توصيف طبيعة تربة الموقع وطبقات التربة .',
                        },
                        8:{
                            header:'الحسابات الفنية لجهد تحمل التربة والهبوط المحتمل :',
                            text:'من خلال تطبيق معادلات حساب تحمل جهد التربة والجداول والمعاملات ( ترزاكي – مايرهوف- هانسن... إلخ) كذلك حساب الهبوط الآني والمحتمل ، وطرق حساب جهد تحمل الطبقات الصخرية .',
                        },
                        9:{
                            header:'التوصيات الفنية وتشمل :',
                            text1:'وصف تربة التأسيس ونوع الاساسات وعمق الأساسات وجهد تحمل التربة الموصى به للتأسيس والهبوط المحتمل والتفاضلي وتوصيات الردم الهندسي وغيره من التوصيات الفنية التي يراها معد التقرير مناسبة وضرورية .',
                            text2:'ويرفق بالتقرير مجموعة ملاحق تشمل:',
                            list:{
                                1:'الخرائط وتشمل الخرائط الجيولوجية والجغرافية والتضاريس وموقع المشروع وحدوده.',
                                2:'ملحق مقاطع الجسات والتحريات والاختبارات الحقلية.',
                                3:'ملحق الاختبارات المعملية.',
                                4:'الحسابات الهندسية لجهد تحمل التربة الهبوطات المسموحة والتفاضلية.',
                                5:'المراجع العلمية المعتمدة في الدراسة .',
                            },
                        },
                    },
                    section4:{
                        text1:'يتملك المكيال السعودي عدد من الحفارات الجيوتقنية لانجاز أعمال دراسات التربة',
                        text2:'يقوم مهندسي الجيوتكنيك باعداد التقرير الفني الجيوتكنيكي متضمناً:',
                        list:{
                            1:'تحديد الخواص الفيزيائية والكيمايئة للتربة و الصخور',
                            2:'حساب قدرة تحمل التربة للتربة والصخور',
                            3:'تحديد تأثير ارتفاع مستوى المياه السطحية على درجة تحمل قواعد وأساسات المباني والمنشآت',
                            4:'تحديد الهبوطات تحت الأساسات وتأثير ذلك على سلامة المنشآت',
                            5:'رصد تغير مستويات المياه السطحية وتحديد تأثير ذلك على خواص التربة الهندسية',
                            6:'عمل اختبار المقاومه الكهربائيه مستعملا ترتيب وينر',
                            7:'تصميم الأساسات السطحية والعميقة (الأوتاد)',
                            8:'تنفيذ أعمال الدك والتثبيت الديناميكي للتربة.',
                            9:'دراسة الصرف وسحب وضبط وقياس مستوى المياه تحت السطحية.',
                        },
                    },
                },

                // service2
                // 1
                soilFirst:{
                    1:'تحديد نسبة الرطوبة بالتربة',
                    2:'التحليل المنخلي لمواد التربة',
                    3:'حدود أتربرغ لتحديد السيولة ودليل اللدونة',
                    4:'تصنيف التربة',
                    5:'تحديد الكثافة الجافة القصوى والرطوبة المثلى للتربة ( بركتور)',
                    6:'تحديد نسبة تحميل كاليفورنيا للتربة CBR',
                    7:'تحديد كثافة التربة الحقلية بالموقع باستخدام أجهزة القياس النووية',
                    8:'تحديد الوزن النوعي للتربة',
                    9:'تحديد المكافئ الرملي للتربة',
                    10:'تحديد الكثافة النسبية للرمل',
                    11:'اختبار تحميل القرص',
                },
                aggregateSecond:{
                    1:'اختبار مقاومة التأكل والبري لعينات الركام الحصوية',
                    2:'اختبار الأصالة لمقاومة فعل المواد الكيميائية لعينات الركام الحصوية',
                    3:'اختبارات الوزن النوعي للركام الناعم والخشن',
                    4:'اختبارات الإستطالة والتفلطح للعينات الحصوية',
                    5:'اختبار تحديد مقاومة الضغط لعينات الكور الصخرية',
                },
                // 2
                asphalt:{
                    text1:'اختبارات تصميم الخلطات وتدقيق الخلطات الإسفلتية .',
                    text2:'وتشمل كافة الاختبارات اللازمة لتصميم الخلطة الإسفلتية وتدقيقها سواء كانت حسب نظام مارشال أو حسب نظام السوبر بيف وأهم هذه الاختبارات .',
                    text3:{
                        text:'من أجل تصميم الخلطات الإسفلتية يمكن إتباع الخطوات التحضيرية التالية:',
                        1:'اختيار المواد الحصوية',
                        2:'اختيار التركيب الحبي',
                        3:'تحديد نسب الحصويات',
                        4:'تحديد الوزن النوعي للحصويات',
                        5:'الوزن النوعي للبيتومين',
                        6:'تحضير عينات الاختبار',
                        7:'تحديد الكثافة ونسبة الفراغ للعينات',
                        8:'تحديد نسبة البيتومين الأصولية',
                    },
                    text4:{
                        text:'ومن أهم التجارب التي يجريها مختبر المكيال السعودي على الخلطات الإسفلتية نذكر :',
                        1:'اختبار تحديد نسبة البيتومين في الخلطة الاسفلتية ( الاستخلاص بالحرق Instraction وهذا الاختبار يستخدم لتدقيق وتحديد نسبة البيتومين في الخلطات الاسفلتية المستخدمة من قبل المقاولين في مشاريع السفلتة والتأكد من أن نسبة البيتومين مطابقة للمواصفات التصميمية ، حيث هناك نوعين من أساليب الاستخلاص أما بالحرق وهو الطريقة الأحدث أو الاستخلاص بالمذيب)',
                        2:'اختبار تحديد الكثافة القصوى للخلطة الاسفلتية GMM',
                        3:'تحديد الكثافة الظاهرية للعينة المدموكة Gmb',
                        4:'اختبار تحديد الثبات و فاقد الثبات لعينات مارشال المدموكة الاسطوانية بجهاز دك مارشال وقياس الانسياب بجهاز مارشال.',
                    },
                    text5:{
                        text:'كما تقدم خدمة الإشراف على التنفيذ ومراقبة ضبط الجودة QC لعملية انتاج الخلاطات كذلك عمل الاختبارات الحقلية والمعملية لضبط جودة تنفيذ أعمال السفلتة .',
                        1:'وهناك اختبارات حقلية لتحديد نسبة الدك الحلقية للخلطة المسفلتة بالجهاز النووي',
                        2:'تحديد سماكة الطبقات المسفلتة باستخدام الكور ماشين',
                    },
                },
                // 3
                bitumenText:'يقوم مختبر المكيال السعودي بإجراء كافة التجارب المخبرية والمعملية على عينات البيتومين السائل ومن هذه الاختبارات والفحوصات:',
                penetration:'تجربة الغرز ',
                viscosity:'تجربة اللزوجة ',
                ductility:'تجربة قابلية السحب ',
                softeningPoint:'تجربة نقطة التميع أو الطراوة ',
                flashAndFire:'تجربة نقطة الوميض و الإشتعال ',
                specificGravity:'تجربة الوزن النوعي ',
                solubility:'تجربة الإذابة أو الإنحلال ',
                volatility:'تجربة التطاير أو الفاقد بالتسخين ',
                // 4
                concrete:{
                    text1:'تعد الخرسانة الأسمنتية الـ Concrete تعد الخرسانة الأسمنتية الـ Concrete من أهم المواد الإنشائية التي تستخدم في كافة المنشآت المدنية على ظهر المعمورة سواء كانت مباني او جسور أو رصفيات وغيرها من المنشآت .',
                    text2:'وللخرسانة أنواع متعددة تبعاً لاستخدامها ومقاومتها وسرعة تصلبها وعيار الأسمنت فيها ...إلخ',
                    text3:{
                        text:'تقوم مختبرات المكيال السعودي بعمل تصاميم الخلطات الخرسانية و كافة الإختبارات على الخرسانة يقوم تصميم الخلطات الخرسانية بكافة طرق التصميم العالمية على :',
                        one:{
                            name:'اختبارات المواد الحصوية',
                            1:'التحليل الحبي للمواد الحصوية',
                            2:'عامل النعومة',
                            3:'تجربة المكافئ الرملي',
                            4:'الوزن الحجمي للرمل (تجربة المخروط)',
                            5:'مقاومة الركام للتآكل (تجربة لوس أنجلوس)',
                        },
                        two:'تحديد نسبة الماء إلى الإسمنت التي تعطي أفضل مقاومة ممكنة .',
                    },
                    text4:{
                        text:'اختبارات تدقيق الخلطات الخرسانية وأهم هذه الاختبارات : ',
                        firstName:'قابلية التشغيل للخرسانة',
                        firstText:'توجد طرق متعددة لقياس قابلية التشغيل منها: مخروط أبرامز أو تجربة الهبوط، اختبار طاولة الانسياب واختبار معامل الدك وغيرها، لكن الطريقة الأكثر انتشاراً واستخداماً هي طريقة مخروط أبرامز وهي تستخدم في المختبر وفي الموقع (الورشة).',
                        secondName:'المقاومة الميكانيكية للخرسانة',
                        secondText:'من خلال إجراء تجربة مقاومة الخرسانة على الضغط -كسر عينات اسطوانية او مكعبية قياسية بعد 7 أيام وبعد 28 يوم لتحديد مدى مطابقة الخرسانة المصبوبة للموصفات.',
                    }
                },
                // 5
                block:{
                    1:'اختبار قوة تحمل البلوك الأسمنتي',
                    2:'اختبار مقاومة البري للبلاط',
                    3:'اختبار الانحناء للبلاط',
                },

                // service3
                chemical:{
                    text:'مختبرات المكيال السعودي تقوم بإجراء اختبارات التحليل الكيميائي للتربة والمياه:',
                    1:'تحديد الأس الهيدروجيني PH وفق المواصفة BS1377',
                    2:'تحديد نسبة كبريتات وفق المواصفة BS1377',
                    3:'تحديد نسبة الكلوريدات وفق المواصفة BS1377',
                    4:'ملوحة التربة – الاملاح الكلية الذائبة وفق المواصفة BS1377',
                },

                // service4
                buildingsService:{
                    1:{
                        header:'أولاً- أخذ عينات القلب الخرسانية (ASTM C-42) :',
                        text:'و تتم وفق مواصفات خاصة في الكود السعودي  (SBC) و المواصفات الأمريكية (ASTM) و المواصفات البريطانية (BS) إضافة إلى اشتراطات معهد الخرسانة الأمريكي (ACI) , و يتم استخدام ماكينة أخذ العينات الخرسانية الخاصة و المجهزة برؤوس  حفر مختلفة الأقطار و تتراوح ما بين 3بوصة إلى   6 بوصة بحسب الحاجة. ',
                        text2:'بعد أن تتم عملية أخذ العينات الخرسانية من العناصر الإنشائية يقوم الفنيون بنقل العينات إلى المختبر بشكل آمن بصناديق عينات خاصة',
                    },
                    2:{
                        header:'ثانياً-  اختبار المطرقة الإرتدادية اختبار مطرقة شميدت (ASTM C805-02 )',
                        text:'يعتبر اختبار مطرقة شميدت (Schmidt Hammer Test) على الخرسانة من الاختبارات المعروفة و تصنف من الإختبارات غير المخربة (Non–Destructive Test). يستخدم هذا الإختبار من أجل الحصول على قيمة تقريبية لمقاومة الخرسانة على الضغط أو إعطاء مؤشر عن مدى جودة الخرسانة.',
                        text2:'تتم هذه التجربة وفق المواصفة الأمريكية (ASTM-C805) ويمكن معرفة قساوة أو صلابة  الخرسانة اعتماداً على مبدأ قيمة رد الفعل على سطح المقطع الخرساني المتصلد بوساطة مطرقة نابضة',
                    },
                    3:{
                        header:'ثالثاً-  الكشف عن حديد التسليح:',
                        text:'تهدف عملية الكشف على حديد التسليح لبعض العناصر الإنشائية الممثلة في المبنى إلى ما يلي:',
                        list:{
                            1:'تحديد اماكن تواجد العناصر الإنشائية كالأعمدة و الكمرات في حال غياب و عدم توفر المخططات المعمارية و الإنشائية للمبنى.',
                            2:'تحديد أماكن توضع حديد التسليح الطولي و العرضي في العناصر الإنشائية.',
                            3:'تحديد سماكة الغطاء الخرساني فوق حديد التسليح.',
                            4:'تحديد اقطار قضبان التسليح.',
                        }
                    },
                    4:{
                        header:'رابعاً- اختبار نصف الخلية ASTM C876-09 :',
                        text:'إن اختبار نصف الخلية (Half -Cell potential test) يتم حسب المواصفة الأمريكية (ASTM C876-09) و هو اختبار ينفذ لمعرفة فيما إذا كان هناك صدأ بحديد التسليح للعناصر الإنشائية في المباني أم لا. ',
                        text2:'يعتمد هذا الاختبار على تشكيل دارة كروكيميائية على مبدأ البطارية تكون فيها شبكة الحديد التسليح في المنطقة المدروسة جزء من الدارة ويستخدم محلول كبريتات النحاس كوسيط و تقاس فها المقاومة بالميلي فولط. يتم استخدام جهار البروفوميتير في اختبار نصف الخلية و هو من احدث الأجهزة المتطورة و المستخدمة في هذا المجال موضع بالصور الفوتوغرافية.',
                    },
                    5:{
                        header:'خامساً- اختبار الامواج فوق الصوتية (ASTM C 597) :',
                        text:'تعتبر طريقة الأمواج فوق الصوتية من الاختبارات غير المخربة و التي من خلالها يتم تحديد جودة الخرسانة و تقدير تقريبي للمقاومة. كما يمكن استخدام هذه الطريقة من أجل حساب معاملات المرونة و التشوه العرضي من خلال قياس الأمواج الطولية و العرضية.',
                        text2:'تعتمد هذه الطريقة على قياس الزمن اللازم لمرور الأمواج فوق الصوتية خلال المقطع الخرساني المدروس و بالتالي قياس سرعة هذه الأمواج ما بين المرسل و المستقبل و تكون بالطريق المباشر أو غير المباشر أو شبه المباشر (على ضلعي زاوية).',
                    },
                    6:{
                        header:'سادساً- تجربة تحميل السقف :',
                        text:'تعتبر تجربة التحميل من أهم التجارب التي تستخدم لتقييم حالة البلاطات أو الاسقف ومعرفة كفاءة السقف الخرساني في تحمل الأحمال التصميمية أو الإضافية و تستخدم في كثير من الحالات التي لا تتوفر فيها مخططات للمباني المدروسة.',
                        text2:'و يتم فيها اختيار عناصر التحميل (بلاطات و كمرات و أعمدة) بحسب متطلبات الدراسة و ما تقتضيه و فيما يلي صور لتجربة التحميل في احد المشاريع التي نفذتها الشركة.',
                    },
                },

                //service5
                studies:{
                    text1:'الدراسات الفنية الجيوتقنية الخاصة:',
                    text2:'يقوم قسم الدارسات الفنية في مختبرات المكيال السعودي بإجراء الدراسات الفنية المتعلقة بمعالجة مشاكل ترب التأسيس الصعبة  وتحسين التربة وتقويتها ودراسات ثبات المنحدرات ودعم جوانب الحفريات وكشف التكهفات ودراسات نضح المياه وكشف الخدمات الأرضية: ',
                    text3:'بالإضافة لذلك تقوم مختبرات المكيال السعودي بتنفيذ الاختبارات والأعمال التالية  :',
                    1:'دراسات  تحسين تربة التأسيس كالإحلال للتربة و دراسات معالجة مشاكل التأسيس على التربة الصعبة مثل التربة الانتفاخية كالطين الانتفاخي والصخور الانتفاخية والتربة الإنهيارية غير المستقرة .',
                    2:'دراسات كشف التكهفات',
                    3:'دراسات حقن التربة ',
                    4:'تدعيم الحفريات والإشراف عليها: بمختلف أنواع التدعيم – الخرساني – الخوازيق – الصفائح المعدنية ... إلخ ',
                    5:'دراسة ثبات واستقرار المنحدرات ',
                    6:'تصميم ودراسة  الجدران الاستنادية  ',
                    7:'دراسات الأوتاد أو الخوازيق والأساسات الوتدية ( المزودة بخوازيق ) بأنواعها حسب طبيعة التربة والمشروع كأوتاد الاحتكاك والارتكاز وأوتاد التدعيم',
                    8:'دراسات تخفيض المنسوب المائي ',
                    9:'أعمال المسح الجيوفيزيائي باستخدام تقنية GPRالتي تستخدم الموجات الراديوية ( كهرومغناطيسية ) لالتقاط الصور تحت سطح الأرض مما يتيح  كشف الخدمات الأرضية ',
                    10:'قياس المقاومة الكهربائية للتربة ',
                },

                ///////Services Page Company
                servicesText1Company:'تقدم شركة المكيال السعودي للاستشارات الهندسية مجموعة من الخدمات الهندسية، وتشمل:',
                servicesText2Company:'مجموعة من الخدمات تقدمها شركة المكيال السعودي للإستشارات الهندسية',
                featuredProjects:'من أعمالنا',
                include: 'تشمل خدماتنا:',

                // service1
                architectural:{
                    text:'يتكون قسم الهندسة المعمارية في شركة المكيال السعودي للاستشارات الهندسية من عدة وحدات تعمل معًا لتقديم تصميمات معمارية متكاملة تلبي احتياجات العميل وتلتزم بالمعايير الفنية والتقنية. تختلف هذه التخصصات وعدد الأفراد بناءً على حجم ونوع المشروعات، وتشمل الوحدات الأساسية ما يلي:',
                    design:{
                        name:'وحدة التصميم المعماري :',
                        1:{
                            name:'التصميم المعماري :',
                            text:'هذه الوحدة مسؤولة عن إعداد التصاميم الابتدائية، وتطوير الأفكار المعمارية، وإنشاء الرسومات الأولية. يعمل المهندسون المعماريون في هذه الوحدة على تلبية متطلبات العميل من حيث الجمالية والوظيفية وإنتاج المناظير المعمارية عالية الجودة والدقة باستخدام أحدث البرامج المعمارية والهندسية المتاحة في هذا المجال.',
                        },
                        2:{
                            name:'التصاميم التنفيذية :',
                            text:'مسؤولة عن تحويل التصميمات الأولية إلى رسومات تنفيذية تتضمن التفاصيل الدقيقة للبناء، مثل مخططات الأساسات، الهيكل الإنشائي، والتفاصيل الكهربائية والميكانيكية ومخططات كفاءة الطاقة.',
                        },
                        3:{
                            name:'تنسيق أنظمة البناء والمتابعة :',
                            text:'تعمل على دمج الأنظمة المختلفة في المبنى مثل التكييف، الكهرباء، والسباكة. يتطلب هذا التنسيق المتواصل مع المهندسين المتخصصين في تلك الأنظمة، كما يتم أيضا التعقيب والمتابعة لدى الأمانة والبلديات بخصوص المشروعات وتقديم الرسومات اللازمة التابعة لانظمة البناء وذلك لاستخراج التراخيص الأولية والنهائية لفسح البناء. ',
                        },
                        4:{
                            name:'التصميم الداخلي :',
                            text:'في شركة المكيال السعودي نركز على التفاصيل الداخلية للمباني مثل اختيار المواد، الألوان، الأثاث، والإضاءة. يعمل المصممون الداخليون جنبًا إلى جنب مع المعماريين لضمان التكامل بين التصميم الداخلي والخارجي، مع اعداد المخططات التنفيذية المتكاملة.',
                        },
                        5:{
                            name:'إعداد جداول حصر الكميات والمواصفات لجميع التخصصات :',
                            text:'وحدة إعداد جداول حصر الكميات، التي تلعب دورًا أساسيًا في ضمان إعداد تقديرات دقيقة لتكاليف المشاريع والتحكم في الميزانية. وتعتمد هذه العملية على استخراج وتحليل تفاصيل الكميات المطلوبة لكل بند من بنود المشروع، سواء في الخرسانة، الطوب، التشطيبات، أنظمة الكهرباء، التكييف، وغيرها. يتطلب هذا دقة عالية في مراجعة الرسومات والمواصفات لضمان شمولية ودقة الحصر.',
                        },
                    },
                    urban:{
                        name:'خدمات التخطيط العمراني ',
                        text:'وحدة التخطيط العمراني تتكون عادة من وحدات متعددة تتكامل لتحقيق التخطيط الشامل للمشاريع، مع الأخذ بالاعتبار الجوانب البيئية، الاجتماعية، والاقتصادية. فيما يلي أهم مكونات وحدة التخطيط العمراني :',
                        1:{
                            name:'تخطيط المدن والقرى : ',
                            text:'تقوم الشركة في مجال تخطيط المدن تهدف إلى تنظيم استخدامات الأراضي وتوزيع الموارد والخدمات، بما يحقق بيئة حضرية أو ريفية مستدامة ومتوازنة تلبي احتياجات السكان. يشمل هذا التخطيط تطوير بنية تحتية مناسبة، وتحسين جودة الحياة، ودعم الاقتصاد المحلي، والمحافظة على البيئة. ',
                        },
                        2:{
                            name:'توزيع استخدامات الأراضي : ',
                            text:'يتضمن تقسيم الأراضي لأغراض سكنية، تجارية، صناعية، وزراعية، وتحديد المناطق المفتوحة والمساحات الخضراء. يهدف هذا التوزيع إلى تحقيق التوازن بين الأنشطة المختلفة وتقليل التزاحم، مما يسهم في توفير بيئة ملائمة للعيش والعمل.',
                        },
                        3:{
                            name:'التخطيط التفصيلي : ',
                            text:'يهتم التخطيط التفصيلي بتحديد استعمالات الأراضي التفصيلية بإحياء المدنية بشكل دقيق ويقترح ضوابط تقسيمات قطع الأراضي والكثافة السكانية والبنائية وضوابط وأنظمة البناء على قطع الأراضي وعروض الطرق والارصفة وممرات المشاة وتخطيط أماكن انتظار السيارات وأنواع ومواقع ومساحات أراضي الخدمات العامة ومسارات خطوط شبكات المرافق العامة.',
                        },
                        4:{
                            name:'تخطيط أراضي الملكيات الخاصة : ',
                            text:'يتم تخطيط الأراضي الخاصة للاستعمالات السكنية اوالتجارية او الصناعية او المستودعات او الخدمات العامة او الاستراحات الزراعية، حيث يتم اعداد الرفوعات المساحية للأراضي الخاصة وإعداد الدراسات التخطيطية للموقع ودراسة احتياجات المالك واعداد المخطط النهائي للموقع ومتابعة إجراءات اعتماد المخطط من الأمانة او وكالة الأمانة او الوزارة ثم تطبيقه على الطبيعة.',
                        },
                        5:{
                            name:'التصميم الحضري : ',
                            text:'تقدم الشركة خدمة التصميم الحضري بتصميم المساحات العامة مثل الحدائق، الساحات، الشوارع، والتجمعات، مع التركيز على تعزيز الجمالية والراحة للمجتمع. يتعاون المصممون الحضريون مع المهندسين المعماريين لضمان تكامل التصميم العام مع المباني المحيطة.',
                        },
                    },
                },

                // service2
                transport: {
                    text: 'نقوم بدراسة جميع الاثار المرورية السالبة المتوقعة للمشاريع الجديدة المراد إنشاؤها أو الناتجة عن تطوير المشاريع القائمة وتحديد المعالجات المطلوبة لتخفيف هذه الاثار. ',
                    1: {
                        text: 'التنسيق مع الجهات المعنية (البلدية، الأمانة، إدارة المرور) للحصول على متطلبات إعداد الدراسة المرورية.',
                    },
                    2: {
                        text: 'تقييم تأثير المشروع على شبكة الطرق المحيطة به والسلامة المرورية.',
                    },
                    3: {
                        text: 'تحديد التحسينات المطلوبة لتخفيف التأثيرات وتقدير تكاليفها.',
                    },
                    4: {
                        text: 'تقييم ملائمة التصميم المقترح وتحديد التحسينات اللازمة لمداخل ومخارج المشروع.',
                    },
                    5: {
                        text: 'إعداد تقرير مروري متكامل وفقًا لمتطلبات الجهات المعتمدة.',
                    },
                    6: {
                        text: 'اعتماد الدراسة المرورية من الجهات المختصة (الأمانة، البلدية، إدارة المرور، وزارة النقل، الهيئة الملكية).',
                    },
                    },

                // service3
                infrastructure: {
                    text: 'نقدم دراسات وتصاميم هندسية متكاملة لتصميم وتنفيذ مشاريع البنية التحتية وفقًا لأعلى المعايير الفنية.',
                    1: {
                        text: 'إجراء الدراسات وإعداد المواصفات الفنية لشبكات الطرق.',
                    },
                    2: {
                        text: 'تصميم وتنفيذ شبكات المياه، الصرف الصحي، وقنوات التصريف.',
                    },
                    3: {
                        text: 'تصميم شبكات السيول والكهرباء، بالإضافة إلى شبكات الإنارة.',
                    },
                    4: {
                        text: 'الإشراف على التنفيذ لضمان الجودة والتوافق مع المعايير الهندسية.',
                    },
                    5: {
                        text: 'حساب الكميات والبنود المرتبطة بكل مشروع لضمان دقة التنفيذ وكفاءة التكلفة.',
                    },
                    },

                // service4
                electrical: {
                    text: 'في المكيال السعودي للاستشارات الهندسية، نقدم خدمات كهربائية شاملة تشمل:',
                    contracts:'العقود',
                    team:'الطاقم الفني',
                    1: {
                        text: 'تصميم شبكات الجهد المنخفض والمتوسط مع ضمان اعتماد المخططات من الشركة السعودية للكهرباء.',
                    },
                    2: {
                        text: 'تصميم وتنفيذ أنظمة إنارة الطرق وفقًا للمخططات الخاصة واعتمادها من الأمانة والبلديات المعنية.',
                    },
                    3: {
                        text: 'تصميم الأعمال الكهربائية للمشاريع السكنية، التجارية، والحكومية وفقًا لمواصفات الشركة السعودية للكهرباء وكود البناء السعودي.',
                    },
                    4: {
                        text: 'الإشراف على إنشاء وصيانة شبكات التوزيع لضمان كفاءتها وسلامتها.',
                    },
                    },

                // service5
                evaluation: {
                    text: 'نقدم في المكيال السعودي للاستشارات الهندسية خدمات تقييم السلامة الإنشائية للمباني والمنشآت لضمان سلامتها واستدامتها لفترات أطول. تساهم هذه الخدمات في تأكيد قدرة المباني والمرافق على الاستمرار في أداء وظيفتها بشكل آمن وفعال.',
                    text2:'وتشمل أهمية هذه الخدمات الحالات التالية:',
                    1: {
                        text: 'إعادة تأهيل المباني أو تغيير الوظيفة الاستثمارية لها.',
                    },
                    2: {
                        text: 'اكتشاف العيوب في العناصر الإنشائية للمباني بشكل عام.',
                    },
                    3: {
                        text: 'إضافة أدوار إضافية للمبنى القائم بما يتوافق مع تغير ضوابط وأنظمة البلديات.',
                    },
                    4: {
                        text: 'إعادة ترميم وصيانة المباني الأثرية ذات القيمة التاريخية.',
                    },
                    5: {
                        text: 'إطالة عمر المنشآت مما يساهم في توفير المال والجهد والزمن.',
                    },
                    },

                // service6
                gis: {
                    text: 'نقدم خدمات مساحية متكاملة باستخدام أحدث التقنيات والأجهزة لضمان تنفيذ المشاريع وفقًا لأعلى المعايير.',
                    text2:'نظم الـ GPS المستخدمة:',
                    text3:'جانب من أعمال الفرق المساحية للمكيال السعودي في بعض المشاريع:',
                    1: {
                        text:'الرفع المساحي الطبوغرافي للأراضي وإنشاء خطوط كنتورية حسب طبيعة المشروع'
                    },
                    2: {
                        text:'إنشاء نقاط تحكم أرضية دائمة باستخدام نظام "GPS" وتوثيقها في كروت وصفية'
                    },
                    3: {
                        text:'ربط الخرائط والأعمال المساحية باستخدام الأنظمة العالمية أو النظام المحلي'
                    },
                    4: {
                        text:'تصميم الطرق وإعداد المقاطع الطولية والعرضية وحساب كميات الحفر والردم'
                    },
                    5: {
                        text:'استخدام الرفع المساحي الجوي والكشف عن الخدمات بواسطة الطائرة (درون) وجهاز المسح الأرضي (GPR)'
                    },
                    6: {
                        text:'تسجيل وتحويل بيانات الرفع المساحي إلى رسومات دقيقة عبر نظم المعلومات الجغرافية (GIS)'
                    },
                    devices:{
                        1:'نظام جارمن',
                        2:'نظام لايكا',
                        3:'نظام ترمبل',
                    },
                },

                // service7
                supervision: {
                    text1:'اولاً: الإشراف الهندسي علي المشاريع:',
                    text2: 'يقدم قسم الإشراف على التنفيذ خدمات إدارة عقد التنفيذ لضمان تنفيذ المشاريع وفقًا للمواصفات والمعايير المطلوبة.',
                    text3: 'تتنوع المشاريع التي يشرف عليها المكيال السعودي  من مشاريع طرق ومرافق وبنية تحتية ومشاريع مخططات ومباني سكنية ومشاريع صيانة وتأهيل.',
                    list: {
                        1:'الإشراف على تنفيذ المباني السكنية والتجارية ومحطات الوقود',
                        2:'الإشراف على تنفيذ مخططات البنية التحتية (طرق، مياه، صرف صحي، كهرباء، إنارة)',
                        3:'مراجعة ومطابقة الأعمال المنفذة مع المخططات التصميمية',
                        4:'إعداد الجداول الزمنية للتنفيذ',
                        5:'عقد الاجتماعات مع الجهات المعنية ومراجعة المستندات',
                        6:'إعداد المستخلصات المالية الجارية والختامية',
                        7:'إعداد تقارير متابعة تقدم العمل الأسبوعية والشهرية',
                        8:'المشاركة في لجان تسليم الموقع والاستلام الابتدائي والنهائي',
                        9:'اعتماد الطواقم والمواد المستخدمة في التنفيذ',
                    },
                    text4:'ثانياً: خدمات إدارة المشاريع:',
                    text5:'نقدم خدمات إدارة المشاريع باستخدام أحدث النظم التخطيطية لضمان نجاح كل مرحلة من مراحل التنفيذ. نركز على التحكم الفعّال في التكاليف باستخدام برامج متطورة.',
                    list2:{
                        1:'دراسة المخططات الفنية ومراجعتها بدقة قبل بدء التنفيذ',
                        2:'إعداد مقايسة تقديرية شاملة لتكلفة المواد بناءً على حصر الكميات',
                        3:'إعداد العقود وتحديد الشروط الفنية والمواصفات القياسية',
                        4:'إعداد جداول زمنية مفصلة ومتابعتها لضمان سير العمل وفقاً للخطة الموضوعة',
                        5:'الإشراف على تنفيذ المشاريع لضمان تحقيق النتائج المطلوبة بكفاءة وفعالية',
                    },
                    text6:'إدارة المشاريع:',
                    text7:'إشراف على المشاريع:',
                },

                // service8
                hydrological:{
                    text1:'- نقدم خدمات شاملة في إعداد الدراسات الهيدرولوجية ودرء مخاطر السيول والتصريف الحضري للأمطار، حيث يقوم فريقنا بمراجعة دقيقة للطبوغرافيا والخصائص المورفولوجية للمناطق المراد عمل الدراسة لها، مع تحديد ودراسة الأودية والشعاب المحيطة أو المارة بمنطقة الدراسة.',
                    text2:'- هذا بالإضافة إلى تقدير كميات مياه الامطار والسيول المعرضة لها منطقة الدراسة وتصميم القنوات المفتوحة او المغلقة المطلوبة لتصريف المياه من منطقة الدراسة وذلك باستخدام التحليل الإحصائي للأمطار بواسطة نماذج حسابية متقدمة مثل Smada وHyfra، والنمذجة المورفولوجية لصور الأقمار الصناعية ونماذج الارتفاعات الرقمية باستخدام برامج مثل Arc GIS وWMS لاستخراج مسارات الأودية بدقة عالية.',
                    text3:'- فضلاً عن عمل نمذجة هيدرولوجية لاستخراج التدفقات والقراءات المتوقعة على أزمنة تكرارية مختلفة باستخدام برنامج Hec HMS، وننفذ نمذجة هيدروليكية أحادية بواسطة برامج Sewer-Gems وHec-Ras وCulvert Master، بالإضافة إلى النماذج ثنائية الأبعاد باستخدام برامج Rasmapper وOpen Flow.',
                    text4:'- وضع الحلول المناسبة لتصريف المياه من منطقة الدراسة، وكذا وضع التوجيهات الكفيلة بالحفاظ على منطقة الدراسة من مخاطر السيول ومراعاتها أثناء التصميم.',
                    water:{
                        header:'دراسات المياه وتصريف السيول:',
                        text1:'- يعمل الفنيون لدينا وفق أحدث البرامج الهندسية والتي تساعد على اخراج العمل بشكل دقيق وقابل للتنفيذ وذلك في كل ما تتطلبه هندسة المياه وتصريف السيول يشمل كلاً من:',
                        text2:'- يعتبر المكيال السعودي من المكاتب الهندسية المعتمدة في مجال الدراسات الهيدرولوجية لدى وزارة البلديات والإسكان وأمانة منطقة الرياض والعديد من الجهات الحكومية',
                        text3:'- تتم الدراسات الهيدروجيولوجية طبقاً لمتطلبات وزارة البلديات والإسكان وهيئة المساحة الجيولوجية السعودية وتطبيق أنظمة الدراسات المعتمدة من قبل هيئة المساحة الجيولوجية الأمريكية والجمعية الجيولوجية البريطانية والجمعية العالمية لجيولوجيا المياه',
                        point1:'اعداد المخططات التصميمية والتنفيذية لشبكات المياه وقنوات التصريف',
                        point2:'فحص المواد والتأكد من صلاحيتها للتنفيذ',
                    },
                    studies:{
                        text:'قام المكيال السعودي بالعديد من الدراسات الهيدرولوجية منها:',
                        list:{
                            1:'الدراسة الهيدرولوجية لمخططات المعتمدة في محافظة عفيف',
                            2:'الدراسة الهيدرولوجية لمخططات المعتمدة في محافظة المجمعة',
                            3:'الدراسة الهيدرولوجية لمخططات المعتمدة في محافظة تمير ',
                            4:'الدراسة الهيدرولوجية لمخطط العليان بالعارض شمال الرياض',
                            5:'الدراسة الهيدرولوجية لمخطط قرية ام سليم السكني – محافظة مرات',
                            6:'الدراسة الهيدرولوجية لمخطط المغزرات بالرياض – شركة أوج للتطوير والاستثمار العقاري',
                        }
                    },
                },

                ///////Clients page
                ourClients:'عملاؤنا',
                clientsHeader:'ثقة العملاء هي أساس نجاحنا',
                clientTextCompany1:'نفخر بخدمة مجموعة متنوعة من العملاء في القطاعين الحكومي والخاص. تميزنا في تقديم حلول هندسية مبتكرة وفعّالة تلبّي احتياجات المشاريع الكبيرة والصغيرة على حد سواء.',
                clientTextCompany2:'سواء كان العميل جهة حكومية تسعى لتحقيق أهدافها التنموية أو شركة خاصة تبحث عن التفوق في مشاريعها، فإننا نلتزم بتوفير أعلى معايير الجودة والابتكار في كل مرحلة من مراحل التنفيذ.',
                clientTextCompany3:'إن خبرتنا المتنوعة في مختلف المجالات الهندسية تجعلنا الخيار الأول للعديد من المؤسسات الرائدة في المملكة.',
                ourPartners:'شركاؤنا',
                clientTextCompany4:'نعمل بتعاون وثيق مع عدد من الشركات الأجنبية المتخصصة في مجالات الاستشارات الهندسية. تشمل شراكاتنا مع هذه الشركات تقديم استشارات هندسية متقدمة، وتنفيذ مشاريع بنجاح في مختلف القطاعات مثل البنية التحتية، والتصاميم، والبيئة',
                clientTextCompany5:'ونحن نسعى دائماً لتوسيع شبكة شركائنا بما يضمن الاستفادة من أحدث التقنيات والمعرفة العالمية.',
                clientsCompanyGov: {
                    client1:'أمانة المنطقة الشرقية',
                    client2:'وكالة الأمانة لشؤون بلديات منطقة الرياض',
                    client3:'أمانة المدينة المنورة',
                    client4:'الجمارك مصلحة',
                    client5:'المؤسسة العامة للخطوط الحديدية – إدارة الصيانة ',
                    client6:'الهيئة الملكية للجبيل وينبع',
                    client7:'إدارة الدراسات والتصاميم بأمانة الرياض',
                    client8:'الشئون الهندسية في رئاسة أمن الدولة',
                    client9:'وزارة الإسكان',
                    client10:'وزارة البيئة والمياه والزراعة',
                    client11:'وزارة التجارة والصناعة',
                    client12:'وزارة التربية والتعليم',
                    client13:'وزارة الثقافة',
                    client14:'وزارة الحرس الوطني',
                    client15:'وزارة الدفاع',
                    client16:'وزارة النقل',
                },
                clientsCompanyPri: {
                    client1:'البحر الأحمر العالمية',
                    client2:'وافي البيع أو التأجير على الخارطة',
                    client3:'الشركة السعودية للكهرباء',
                    client4:'الشركة السعودية للنقل الجماعي',
                    client5:'القدية',
                    client6:'الهيئة السعودية للمياه',
                    client7:'جامعة شقراء',
                    client8:'شركة الاتصالات السعودية',
                    client9:'شركة المياه الوطنية',
                    client10:'شركة بوابة الدرعية المحدودة',
                    client11:'شركة تطوير للمباني',
                    client12:'مجموعة روشن',
                    client13:'مدن',
                    client14:'مشروع الرياض الخضراء',
                    client15:'نيوم',
                    client16:'مصرف الإنماء',
                },
                clientsLabsGov: {
                    client1:'الهيئة السعودية للمياه',
                    client2:'مدن',
                    client3:'وزارة الشؤون البلدية والقروية والاسكان',
                    client4:'وزارة البيئة والمياه والزراعة ',
                    client5:'وزارة النقل والخدمات اللوجستية ',
                    client6:'وزارة الدفاع – الأشغال العسكرية ',
                    client7:'وزارة التربية والتعليم ',
                    client8:'أمانة منطقة الرياض',
                    client9:'امانة منطقة المدينة المنورة ',
                    client10:'امانة منطقة الشرقية ',
                    client11:'امانة العاصمة المقدسة',
                    client12:'قوات امن المنشآت',
                    client13:'رئاسة امن الدولة',
                    client14:'الشركة الوطنية للاسكان',
                    client15:'الشركة السعودية للكهرباء',
                    client16:'الشركة الوطنية للاسكان',
                    client17:'الخطوط الحديدية السعودية',
                    client18:'الهيئة الملكية للجبيل وينبع',
                },
                clientsLabsPri: {
                    client1:'مجموعة روشن',
                    client2:'شركة سيسترا',
                    client3:'ايجيك – بيت الخبرة والإستشارات الهندسية',
                    client4:'شركة المشاريع الأولى',
                    client5:'شركة وثبة للإستثمار',
                    client6:'شركة الإعمار والتنسيق المحدودة',
                    client7:'شركة كاب فرانس بات السعودية',
                    client8:'شركة فريسينه السعودية العربية',
                    client9:'مكتب عبدالإله المهنا للإستشارات الهندسية',
                    client10:'مكتب البعد التصميمي للإستشارات الهندسية',
                    client11:'مجموعة العمرو للاستشارات الهندسية',
                    client12:'مركز الشرق للإستشارات الهندسية',
                    client13:'شركة ميثاق للمقاولات',
                    client14:'مكتب سمير ابو غلية للإستشارات الهندسية',
                    client15:'مكتب فهد صالح الرشودي للاستشارات الهندسية',
                    client16:'مكتب عمرانيات للإستشارات الهندسية',
                    client17:'شركة اساس إيناس للتطوير العقاري',
                    client18:'شركة الدارة للتطوير العقاري',
                    client19:'مجموعة تعاهدات القابضة',
                    client20:'شركة زوايا العقارية',
                    client21:'شركة نجوم السلام القابضة',
                    client22:'شركة الشرفة للتطوير والاستثمار',
                    client23:'مكتب البواردي للإستشارات الهندسية',
                },
                partners: {
                    1:'شركة سويكو العالمية للاستشارات الهندسية',
                    2:'شركة توبتيك العالمية للاستشارات الهندسية',
                    3:'شركة إس بي إس العالمية للاستشارات الهندسية',
                    4:'شركة ام تي بي العالمية للاستشارات الهندسية',
                    5:'شركة باتليرويج العالمية للاستشارات الهندسية',
                },

                /////////Quality page
                qualityHeader:'تركز سياساتنا على الحفاظ على الجودة والسلامة لتعزيز التطوير المستدام',
                quality:{
                    quality:'الجودة والتطوير في المكيال السعودي :',
                    text1:'أخذت نظم ضبط الجودة بالتطور وكذلك الأساليب والمعدات المنفذة لها وذلك مع ازدياد الحاجة لنظام ضبط الجودة، وقد حرص المكيال السعودي على إتباع برنامج متطور لمواكبة هذا النظام والذي كان له الدور الكبير في التفاعل مع نظم ضبط الجودة والعمل على نجاحها ورفع مستوى أداءه. ',
                    text2:'ولدى المكيال السعودي Al-Mekyal Al-Saudi منظومة جودة تقوم على :',
                    iso1Name:'نظام إدارة الجودة (ISO 9001:2015) :',
                    iso1Sentence:' هو معيار دولي مخصص لأنظمة إدارة الجودة (QMS) بحيث يهدف لتحسين الجودة لأي مؤسسة/منظمة/إدارة تتطلع إلى تقديم خدمات تلبي باستمرار متطلبات وتوقعات العملاء بأكثر الطرق كفاءة.',
                    iso2Name:'نظام السلامة والصحة المهنية لأيزو (ISO45001-2018) :',
                    iso2Sentence:' والذي يعد مطلباً أساسياً في تطبيق إجراءات السلامة والصحة لجميع العاملين والموظفين في المنشأة وفي مواقع العمل.',
                    iso3Name:'نظام جودة إدارة البيئة (ISO 14001) :',
                    iso3Sentence:' هو معيار متفق عليه دوليًا، ويساعد المنظمات على تحسين أدائها البيئي من خلال الاستخدام الأكثر كفاءة للموارد والحد من النفايات، والحصول على ميزة تنافسية وثقة العملاء.',
                    iso4Name:'نظام الجودة لكفاءة مختبرات الفحص والمعايرة (ISO 17025 -2017)',
                    iso4Text:'ومتطلبات المركز السعودي للاعتماد ( ساك) :',
                    iso4Sentence:'مواصفات الإيزو 17025  تقوم على مجموعتين من المتطلبات، إدارية وفنية، تتعلق المتطلبات الإدارية بجودة «نظام الإدارة» اما الفنية فتتعلق بكفاءة طاقم التحليل وبطرق التحليل والأجهزة المستخدمة وطرق ضبط جودة التحاليل وإصدار التقارير وغيرها. تستخدم المختبرات هذه المواصفة كي تنفذ نظاماً للنوعية يهدف إلى تحسين قابليتها لإنتاج نتائج مُثبتة. وهذه المواصفة أيضاً هي الأساس للحصول على الاعتماد من جهة اعتماد معينة. وبما ان هذه المواصفة تتعلق بإثبات الكفاءة، فإن الاعتماد ببساطة عبارة عن اعتراف من قبل جهة محايدة بكفاءة وأهلية المختبر. وهنالك متطلب أساسي للحصول على الاعتماد وهو «توثيق النظام» أي كتابته في الوثيقة التي تسميها المواصفة «ملف الجودة». وتحتوي هذه الوثيقة سياسات وإجراءات المختبر لكيفية التقيد بمتطلبات هذه المواصفة.',
                    iso4Sentence2:'- مختبرات المكيال السعودي حاصلة على شهادة كفاءة مختبرات الفحص وفق المواصفة 17025  من المركز السعودي للاعتماد ( ساك)  في المملكة العربية السعودية كجهة محايدة ومعتمدة.',
                    qualitySystem:'يرتكز نظام الجودة في المكيال السعودي على تطبيق: ',
                    objectives:{
                        name:'أهداف نظام الجودة',
                        text:'هدفنا هو التطوير المستمر والتحسين المستمر الذي يساهم في زيادة الفعالية وتحسين الإنتاجية وذلك من أجل:',
                        text2:'تنفيذ نظام إدارة معترف به دولياً على أساس متطلبات المواصفات ISO/IEC 17025 - ISO 9001 وبما يتوافق مع متطلبات جهات الاعتماد ومنح الشهادات وتحقيق المتطلبات القانونية والتشريعية.',
                        1:'تطبيق ممارسة مهنية جيدة وتقديم أفضل جودة  من الخدمات ',
                        2:'تحقيق رضا العملاء في أقصر وقت ممكن ',
                        3:'رفع كفاءة وتطوير الموظفين ',
                        4:'تنفيذ اعلى معايير للجودة في تنفيذ الاختبارات والخدمات الهندسية  وصيانة المعدات والادوات بشكل صحيح.',
                        5:'الحفاظ على أعلى مستوى من الحيادية والنزاهة والحفاظ على سرية المعلومات الخاصة بالعملاء',
                    },
                    policy:{
                        name:'سياسة الجودة',
                        text:'تلتزم إدارة المكيال السعودي بانجاز وتحقيق أعلى معايير الجودة في الاختبارات والخدمات الهندسية المقدمة   وذلك من أجل تحسين وتطوير أدائها للمحافظة على سمعتها المتميزة وكذلك لدعم الاقتصاد  والمساهمة في تلبية احتياجات السوق السعودي   حيث تؤمن الشركة بأن الالتزام بنظام الجودة  يلبي احتياجات  الشركة وينعكس على تطلعات العملاء وهو شرط أساسي لاستمرار النجاح  ويتم ذلك من خلال :',
                        1:'التزام إدارة المكيال السعودي بالحيادية وإعطاء الثقة لجميع العملاء بأن الخدمات المقدمة قائمة على أساس الحيادية والكفاءة والسرية .',
                        2:'التزام إدارة المكيال السعودي بالتركيز دائماً على متطلبات العميل وتطوير الخدمات المقدمة  لنيل رضاء العملاء .',
                        3:'التزام إدارة المكيال السعودي بالتحسين المستمر في خدمة العملاء وسرعة الاستجابة والرد على طلباتهم وكذلك الحفاظ على سرية البيانات والمعلومات الخاصة بهم، مع تلبية المتطلبات القانونية والتنظيمية المعمول بها وفقا لمجالات العمل . ',
                        4:'التزام إدارة المكيال السعودي بتوفير بيئة عمل صحية ومناسبة تضمن أن يكون الموظفين غير خاضعين لأى نوع من الضغوط بما فيها الضغوط المالية أو أي ضغوط أخرى يمكن أن تؤثر سلباً على نتائج أو جودة اعمالهم. ',
                        5:'التزام إدارة المكيال السعودي بتطبيق متطلبات المواصفات الدولية ISO/IEC (9001-14001-17025-45001) مع التحسين المستمر لنظام إدارة الجودة بالشركة .',
                        6:'التزام إدارة المكيال السعودي بأن جميع العاملين على دراية تامة وملتزمين بالمسئوليات المنوطة بهم  وكذلك بالعلاقات البينية بين جميع العاملين بما يتوافق مع إجراءات التشغيل القياسية المشار إليها بدليل الجودة.',
                        7:'التزام إدارة المكيال السعودي بضمان اجراء الخدمات والأعمال بواسطة فريق مؤهل ومدرب تدريباً مناسباً ووفقا لطرق الاختبار المعتمدة طبقا للمواصفات القياسية والجهات الحكومية ذات العلاقة في المملكة العربية السعودية.',
                        8:'التزام إدارة المكيال السعودي توفير فرص  تطوير للموظفين لزيادة مهاراتهم واكتساب الخبرات اللازمة.',
                        9:'التزام إدارة المكيال السعودي بالقوانين التشريعية أو التنظيمية الخاصة بالمملكة العربية السعودية .',
                    },
                    code:{
                        name:'ميثاق أخلاقيات المهنة',
                        intro:'مقدمة',
                        text:'ميثاق الأخلاقيات هو تعهد لأعضاء المهنة من خلال بيان من الخبرات الجماعية يعكس تجربة العديد من الأعضاء واتفاقهم على مجموعة من الالتزامات. كما يضمن القانون المسؤولية الجماعية .',
                        text2:'جميع موظفي المكيال السعودي ملتزمون بهذا القانون الأخلاقي.',
                        1:{
                            header:'الميثاق نفسه له عدة أدوار:',
                            1:'تعزيز الممارسات الجديرة بالاهتمام.',
                            2:'بيان الالتزام المشترك لأعضاء المهنة.',
                            3:'بيان القيم المتفق عليها.',
                            4:'بيان القواعد المتفق عليها.',
                            5:'تنبيه الأعضاء بالقضايا المهمة.',
                            6:'آلية للتعليم لمن يدخلون المهنة، الشركات والعملاء.',
                        },
                        2:{
                            header:'النزاهة والأخلاق العامة:',
                            1:'نحن نتصرف بالإيمان والصدق والعدالة.',
                            2:'نحن نفعل ما قلنا.',
                            3:'نقدم خدماتنا وفقًا للعقود المُتفق عليها والإجراءات المحددة.',
                            4:'نتبع السياسات والإجراءات الخاصة بالمختبر.',
                            5:'نحترم سرية الأعمال والبيانات.',
                            6:'نحترم ونطبق المعايير المهنية والأخلاقيات المحلية لأنشطتنا.',
                            7:'نحترم التزاماتنا ومسؤولياتنا تجاه البيئة.',
                            8:'نحترم التزاماتنا ومسؤولياتنا تجاه الصحة والسلامة المهنية.',
                        },
                        3:{
                            header:'الحيادية والإستقلالية:',
                            1:'نحن فخورون بأن جميع أعمالنا تتم بشكل محايد.',
                            2:'نقدم نصائح مهنية محايدة وغير متحيزة.',
                            3:'نكتب تقارير تعكس بدقة حيادنا وفقًا لأفضل ممارساتنا.',
                            4:'لا نقبل أي ضغوط من أي نوع.',
                        },
                        4:{
                            header:'السرية:',
                            1:'نحتفظ بكل المعلومات التي تم جمعها خلال خدماتنا ونحافظ على سريتها، ما لم تكن قد خضعت للإفشاء المصرح به.',
                            2:'نتخذ التدابير اللازمة لحماية المعلومات المتاحة لنا، مع احترام هذا الالتزام بالسرية على الصعيد الشخصي وحرصنا على القيام بذلك.',
                            3:'نضمن أن حماية المعلومات السرية تتم بشكل فعال من خلال إنشاء تدابير أمنية مناسبة.',
                            4:'نضمن قصر الوصول إلى المعلومات السرية على الأشخاص المصرح لهم فقط.',
                            5:'نحن نضمن الحماية المادية للوثائق.',
                        },
                    },
                    certificates:'شهادات الجودة والاعتماد في المكيال السعودي : ',
                    certificate1:'نظام إدارة الجودة ISO 9001:2015',
                    certificate2:'نظام السلامة والصحة المهنية  لأيزو  45001 - ISO45001-2018',
                    certificate3:'نظام جودة  إدارة البيئة ISO 14001',
                    certificateSac: {
                        text:'شهادة الاعتماد من المركز السعودي للاعتماد (ساك) متطلبات  نظام الجودة لكفاءة مختبرات الفحص والمعايرة – ISO 17025 -2017',
                        riyadh:'مختبر المكيال السعودي بالرياض ',
                        khobar:'مختبر المكيال السعودي بالخبر ',
                        madinah:'مختبر المكيال السعودي بالمدينة المنورة ',
                    }
                },

                /////////Workshops page
                workshopHeader:'نحن نؤمن بأهمية تبادل المعرفة لتحقيق التطوير المستدام.',
                workshops: {
                    text1:'نحن في المكيال السعودي نولي اهتماماً كبيراً بتطوير ونقل المعرفة بشكل مستمر، كما نحرص على تنظيم ورش عمل دورية تهدف إلى تعزيز تبادل الخبرات والمعرفة، بما يسهم في رفع مستوى الاحترافية وتحفيز الابتكار وتطوير المهارات، كما نعمل أيضاً على توسيع دائرة الاستفادة من هذه الخبرات لتشمل الآخرين. ',
                    text2:'لقد قمنا بتنفيذ مجموعة متنوعة من ورش العمل، ومن أبرزها : ',
                    workshop1:'جانب من ورش العمل بمقر وكالة أمانة منطقة الرياض لشئون بلديات المنطقة بعنوان أهمية الدراسات الهيدرولوجية والجيوتقنية',
                    workshop2:'جانب من ورش العمل تم عقدها بمقر وزارة المياه والبيئة والزراعة للتعريف بمشروع دراسة وإعداد الكود السعودي لمصادر المياه واستخداماتها.',
                },  
                trainingLabs: {
                    text1:'يعد التدريب ونقل المعارف والخبرات بين الأفراد والكوادر الفنية والإدارية في مجموعة مختبرات المكيال السعودي من استراتيجيات التطوير وتحسين الأداء التي تقوم عليها سياسة الجودة',
                    text2:'ماهي أهداف نقل المعرفة في مختبرات المكيال السعودي ؟',
                    section2: {
                        goal1:'الهدف الأساسي لخطة نقل المعرفة',
                        goalSentence1:'هو ضمان الانتقال السلس للمعلومات والمهارات والخبرات المهمة، وخاصة عندما يكون هناك  تغيير في الموظفين أو الأدوار أو المشاريع.',
                        goal2:'تقليل المخاطر وتحسين الأداء في المستقبل ',
                        goalSentence2:'الهدف الرئيسي لخطة نقل المعرفة هو تقليل المخاطر المرتبطة بفقدان المواهب والمعرفة في المنظمة.',
                        goal3:'تعزيز عملية اتخاذ القرار ',
                        goalSentence3:'إن نقل المعرفة الفعال يمكّن الموظفين من الحصول على المعلومات التي يحتاجونها لاتخاذ قرارات مستنيرة. عندما يتم تبادل المعرفة عبر المنظمة، يتمكن صناع القرار من الوصول إلى رؤى متنوعة وبيانات تاريخية، مما يمكنهم من النظر في وجهات نظر متعددة واتخاذ خيارات أكثر شمولاً.',
                        goal4:'تقليل التكرار',
                        goalSentence4:'حدى المشاكل الشائعة في الشركات هي ازدواجية الجهود في حل نفس المشكلة دون إدراك ذلك. يساعد نقل المعرفة في القضاء على هذه الازدواجية من خلال ضمان سهولة الوصول إلى المعلومات حول المشاريع الجارية والحلول السابقة.',
                        goal5:'تحسين عملية دمج الموظفين وتدريبهم',
                        goalSentence5:'عد نقل المعرفة أمرًا حيويًا لتدريب الموظفين الجدد وتوفير التدريب المستمر لهم',
                        goal6:'ضمان استمرارية الأعمال ',
                        goalSentence6:'يضمن نقل المعرفة الفعال عدم فقد المعلومات المهمة عندما يترك الموظفون العمل ',
                        goal7:'تعزيز رضا العملاء ',
                        goalSentence7:'عندما يكون الموظفون على دراية جيدة ويمكنهم الوصول إلى المعلومات ذات الصلة بسرعة، فإنهم يكونون مجهزين بشكل أفضل لتلبية احتياجات العملاء وحل المشكلات على الفور.',
                    },
                    text3:'يتم نقل المعرفة والتدريب في مجموعة مختبرات المكيال السعودي من خلال : ',
                    section3: {
                        text1:'وضع خطة تدريب سنوية وتوثيق إجراءاتها',
                        text2:'عمل الدورات التدريبية  داخلية  بشكل دوري',
                        text3:'تنظيم ورش عمل  داخل المنشاة بشكل دوري ',
                        text4:'عمل دورات تدريبية خارجية  لرفع مستوى الكوادر في  المختبر',
                        text5:'المشاركة الفعالة  في الدورات وورش العمل لدى الهيئات الحكومية وشبه الحكومية والخاصة',
                        text6:'إنشاء وتطوير برامج قواعد البيانات لحفظ وتوثيق النتائج والوثائق والمعارف والخبرات',
                    },
                },      
                
                ////////NotFound Page
                notfoundHeader: 'الصفحة غير موجودة',
                notfoundText: 'عذرًا، لا يمكننا العثور على الصفحة التي تبحث عنها',
                notfoundBtn: 'العودة إلى الصفحة الرئيسية',
            },
            
        },
        en: {
            translation: {
                siteName:'AlMekyal AlSaudi',
                greeting:"Welcome at AlMekyal AlSaudi",
                text:"We are delighted to have you visit us and would like to inform you that work is underway to develop the website.",
                contact:"For Contact:",
                infoName:"AlMekyal AlSaudi Engineering Consulting Company",
                labName:"AlMekyal AlSaudi Engineering Laboratories",
                mail:"Email: ",
                phone:"Phone: ",
                more:"More",
                back:'Back to home',
                details:"Show Details",
                detailService:'Service Details',
                all:"All Projects",
                forMore:'For More',
                allProjects:"All",
                seeMore:"See More",
                readMore:"Read more",
                send:"Send",
                location:'Riyadh - Al Quds',
                //////nav
                textSelection: "AlMekyal AlSaudi is a group consisting of AlMekyal AlSaudi Engineering Consulting Company and AlMekyal AlSaudi Construction and Building Laboratories. It is licensed and accredited by various governmental and semi-governmental entities and operates according to high standards and regulations. It is staffed by a distinguished team of technical experts across all branches. For more information, you can click on the establishment's name at the bottom of the page.",
                lab1: "AlMekyal AlSaudi Laboratories",
                lab2: "For Testing Soil And Building Materials",
                company1: "AlMekyal AlSaudi Company",
                company2: "For Engineering Consulting",
                navHome:'Home',
                about:'About Us',
                services:'Services',
                projects:'Projects',
                projectsLabs:'Projects And Works',
                navClients:'Clients',
                // navClients
                navGovernment:'Government Entities',
                navPrivate:'Private Entities',
                navSafety:'Quality And Safety policies',
                navQualityLabs:'Development And Quality',
                navWorkshops:'Workshops And Knowledge Transfer',
                navTrainingLabs:'Training And Knowledge Transfer',
                navMediaCenter:'Media Center',
                contactUs:'Contact Us',
                navPhoneT:'Phone Number',
                navLocationT:'Location',
                navEmailT:'Email Address',
                navProfile:'Upload Profile',
                // navAbout
                navMessage:'Our Message',
                navOurMessage:'Company Vision',
                navWhyUs:'About us',
                navGrowth:'Growth And Development',
                // navServices
                navGeotechnical:'Geotechnical Engineering And Soil Studies',
                navSoil:'Soil Tests, Building Materials And Construction',
                navChemical:'Chemical Analysis Tests For Soil And Water',
                navBuilding:'Building And Structure Testing',
                navStudies:'Special Technical Studies of Soil',
                // navServiceSoil
                navSoilAggregateTest: 'Soil And Aggregate Materials Tests',
                navAsphaltTest: 'Asphalt Tests',
                navLiquidBitumenTest: 'Bitumen Tests',
                navConcreteTest: 'Concrete Tests And Mix Design',
                navBlockTileTest: 'Tests of Blocks, Tiles, Interlocking',
                // navServicesCompany
                navArchitectural :'Architectural Engineering',
                navHydrological :'Water Engineering And Hydrological Studies',
                navGIS:'Surveying And Geographic Information Systems (GIS)',
                navSupervision :'Implementation Supervision & Project Management',
                navInfrastructure :'Infrastructure Design',
                navTransport :'Transport And Traffic Engineering',
                navElectrical :'Electrical Engineering',
                navEvaluation:'Building And Facility Assessment Study',
                // navProjects
                navGeotechnicalProject:'Geotechnical Engineering',
                navQualityProject:'Material testing & quality control',
                navBuildingProject:'Building And Facility Inspection',
                //////footer
                footerAddress:'Main Office Address',
                footerWorkHours:'Working Hours',
                footerWelcome:'Welcome to our main office',
                footerWeekCompany:'Sunday - Thursday',
                footerWeekLabs:'Saturday - Thursday',
                footerWeekTimeCompany:'7:30 AM - 5:00 PM',
                footerWeekTimeLabs:'8:00 AM - 4:00 PM',
                footerSaturday:'Saturday',
                footerFriday:'Friday',
                footerHoliday:'Holiday',
                footerCompany:'About Consultancy',
                footerCompanyAbout:'AlMekyal AlSaudi Engineering Company, your trusted engineering office in the heart of Riyadh, is your primary destination for comprehensive and specialized engineering consultancy  services.',
                footerLabs:'About Laboratories',
                footerLabsAbout:'AlMekyal AlSaudi Engineering Company, your accredited engineering office in the heart of Riyadh, is your primary destination for comprehensive and specialized engineering tests services.',
                footerLinks:'Important Links',
                //////header
                // headerNameEngineering: "Engineering",
                headerCompanyEngineering: "AlMekyal AlSaudi Company",
                headerNameCompany: "For Engineering Consultancy",
                headerNameLabs: "Laboratories",
                headerSentence:'We are committed to providing innovative and comprehensive engineering solutions that meet our clients needs and contribute to achieving their vision.',
                // headerAboutCompany: "AlMekyal AlSaudi Engineering Consultancy was established in 1432 AH as a national engineering company offering a full range of engineering consultancy services. Our services include project supervision, structural and architectural designs, surveying and topography engineering, water engineering, environmental engineering, and electrical engineering. We are distinguished by a team of highly experienced and skilled specialists in all areas of engineering consultancy. We believe in the importance of providing integrated and innovative solutions that meet the needs of our clients and enhance the quality and efficiency of engineering projects. We continuously strive for excellence by adhering to the highest standards of quality and up-to-date knowledge.",
                headerAboutCompany:`AlMekyal AlSaudi Engineering Consultancy Company was established in 1432 AH as a national engineering firm offering a full range of engineering consultancy services. Our expertise spans project supervision, structural and architectural design, surveying and topography, water engineering, environmental engineering, and electrical engineering. With a highly skilled and experienced team of specialists in all areas of engineering consultancy, we are dedicated to delivering integrated and innovative solutions that fulfill our clients' requirements while enhancing the quality and efficiency of their projects.`,
                headerAboutLabs:`It is a leading national group of construction and building laboratories specializing in soil testing and construction material testing (soil, concrete, asphalt, etc.), as well as building materials and geotechnical studies. It also provides specialized technical studies in the field of soil and foundation engineering, testing and evaluation of buildings and structures, and technical consultancy in these fields.
AlMekyal AlSaudi possesses the best expertise and technical personnel, as well as the latest equipment, technologies, and laboratory tools for testing and inspections. It applies the most advanced programs and applications in the field of engineering laboratories across the Kingdom of Saudi Arabia.`,
                ///////servicesLabs
                valueEngineering:'Value Engineering',
                valueEngineeringSentence:'Value Engineering is an analytical study conducted using a structured methodology by a multidisciplinary team. Its goal is to identify and classify the functions performed by a project or product, aiming to achieve those functions in the most efficient and cost-effective manner.',
                surveyingEngineering:'Surveying Engineering',
                surveyingEngineeringSentence:'Surveying Engineering involves conducting detailed land surveys essential for urban planning projects, infrastructure services, electrical systems, and industrial cities. These surveys provide the foundational data required for accurate project execution.',
                waterStudies:'Water Studies And Flood Drainage',
                waterStudiesSentence:'Our technicians utilize the latest engineering software to ensure precise and implementable solutions for water studies and flood drainage systems, enabling effective and sustainable project outcomes.',
                service3LabSentence:'Our services include soil and aggregate material testing, asphalt testing, liquid bitumen testing, concrete testing and mix design, as well as testing of blocks, tiles, interlocking tiles, and kerbs.',
                service2LabSentence:'Chemical analysis services include determining pH levels, sulfate concentration, chloride concentration, as well as measuring soil salinity and total dissolved salts.',
                service1LabSentence:'The geotechnical engineering department specializes in soil testing and field investigations using advanced equipment, providing reports with soil analysis, groundwater levels, and project solutions.',
                service4LabSentence:'Services include core sampling of concrete, rebar detection, material resistance testing, and roof load testing.',
                service5LabSentence:'Services include core sampling of concrete, rebar detection, material resistance testing, and roof load testing.',
                aggregateTesting:'Aggregate Testing',
                ///////servicesCompany
                service1CompanySentence:'Urban planning (residential, commercial, industrial, and park planning) and architectural design for government, residential, or commercial projects',
                service2CompanySentence:'We provide comprehensive services in hydrological studies and flood risk mitigation, using advanced modeling to design urban drainage systems and analyze topography and morphological features.',
                service3CompanySentence:'We provide surveying services, including topographic surveys, control point creation, and the use of techniques like drones and GPR. Data is converted into digital maps and entered into GIS systems.',
                service4CompanySentence:'We provide comprehensive services, including reviewing technical plans, estimating quantities, determining costs, and supervising execution to ensure efficiency. Additionally, we oversee infrastructure projects (roads, water, drainage, electricity) with regular progress reporting',
                service5CompanySentence:'We provide engineering studies and designs for infrastructure projects, including road, water, sewage, and stormwater networks, with supervision of execution and quantity calculations according to the highest engineering standards',
                service6CompanySentence:'We offer consultancy services for road projects, covering all pre-execution, execution, and operation phases, focusing on vehicle and pedestrian movement using the latest software for analysis and design',
                service7CompanySentence:`We provide comprehensive services, including the design of low and medium voltage networks, road lighting compliant with local authorities' requirements, and electrical designs for residential, commercial, and government projects as per Saudi Building Code.`,
                service8CompanySentence:'We provide structural assessment services to ensure building safety and prolonged utility, including rehabilitation, addressing structural defects, and extending lifespan',
                ///////Achievements
                achievements:'Our Achievements',
                experience:'Years of Experience',
                million:'Million Square Meters',
                land:'Land',
                entity:'Government Bodies',
                buildings:'Buildings & Structures',
                kilometer:'Linear Kilometer',
                project:'Project',
                achieveProjects:'Projects',
                years:'Years',
                companies:'Private Companies',
                plans:'Government Plan',
                landsPlans:'Lands and Layouts',
                reports:'Reports and Studies',
                towers:'Tower and High-Rise Buildings',
                bridges:'Bridges & Tunnels',
                technical:'Technical Studies',
                test:'Study',
                // Company Achievements
                companyAchieve1:'In the Field of Engineering Consultancy',
                companyAchieve2:'Government, Semi-Government, and Private Entities Collaborating with AlMekyal AlSaudi',
                companyAchieve3:'Designed for various uses (residential, commercial, governmental).',
                companyAchieve4:'Surveyed by professional teams',
                companyAchieve5:'Raw has been planned.',
                companyAchieve6:'Designed as water and sewerage networks.',
                companyAchieve7:'Supervision of electrical works related to the Saudi Electricity Company',
                companyAchieve8:'Main and secondary roads have been designed.',
                companyAchieve9:'Concrete structures have been tested and evaluated.',
                companyAchieve10:'It has been supervised.',
                companyAchieve11:'It has been studied hydrologically, and a drainage and flood risk mitigation study has been prepared for it.',
                // Labs Achievements
                labsAchieve1:'In the Field of Engineering Laboratories',
                labsAchieve2:'Accredited by Government and Semi-Government Entities',
                labsAchieve3:'Accredited by Them',
                labsAchieve4:'Studied',
                labsAchieve5:'Residential – Soil Investigations and Studies Conducted',
                labsAchieve6:'Completed',
                labsAchieve7:'Foundation Soil Studied',
                labsAchieve8:'Technically Studied',
                labsAchieve9:'For Testing Buildings and Structures',
                labsAchieve10:'Soil Investigations and Studies Conducted',
                labsAchieve11:'Quality Control and Materials',
                labsAchieve12:'Detection of Subsurface Cavities',
                ///////projects
                projectTextCompany:'Some Projects of AlMekyal AlSaudi Engineering Consultancy',
                projectTextLabs:'Some Projects of AlMekyal AlSaudi Engineering Laboratories',
                projectHeader:'Towards a Bright Future with Every Project We Accomplish.',
                projectsIntro1:'ALMekyal AlSaudi labs has implemented many qualitative projects in the field of testing and studying soil and foundations, geotechnical studies, tests of soil materials, asphalt, concrete and construction materials, and inspection and evaluation of buildings.',
                projectsIntro2:' We will mention in the following some of the most important projects and field of work',

                projectDetails: {
                    customer: "Owner:",
                    workScope: "Scope of Work:",
                    geographicalScope: "Geographical Scope:",
                    executionYear: "Implementation Year:",
                    startDate: "Start Date:",
                    status: "Status:",
                },                
                related:'Related Projects',
                //projects labs
                // projects1:{
                //     1:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     2:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     3:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     4:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     5:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     6:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         status:'',
                //         description: '',
                //     },
                //     7:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         status:'',
                //         description: '',
                //     },
                //     8:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                //     9:{
                //         name: '',
                //         customer: '',
                //         workScope: '',
                //         geographicalScope:'',
                //         description: '',
                //     },
                // },
                projects1: {
                    1: {
                        name: "Soil investigation for Southern Sectors in Riyadh Area",
                        customer: "Riyadh Municipality",
                        workScope: "Soil investigation and comprehensive report& Hydrological study ",
                        geographicalScope: "Different Municipalities in Riyadh area AL-Dulm, Al Kharj, Al Huta, Al Hariq, Al Aflaj, Al Badea, Al Ahmar, Al Sulayel and Wadi Addwaser",
                        description: "",
                    },
                    2: {
                        name: "Soil investigation for Al Widyan plan, Diriyah North in Riyadh City. 7000,000 m2 of 2795 boreholes",
                        customer: "Al Wedyan Saudi Real Estate",
                        workScope: "Soil investigation and comprehensive report Accredited by Riyadh Municipality",
                        geographicalScope: "Salbukh- North of Diriyah in Riyadh",
                        description: "",
                    },
                    3: {
                        name: "Soil investigation for school projects in Jedah",
                        customer: "Tattweer Building Company- TBC",
                        workScope: "Soil investigation and comprehensive report for school buildings",
                        description: "",
                    },
                    4: {
                        name: "Soil Investigations for Sedra-2 Roshn in Riyadh",
                        customer: "Roshn Real Estate company",
                        workScope: "Soil investigation and comprehensive report",
                        description: "",
                    },
                    5: {
                        name: "Material Testing at Al Jubail Industrial City",
                        customer: "Royal Commission for Jubail and Yanbu",
                        workScope: "Material Testing, QC & QA for projects belongs to Royal Commission for Jubail-Five Years period",
                        status: "In progress",
                        description: "",
                    },
                    6: {
                        name: "Under-ground utilities quality control & quality assurance -framework agreement",
                        customer: "Under-Ground utilities Projects’ Center Company",
                        workScope: "Soil, Concrete & Asphalt for quality control purposes of roads and under-ground utilities (water supply, water seepage & electricity)",
                        status: "Ongoing within the framework of laboratories approved by the Riyadh Municipality",
                        description: "",
                    },
                    7: {
                        name: "Structural assessment for King Khaled Palace and buildings around",
                        customer: "Imam Abdulaziz bin Muhammad Royal Reserve Development Authority",
                        workScope: "inspections and tests to evaluate the structural condition of King Khaled Palace and the surrounding buildings and annexes in the King Khaled Reserve",
                        description: "",
                    },
                    8: {
                        name: "Structural assessment for King Abdulazeez Military City",
                        customer: "Military Works -Military of defense",
                        workScope: "Structural assessment for different buildings in King Abdulazeez Military city in Tabuk",
                        geographicalScope: "Tabuk",
                        description: "",
                    },
                    9: {
                        name: "Structural evaluation of  buildings at the main center of the Saudi Water Authority and the administrative building at Al-Takhassusi street in Riyadh city",
                        customer: "Saudi Water Authority",
                        workScope: "inspection tests & Structural evaluation and preparation of a structural case study",
                        geographicalScope: "Riyadh City",
                        description: "",
                    }
                },
                

                //projects company
                projects2:{

                },

                //////form
                contactHeader: {
                    text1: 'We are here to help you!',
                    text2: 'We are happy to assist you with your inquiries and provide the necessary engineering support for your projects.',
                    text3: 'Feel free to contact us through the following channels:',
                    text4C: 'You can also visit us at our office during working hours, from Sunday to Thursday, from 8:00 AM to 5:00 PM.',
                    text4L: 'You can also visit us at our office during working hours, from Saturday to Thursday, from 8:00 AM to 4:00 PM.',
                    text5: 'Or send your inquiries through the contact form below:',
                    text6: 'We are delighted to serve you and look forward to a fruitful collaboration that achieves your engineering goals with precision and professionalism.',
                    contactForm: 'Contact Form',
                    linkedIn: 'LinkedIn',
                },
                firstName:'First Name',
                yourFirstName:'Your First Name',
                LastName:'Last Name',
                yourLastName:'Your Last Name',
                formEmail:'Email Address',
                yourEmail:'Your Email',
                formPhone:'Phone',
                yourPhone:'Your Phone',
                subject:'Subject',
                yourSubject:'For Example : Support',
                message:'Message',
                yourMessage:'Your Message',
                fullName: 'Full Name',
                yourFullName: 'Your Full Name',
                companyName: 'Company/Organization Name',
                optional:'Optional',
                serviceDetails: 'Details of the Requested Service',
                select:'Select',
                architecturalDesign: "Architectural Design",
                infrastructureConsulting: "Infrastructure Consulting",
                projectSupervision: "Project Supervision",
                environmentalConsulting: "Environmental Consulting",
                electricalProjectSupervision: "Electrical Project Supervision",
                other: "Other (Please specify)",
                projectSite: 'Project Location',
                site: 'City / Area',
                attachedFiles: 'Attached Files',
                additionalNotes: 'Additional Notes',
                anyInformation:'Any other information you wish to add',
                favoriteContact: 'Preferred Method of Contact',
                emailContact: "Email",
                phoneContact: "Phone Call",
                meetingContact: "In-person Meeting",
                ///////news
                newsText1:'We will keep you up to date with all of the latest Almekyal AlSaudi news',
                newsText2:'Visit our most popular news of the month',
                newsText3: 'Stay updated with the latest news and achievements of AlMekyal AlSaudi',
                latestNews: {
                    text: 'Latest news from AlMekyal AlSaudi Laboratories:',
                    1: 'AlMekyal AlSaudi Laboratory in Al-Khobar (Eastern Region Branch) has been accredited by the Saudi Accreditation Center (SAC) under certification number N-T-00517, in compliance with the ISO/IEC 17025:2017 standard for general quality requirements for the competence of testing and calibration laboratories.',
                    2: 'AlMekyal AlSaudi Laboratory in Al-Khobar (Eastern Region Branch) has obtained a license to use radioactive sources for measurement and exploration – License No. 10739ER-01 from the Nuclear and Radiological Regulatory Commission in Saudi Arabia.',
                    3: 'The project for managing quality control and testing of execution works in the Oasis Initiative and other industrial cities has been awarded to AlMekyal AlSaudi by the Saudi Authority for Industrial Cities and Technology Zones.',
                },
                
                ///////departments
                departments: "Departments and Specializations",
                departmentHeader1: "Construction Materials Testing",
                departmentText1: "Assessing material quality to ensure compliance with standards and specifications.",
                departmentHeader2: "Quality Control Projects",
                departmentText2: "Monitoring quality in projects to ensure execution meets engineering standards.",
                departmentHeader3: "Geotechnical Engineering and Technical Studies",
                departmentText3: "Analyzing soil and foundations to provide accurate engineering solutions.",
                departmentHeader4: "Building and Structure Inspection",
                departmentText4: "Evaluating the safety and performance of buildings to ensure sustainability and safety.",
                departmentHeader5: "Chemical Analysis Unit for Water and Soil",
                departmentText5: "To understand the chemical properties of soil and water and their impact on the foundations of structures.",
                  
                ///////branches
                branches:'AlMekyal AlSaudi Laboratories Branches',
                branchesTextCompany:'We strive to provide professional engineering consultancy services with the highest standards',
                branchesTextLabs:'We strive to provide professional engineering tests services with the highest standards',
                branch1:'Main office - Riyadh',
                branch1Detailed:'Riyadh, Al Quds District',
                branch2:'Madinah Branch',
                branch2Detailed:'2978 Khawlah Bint Hudhayfah Bin Al-Yaman, Ar Rawabi District, Madinah 42381, Saudi Arabia',
                branch3:'astern Region Branch – Khobar',
                branch3Detailed:'Al-Najah Street, Ibn Sina District, Al Khobar 34627, Saudi Arabia.',
                branch4:'astern Region Branch – Jubail',
                branch4Detailed:'King Fahd Industrial Port, Al Jubail 35525, Saudi Arabia',

                ////////About Page
                engineeringConsultancy: "We provide expert engineering consultancy to help bring your projects to life.",
                engineeringLaboratories: "Our engineering laboratories offer precise testing to support your engineering decisions.",
                ourProjects:'Our Projects',
                visionSentenceLabs:'A passion for leadership, excellence in performance, and ambition to position AlMekyal AlSaudi as a distinguished name in the fields of soil engineering, materials, and construction laboratories.',
                goalsSentenceLabs:'To be a leading name in the field of construction and building laboratories across Saudi Arabia and the Middle East.',
                valuesSentenceLabs:'Our values guide our behavior, emphasizing hard work, high professionalism, and the application of the highest quality standards in delivering our services.',
                visionSentenceCompany: `To be trusted partners in building high-quality projects by offering integrated engineering services that meet our clients' aspirations, keeping pace with the rapid development in the engineering and construction sector, and achieving a leading position in the engineering field in Saudi Arabia`,
                OurMessageCompany: 'To deliver high-quality, professional, and integrated engineering services that focus on meeting our clients’ needs, realizing their visions, and contributing to sustainable infrastructure development in alignment with the aspirations of Saudi Vision 2030',
                goalsSentenceCompany: `To make AlMekyal AlSaudi a trusted name and a leading source for engineering solutions in Saudi Arabia by ensuring full commitment to our clients, excelling in innovation, and continuously developing our team's skills to provide superior and professional engineering consultancy services`,
                aboutHeaderEngineering: 'Delivering innovative engineering construction solutions with a commitment to excellence',
                aboutHeaderLaboratories: 'Advancing construction standards with advanced engineering laboratories',
                aboutSentenceEngineering: 'We believe that successful construction is built on strong collaboration and understanding. Construction is not just about assembling structures; it’s about creating lasting, functional solutions that meet the needs of our clients and the community.',
                aboutSentenceLaboratories: 'We believe that precision and reliability are key to successful engineering, and we work closely with our clients to ensure that every test and analysis meets the highest standards. Our laboratories are equipped with state-of-the-art technology to support the construction process and material quality.',
                aboutOrganization: 'Organizational Chart',
                aboutCompanyOrganization:`The Company's Organizational Structure`,
                navVision:'Vision',
                navGoals:'Our Goals',
                navValues:'Our Values',
                navOurVision:'Our Vision',
                aboutText1: 'AlMekyal AlSaudi Engineering Consulting Company is a national engineering firm established in 1432 AH (2011). The company is distinguished by a comprehensive team of highly experienced and skilled specialists in all fields of engineering consultancy. Our services include:',
                urbanPlanning: 'Urban Planning',
                urbanDesign: 'Urban Design',
                aboutText2: 'Since its inception, we have collaborated with both the public and private sectors, contributing to the execution of significant projects with governmental entities such as the Ministry of Health, Ministry of Transport, Ministry of Environment, Water, and Agriculture, among many others. These partnerships have played a vital role in enhancing our expertise and reputation, allowing us to combine experience, precision, and professionalism.',
                managerWord: 'General Manager’s Word:',
                managerWordSentence: 'At AlMekyal AlSaudi, we are professionally committed to applying the best standards and the latest technologies in the field of engineering services. We aspire to deliver these services with high performance and outstanding quality, enabling our partners to benefit from our expertise to fulfill their requirements. We offer our services across all engineering fields through a carefully selected team of specialists and experts, following global standards and principles. We continuously strive for development to contribute to building a remarkable future.',
                
                // about growth
                growth1:'Establishment of AlMekyal AlSaudi Engineering Consultancy',
                growth2:'Providing engineering services to government entities',
                growth3:'Establishment of AlMekyal AlSaudi Engineering Laboratories',
                growth4:'Implementation of ISO 9001 Quality Management System and ISO 45001 Occupational Health and Safety System',
                growth5:'Expansion and opening of new branches in multiple regions of the Kingdom',
                growth6:'Qualification of AlMekyal AlSaudi in quality projects with government entities',
                growth7:'AlMekyal AlSaudi Laboratories receive ISO 17025 certification',
                growth8:'AlMekyal AlSaudi classified as "First Class"',
                growth9:'Joining a coalition with foreign firms for large-scale projects',
                growth10:'Collaboration with royal commissions in design and quality control work',

                // about important engineers
                importantEngineers: 'The most important cadres at alMekyal alSaudi',
                eng1: 'Eng. Hatem Al-Othman',
                eng1JobTitle: 'Executive Director of AlMekyal AlSaudi Labs',
                eng1About: 'He specializes in the field of engineering laboratories and geotechnical engineering, he has more than 22 years of experience in establishing, developing and managing engineering laboratories. he holds a bachelor’s degree in civil engineering - specializing in geotechnical engineering in 2002. He worked as a materials engineer and later as director of the soil and materials department at GEH Engineering Company, where he contributed to establishing and developing laboratories there,In addition, he worked as a lecturer at the Road Institute from 2004-2011. projects manager at Nice Contracting Company. He founded and managed the Testing House Lab from 2015 to 2018, after that he moved to AlMekyal Alsaudi and currently works as Executive Director of AlMekyal AlSaudi Labs group.',
                
                eng2: 'Eng. Nader Al-Ikhwan',
                eng2JobTitle: 'Riyadh Lab Manager - Chief technical & Geotechnical manager',
                eng2About: 'Bachelor of Civil Engineering / Geotechnical Engineer. 26 years’ experience in Geotechnical investigations, comprehensive reports, soil problems consultant and structural assessments.1997-1998 Military Housing General establishment – Syria. 1998-2013 Saudi Labs company. 2013-2022 ECEC / Establishing Adeema Lab under Abdullah Ahmad Bughshan. 2022-till now Riyadh Laboratory manager.Geotechnical Expert, Chief technical & Geotechnical manager.',
                
                eng3: 'Eng. Mohammad Al-Youssef',
                eng3JobTitle: 'Regional Director of M.S Lab In Medina',
                eng3About: 'He obtained a bachelor’s degree in Geology from the Aleppo University in 2000. He worked for Abdul Mohsen Al-Kharafi Company on road and bridge projects in Syria until 2003, then work in Mohamed Gunas company (Turkish Company )  as a materials lab_ manager in a land reclamation project in northern Syria until 2005. .then worked with Rashid Geotechnical and Materials Engineers (RGME)  in the Republic of Djibouti as a resident engineer for a groundwater drilling project. In 2006, he moved to Riyadh, Saudi Arabia, where he worked for Gulf Engineering Hous on the Madinah Laboratory Project and continued there until 2014. He then worked for the Arabian Company for Laboratories and Soil ACES as Medina  branch manager until 2019. Since 2019, he has been working as the Director of Medina Region of the AlMekyal AlSaudi Lab.',
                
                eng4: 'Eng. Mohammad Ali Solaibi',
                eng4JobTitle: 'Regional Director of M.S Labs In Eastern Area',
                eng4About: 'He obtained a Bachelor’s degree in Engineering Geology from the University of Latakia in 2001 . he has over 20 years of experience in the field of materials and soil testing. He began his career at the Syrian Highway Company - Central Region - as a materials engineer on the Homs-Palmyra Highway project. Then, in 2005, he worked in Saudi Arabia with Gulf Engineering Hous  as lab manager of the company’s laboratory in Riyadh until 2010. He later worked for Abdul Ali Al-Ajmi Contracting Company as the director of the company’s laboratories until 2022. Since 2023, he has been working as the branch manager of AlMekyal AlSaudi Company in the Eastern Region.',
                
                eng5: 'Eng. Islam Talaat',
                eng5JobTitle: 'Lab manager- Jubail branch',
                eng5About: 'He graduated from the Faculty of Civil Engineering, Alexandria University in 2004. He has more than 20 years of experience in the engineering field. He worked at Talaat Moustafa Group for one year, then moved to work in Saudia Arabia, where he worked at Al-Mubdi Contracting Company for three years. Then he worked in the field of engineering services and consultations in the Eastern Province in several companies Such as GEH and GHL laboratory, now he is works in ALMekyal Alsaudi as director of the Jubail lab.',
                
                eng6: 'Dr. Mohamed Sobhey',
                eng6JobTitle: 'Director of the Geotechnical Department - M.S Lab- Medina Branch',
                eng6About: 'He graduated from the Faculty of Civil Engineering, Alexandria University in 2004. He has more than 20 years of experience in the engineering field. He worked at Talaat Moustafa Group for one year, then moved to work in Saudia Arabia, where he worked at Al-Mubdi Contracting Company for three years. Then he worked in the field of engineering services and consultations in the Eastern Province in several companies Such as GEH and GHL laboratory, now he is works in ALMekyal Alsaudi as director of the Jubail lab.',                

                servicesHeader: 'Delivering services to support your journey and help you thrive',
                ////////Services Page Labs
                servicesText1:'AlMekyal AlSaudi Engineering Laboratories for Construction and Building',
                servicesText2:'Offers a range of testing within the construction sector and inspection services in geotechnical and materials engineering',

                // services1
                geoHeader: {
                    section1: {
                        text1: 'Geotechnical engineering is considered one of the most important fields of work and specialization in Almekyal Alsaudi laboratories.',
                        text2: 'AlMekyal AlSaudi has full equipment and facilities to conduct all field investigations and laboratory geotechnical tests, including drilling rigs for sampling at different depths and determining the groundwater level. The Geotechnical Engineering Department possesses a number of rigs mounted on trucks, which can drill boreholes and take samples across the Kingdom, as well as prepare comprehensive technical reports, provide solutions, and make technical recommendations.',
                    },
                    section2: {
                        text: 'A number of technical teams work on these rigs, including skilled drillers, assistant drillers, and trained workers, supervised by geologists and experienced geotechnical engineers.',
                    },
                    section3: {
                        text: 'The methodology for soil testing studies is based on the following elements:',
                        1: {
                            header: 'Purpose of soil study:',
                            text: 'The study aims to determine: the characteristics and nature of soil layers at the site, description of the surface soil and sub-surface layers at all borehole depths, determining the ultimate and allowable bearing capacity of the soil, determining the foundation depth and suitable foundation type, as well as expected settlements and differential settlements, and groundwater level if present. The report includes all field and laboratory tests, the characteristics of the excavation soil, backfill works, the type of concrete used in foundations, and the necessary technical recommendations and engineering suggestions.',
                        },
                        2: {
                            header: 'Determining the Number and Depth of Boreholes and Distribution:',
                            text: 'Boreholes are distributed across the project site in a grid pattern every 50 meters, with one borehole per 2500 square meters, according to geotechnical study requirements for residential plans issued by the Ministry of Municipal and Rural Affairs. The borehole depth specification is 10 meters in soil, and in case of rocky layers, the borehole is drilled through continuous rock layers for 3-5 meters (depending on hardness). The number and distribution of boreholes are determined based on geotechnical specifications from the Ministry of Municipal and Rural Affairs.',
                        },
                        3: {
                            header: 'Geological Study of the Area:',
                            text: 'This involves determining the geological nature of the project area, the predominant geological formation, and the geological zone (Arabian Plate, Arabian Shield, etc.) based on approved geological maps.',
                        },
                        4: {
                            header: 'Determining the Seismic range of area:',
                            text: 'The seismic zone and seismic zone factors are determined, along with the seismic classification of the soil and rock.',
                        },
                        5: {
                            header: 'Field Investigations carried out:',
                            text1: 'The drilling was done using mobile drilling machines, with samples of surface soil taken and preserved in plastic containers for laboratory testing. Rock samples were collected in specialized plastic and wooden boxes for each core extracted to facilitate description and quality measurement (RQD and TCR) at each depth. Each sample or core box was labeled with project details, borehole number, drilling date, rig number, and operator name for easy documentation. A borehole log was created, showing the entire borehole section, soil and rock layers, and their characteristics and depths.',
                            text2: 'Field investigations also include determining the presence of a groundwater table and its depth if present.',
                        },
                        6: {
                            header: 'Field and Laboratory Tests:',
                            list: {
                                1: 'Water content of samples (moisture content) (ASTM D – 2216)',
                                2: 'Grain size analysis of soil (ASTM D – 422)',
                                3: 'Atterberg Limits Tests (Liquid Limit and Plastic Limit) (ASTM D – 4318)',
                                4: 'Direct shear test (ASTM D 3080) - (for soil samples)',
                                5: 'Unconfined compression test for rock samples (ASTM D 7012)',
                                6: 'Maximum dry density of soil (Proctor) (ASTM D -1557)',
                                7: 'California Bearing Ratio (CBR) Test',
                                8: 'Chemical analysis of soil samples',
                                9: 'AASHTO Soil Classification Chart',
                                10: 'USCS Soil Classification Diagrams',
                            },
                        },
                        7: {
                            header: 'Describe the nature of the site’s surface soil and soil layers.',
                        },
                        8: {
                            header: 'Technical calculations of soil bearing capacity and soil settlement:',
                            text: 'This involves applying bearing capacity equations and tables, including Terzaghi, Meyerhof, Hansen, etc., as well as calculating immediate and potential settlements, and determining the bearing capacity of rock layers.',
                        },
                        9: {
                            header: 'The Results & Recommendations:',
                            text1: 'Description of foundation soil, foundation type, foundation depth, recommended soil bearing capacity, expected settlement, differential settlement, and engineering recommendations and proposals deemed necessary by the report preparer.',
                            text2: 'The report includes appendices such as:',
                            list: {
                                1: 'Maps including geological, geographical, and topographical maps of the project site and boundaries.',
                                2: 'Appendix with borehole sections, investigations, and field tests.',
                                3: 'Appendix with laboratory tests.',
                                4: 'Engineering calculations for soil bearing capacity and allowable settlements.',
                                5: 'References used in the study.',
                            },
                        },
                    },
                    section4: {
                        text1: 'AlMekyal AlSaudi owns several geotechnical drilling rigs to complete soil study works.',
                        text2: 'Geotechnical engineers prepare the technical geotechnical report, including:',
                        list: {
                            1: 'Determining the physical and chemical properties of soil and rock.',
                            2: 'Calculating the bearing capacity of soil and rock.',
                            3: 'Determining the impact of the water table on the bearing capacity of foundations and structures.',
                            4: 'Determining settlements beneath foundations and their impact on the stability of structures.',
                            5: 'Monitoring changes in the water table and determining its impact on the engineering properties of the soil.',
                            6: 'Conducting electrical resistivity tests using the Wenner array.',
                            7: 'Designing shallow and deep foundations (piles).',
                            8: 'Implementing dynamic compaction and soil stabilization works.',
                            9: 'Drainage studies and measurement of subsurface water levels.',
                        },
                    },
                },                

                // services2
                // 1
                soilFirst:{
                    1:'The “water content” of the soil',
                    2:'Standard Test Method for Particle-Size Analysis of Soils',
                    3:'Standard Test Methods for Liquid Limit, Plastic Limit, of Soils',
                    4:'Classification of Soils for Engineering Purposes',
                    5:'Standard Test Method for Laboratory Compaction Characteristics of Soil',
                    6:'Standard Test Method for California Bearing Ratio (CBR) ',
                    7:'Density and Unit Weight of Soil in Place by the Sand-Cone Method',
                    8:'Standard Test Methods for In-Place Density and Water Content of Soil and Soil-Aggregate by Nuclear Methods',
                    9:'Sand Equivalent Value of Soils and Fine Aggregate',
                    10:'Maximum and Minimum Index Density and Unit Weight of Soils Using a Vibratory Table',
                    11:'Plate Load Test',
                },
                aggregateSecond:{
                    1:'Standard Test Method for Sieve Analysis of Fine and Coarse Aggregates',
                    2:'Standard Test Method for Soundness of Aggregate by Use of Sodium Sulfate or Magnesium Sulfate.',
                    3:'Standard Test Method for Specific Gravity and Absorption of Coarse and Fine Aggregate.',
                    4:'Standard Test Method for Flat Particles, Elongated Particles, or Flat and Elongated Particles in Coarse Aggregate.',
                    5:'Determine the compressive strength of rock',
                },
                // 2
                asphalt: {
                    text1: 'Tests for the design and auditing of asphalt mixtures.',
                    text2: 'This includes all the necessary tests for designing and auditing asphalt mixtures, whether according to the Marshall method or the Superpave method. The most important of these tests are:',
                    text3: {
                        text: 'To design asphalt mixtures, the following preparatory steps can be followed:',
                        1: 'Selection of aggregate materials',
                        2: 'Selection of gradation',
                        3: 'Determination of aggregate proportions',
                        4: 'Determination of the specific gravity of aggregates',
                        5: 'Specific gravity of bitumen',
                        6: 'Preparation of test samples',
                        7: 'Determination of density and air voids percentage for samples',
                        8: 'Determination of the original bitumen content',
                    },
                    text4: {
                        text: 'Some of the most important tests conducted by AlMekyal AlSaudi Laboratories on asphalt mixtures include:',
                        1: 'Test for determining bitumen content in asphalt mixture (Ignition Method). This test is used to audit and determine the bitumen content in asphalt mixtures used by contractors in paving projects, ensuring the bitumen content meets design specifications. There are two methods for extraction: the modern ignition method and solvent extraction.',
                        2: 'Test for determining the maximum density of asphalt mixture (GMM)',
                        3: 'Test for determining the maximum density of asphalt mixture (GMM)',
                        4: 'Test for determining the stability and flow of Marshall-compacted cylindrical samples using the Marshall Compaction Device and measuring flow using the Marshall flow meter.',
                    },
                    text5: {
                        text: 'We also provide supervision services during execution and quality control (QC) monitoring for the production of asphalt mixtures. Additionally, we perform field and laboratory tests to ensure quality control in the execution of paving work.',
                        1: 'Field tests for determining the compaction ratio of the asphalt mixture using a nuclear gauge.',
                        2: 'Determining the thickness of asphalt layers using the core machine.',
                    },
                },
                // 3
                bitumenText:'There are a number of tests to assess the properties of bituminous materials. The following tests are usually conducted to evaluate different properties of bituminous material:',
                penetration:'Penetration Test',
                viscosity:'Kinematic Viscosity of Asphalts Test',
                ductility:'Ductility Test',
                softeningPoint:'Softening Point Test',
                flashAndFire:'Flash and Fire Point Test',
                specificGravity:'Specific Gravity Test',
                solubility:'Water content Test',
                volatility:'Loss on heating Test',
                // 4
                concrete: {
                    text1: 'Concrete is one of the most important construction materials used in all civil structures worldwide, whether they are buildings, bridges, pavements, or other structures.',
                    text2: 'There are various types of concrete depending on its use, resistance, setting time, cement content, etc.',
                    text3: {
                        text: 'AlMekyal AlSaudi Laboratories design concrete mixes and perform all related tests on concrete. The concrete mix design is carried out using all global design methods on:',
                        one: {
                            name: 'Aggregates Material Tests',
                            1: 'Sieve analysis of aggregates',
                            2: 'Fineness modulus',
                            3: 'Sand equivalent test',
                            4: 'Bulk density of sand (Cone test)',
                            5: 'Aggregate resistance to wear (Los Angeles test)',
                        },
                        two: 'Determine the water-to-cement ratio that provides the best possible resistance.',
                    },
                    text4: {
                        text: 'Concrete mix verification tests and the most important of these tests:',
                        firstName: 'Workability of Concrete',
                        firstText: 'There are multiple methods to measure workability, including the Abrams cone method (slump test), flow table test, and compaction factor test. However, the most widely used and common method is the Abrams cone, which is used both in the laboratory and on-site (workshop).',
                        secondName: 'Mechanical Resistance of Concrete',
                        secondText: 'By conducting the concrete compressive strength test – breaking standard cylindrical or cubic samples after 7 days and 28 days to determine the compliance of the poured concrete with specifications.',
                    }
                },                
                // 5
                block:{
                    1:'Cement block stress test',
                    2:'Abrasion resistance test for tiles',
                    3:'Flexural strength tests of tiles',
                },

                // services3
                chemical:{
                    text:'AlMekyal AlSaudi Laboratories perform chemical analysis tests for soil and water:',
                    1:'PH determination test according to BS1377',
                    2:'Sulfate determination test.  According to BS1377',
                    3:'Chloride determination test according to BS1377',
                    4:'Soil salinity test - total dissolved salts according to BS1377',
                },

                // service4
                buildingsService:{
                    1:{
                        header: 'First - CONCRETE CORE SAMPLING- ASTM C-42:',
                        text: 'This test method provides standardized procedures for obtaining and testing specimens to determine the compressive, splitting tensile, and flexural strength of in-place concrete. To have an idea about the concrete strength used in different elements of the buildings, this test is carried out according to special specifications in the Saudi Code (SBC), and (ASTM), and British Standards (BS). In addition, according to the requirements of the American Concrete Institute (ACI), a special machine is used to take concrete samples, equipped with drill bits of different diameters ranging from 3 inches to 6 inches.',
                        text2: 'After the concrete samples are taken from the structural elements, technicians transfer the samples to the laboratory safely using special sample boxes.',
                    },
                    2: {
                        header: 'Secondly - Rebound Hammer Test (Schmidt) ASTM C805:',
                        text: `The Schmidt Hammer test on concrete is one of the well-known tests and is classified as a non-destructive test. This test is used to obtain an approximate value of the concrete’s compressive strength or to provide an indication of the concrete's quality.`,
                        text2: `This test is conducted according to the American specification (ASTM-C805), and the concrete's hardness or stiffness can be determined based on the principle of the rebound value from the surface of the hardened concrete section using a spring-loaded hammer.`
                    },
                    3: {
                        header: 'Thirdly - Cover Meter or Profometer Test:',
                        text: 'The purpose of the detection process for reinforcement steel in certain structural elements represented in the building is as follows:',
                        list: {
                        1: 'Identifying the locations of structural elements, such as columns and beams, when the architectural and structural plans are unavailable.',
                        2: 'Identifying the placement of longitudinal and transverse reinforcement in structural elements.',
                        3: 'Determining the thickness of the concrete cover over the reinforcement.',
                        4: 'Identifying the diameters of the reinforcement bars.'
                        }
                    },
                    4: {
                        header: 'Fourthly - Half-Cell Potential Test (ASTM C876-09):',
                        text: 'The half-cell potential test is carried out according to the American specification (ASTM C876-09) and is used to determine whether there is corrosion in the reinforcement steel of structural elements in buildings.',
                        text2: 'This test is based on forming a galvanic circuit on the principle of a battery, where the reinforcement network in the studied area is part of the circuit. Copper sulfate solution is used as the medium, and resistance is measured in millivolts. The Profometer device is used in the half-cell test, and it is one of the latest advanced devices used in this field, as shown in the photographs.'
                    },
                    5: {
                        header: 'Fifthly - Ultrasonic Pulse Velocity Through Concrete Test (ASTM C 597):',
                        text: 'The ultrasonic pulse velocity method is a non-destructive test used to determine the quality of concrete and provide an approximate estimation of its strength. This method can also be used to calculate the elastic modulus and lateral deformation by measuring longitudinal and transverse waves.',
                        text2: `This method depends on measuring the time it takes for ultrasonic waves to pass through the concrete section being studied and, consequently, measuring the velocity of these waves between the transmitter and receiver, either directly or indirectly, or semi-directly (at the angle's edges).`
                    },
                    6: {
                        header: 'Sixthly - Slab Load Test:',
                        text: 'The load test is one of the most important tests used to assess the condition of slabs or ceilings and determine the efficiency of the concrete slab in bearing the design or additional loads. It is used in many cases where building plans are unavailable.',
                        text2: `In this test, the loading elements (slabs, beams, and columns) are selected based on the study's requirements, and here are photos from the loading test in one of the projects executed by the company.`,
                    }
                },

                //service5
                studies:{
                    text1:'Specialized Geotechnical Technical Studies:',
                    text2:'AlMekyal AlSaudi can conduct technical studies related to soil problems, soil improvement and consolidation, slope stability studies, and support for the sides of excavations:',
                    text3:'In addition, ALMekyal Alsaudi Laboratories carry out the following tests and work:',
                    1:'Foundation soil improvement studies : Such as soil replacement studies, and studies of foundation problems on difficult soil, such as swelling soils  such as swelling  clay, swelling rocks, and unstable soils that are subject to collapse.',
                    2:'Cavitations detection studies',
                    3:'Soil injection with cement grout studies',
                    4:'Reinforcement of Underground Excavation studies',
                    5:'Slope studies slope stability studies',
                    6:'Retaining walls studies ',
                    7:'Piles Studies of all types according to the nature of the soil and the project, such as friction piles, fulcrums, and supporting piles',
                    8:'Dewatering studies: dewatering is a term to describe the removal of groundwater or surface water from a construction site',
                    9:'Geophysical survey work using GPR technology, which uses radio waves (electromagnetic) to take images under the Earth’s surface, allowing the detection of ground services',
                    10:'Soil Resistivity measurement',
                },

                ///////Services Page Company
                servicesText1Company: 'Provides a comprehensive range of engineering services, including project supervision, structural and architectural designs, and surveying, water, environmental, and electrical engineering.',
                // servicesText2Company: 'A range of services offered by AlMekyal AlSaudi Engineering Consultancy',
                featuredProjects:'From Our Works',
                include: 'Our Services include',

                // services1
                architectural: {
                    text: 'The Architectural Engineering Department at AlMekyal AlSaudi Engineering Consultancy consists of multiple units working together to deliver integrated architectural designs that meet client needs and adhere to technical and engineering standards. These specialties and team sizes vary based on project type and scale. The core units include:',
                    design: {
                        name: 'Architectural Design Unit:',
                        1: {
                            name: 'Architectural Design:',
                            text: 'This unit is responsible for preparing preliminary designs, developing architectural concepts, and creating initial drawings. Architects in this unit focus on meeting client requirements in terms of aesthetics and functionality while producing high-quality architectural perspectives using the latest architectural and engineering software.',
                        },
                        2: {
                            name: 'Executive Designs:',
                            text: 'Responsible for transforming initial designs into detailed execution drawings, including foundations, structural details, electrical and mechanical systems, and energy efficiency plans.',
                        },
                        3: {
                            name: 'Building Systems Coordination and Follow-Up:',
                            text: 'Handles integrating various building systems such as HVAC, electrical, and plumbing. This requires continuous coordination with specialized engineers and follow-up with municipalities to ensure compliance with building regulations and to obtain preliminary and final construction permits.',
                        },
                        4: {
                            name: 'Interior Design:',
                            text: 'At AlMekyal AlSaudi, we focus on the interior details of buildings, including material selection, colors, furniture, and lighting. Interior designers work closely with architects to ensure harmony between interior and exterior designs, producing comprehensive execution plans.',
                        },
                        5: {
                            name: 'Quantity Surveying and Specifications Preparation:',
                            text: 'This unit plays a critical role in ensuring accurate project cost estimates and budget control. The process involves extracting and analyzing quantity details for all project items, including concrete, masonry, finishes, electrical systems, HVAC, and more. It requires high precision in reviewing drawings and specifications to ensure comprehensive and accurate estimates.',
                        },
                    },
                    urban: {
                        name: 'Urban Planning Services',
                        text: 'The Urban Planning Unit typically comprises multiple subunits working in harmony to achieve comprehensive project planning while considering environmental, social, and economic aspects. The main components of this unit include:',
                        1: {
                            name: 'Urban and Rural Planning:',
                            text: 'The company specializes in city planning aimed at organizing land use and distributing resources and services to create sustainable and balanced urban or rural environments that meet residents’ needs. This includes developing suitable infrastructure, enhancing quality of life, supporting local economies, and preserving the environment.',
                        },
                        2: {
                            name: 'Land Use Distribution:',
                            text: 'Involves dividing land for residential, commercial, industrial, and agricultural purposes, as well as allocating open spaces and green areas. The goal is to balance various activities, reduce congestion, and provide a conducive environment for living and working.',
                        },
                        3: {
                            name: 'Detailed Planning:',
                            text: 'Focuses on determining detailed land use plans for urban neighborhoods, proposing plot subdivisions, population and building density controls, building regulations, road and sidewalk widths, pedestrian pathways, parking areas, and public services. It also includes planning utility network routes and other infrastructure.',
                        },
                        4: {
                            name: 'Private Land Planning:',
                            text: 'Involves planning private lands for residential, commercial, industrial, warehouses, public services, or agricultural retreats. The process includes conducting land surveys, preparing planning studies, assessing owner needs, creating final site plans, and following up with municipal authorities for approvals and implementation.',
                        },
                        5: {
                            name: 'Urban Design:',
                            text: 'The company offers urban design services for public spaces such as parks, squares, streets, and community areas, focusing on enhancing aesthetics and comfort for the community. Urban designers collaborate with architects to ensure integration between public space design and surrounding buildings.',
                        },
                    },
                },                
                // services2
                transport: {
                    text: 'We conduct studies to assess the potential negative traffic impacts of new projects or those arising from the development of existing projects and determine the necessary measures to mitigate these impacts.',
                    1: {
                        text: 'Coordinate with relevant authorities (municipality, traffic department) to obtain the requirements for preparing the traffic study.',
                    },
                    2: {
                        text: 'Assess the impact of the project on the surrounding road network and traffic safety.',
                    },
                    3: {
                        text: 'Identify necessary improvements to mitigate impacts and estimate their costs.',
                    },
                    4: {
                        text: 'Evaluate the suitability of the proposed design and identify necessary improvements for project entrances and exits.',
                    },
                    5: {
                        text: 'Prepare a comprehensive traffic report in accordance with the requirements of approved authorities.',
                    },
                    6: {
                        text: 'Obtain traffic study approval from relevant authorities (municipality, traffic department, Ministry of Transport, Royal Commission).',
                    },
                },

                // services3
                infrastructure: {
                    text: 'We provide comprehensive engineering studies and designs for the design and implementation of infrastructure projects in accordance with the highest technical standards.',
                    1: {
                        text: 'Conduct studies and prepare technical specifications for road networks.',
                    },
                    2: {
                        text: 'Design and implement water, sewage, and drainage systems.',
                    },
                    3: {
                        text: 'Design stormwater, electricity networks, and lighting systems.',
                    },
                    4: {
                        text: 'Supervise implementation to ensure quality and compliance with engineering standards.',
                    },
                    5: {
                        text: 'Calculate quantities and items associated with each project to ensure accurate execution and cost efficiency.',
                    },
                },

                // services4
                electrical: {
                    text: 'At AlMekyal AlSaudi Engineering Consulting, we provide comprehensive electrical services, including:',
                    contracts: 'Contracts',
                    team: 'Technical Team',
                    1: {
                        text: 'Design low and medium voltage networks with approved plans from the Saudi Electricity Company.',
                    },
                    2: {
                        text: 'Design and implement road lighting systems in accordance with the specified plans and approval from relevant municipalities.',
                    },
                    3: {
                        text: 'Design electrical works for residential, commercial, and government projects in accordance with Saudi Electricity Company specifications and Saudi Building Code.',
                    },
                    4: {
                        text: 'Supervise the construction and maintenance of distribution networks to ensure their efficiency and safety.',
                    },
                },

                // services5
                evaluation: {
                    text: 'At AlMekyal AlSaudi Engineering Consulting, we provide structural safety evaluation services for buildings and facilities to ensure their safety and sustainability for longer periods. These services help confirm the buildings\' and facilities\' ability to continue performing their function safely and effectively.',
                    text2: 'The importance of these services includes the following cases:',
                    1: {
                        text: 'Rehabilitation of buildings or changing their investment function.',
                    },
                    2: {
                        text: 'Identifying defects in the structural elements of buildings in general.',
                    },
                    3: {
                        text: 'Adding extra floors to an existing building in line with changes in municipal regulations and systems.',
                    },
                    4: {
                        text: 'Restoration and maintenance of historical buildings with cultural significance.',
                    },
                    5: {
                        text: 'Extending the lifespan of facilities, contributing to saving money, effort, and time.',
                    },
                },

                // services6
                gis: {
                    text: 'We provide comprehensive surveying services using the latest technologies and equipment to ensure project execution meets the highest standards.',
                    text2: 'GPS systems used:',
                    text3: 'An overview of the surveying team’s work for AlMekyal AlSaudi on some projects:',
                    1: {
                        text: 'Topographic land surveying and creation of contour lines according to the project’s nature'
                    },
                    2: {
                        text: 'Establishing permanent ground control points using the "GPS" system and documenting them in descriptive cards'
                    },
                    3: {
                        text: 'Integrating maps and surveying works using global or local coordinate systems'
                    },
                    4: {
                        text: 'Road design, preparation of longitudinal and cross sections, and calculation of cut and fill volumes'
                    },
                    5: {
                        text: 'Utilizing aerial surveys and service detection using drones and Ground Penetrating Radar (GPR)'
                    },
                    6: {
                        text: 'Recording and converting survey data into precise drawings through Geographic Information Systems (GIS)'
                    },
                    devices: {
                        1: 'Garmin System',
                        2: 'Leica System',
                        3: 'Trimble System'
                    },
                },                

                // services7
                supervision: {
                    text1: 'First: Engineering supervision of projects:',
                    text2: 'The supervision department provides contract management services to ensure projects are executed according to the required specifications and standards.',
                    text3: 'AlMekyal AlSaudi supervises a wide range of projects, including roads, facilities, infrastructure, residential plans, buildings, maintenance, and rehabilitation projects.',
                    list: {
                        1: 'Supervision of the construction of residential and commercial buildings and fuel stations',
                        2: 'Supervision of infrastructure projects (roads, water, sewage, electricity, lighting)',
                        3: 'Review and ensure compliance of executed works with design plans',
                        4: 'Preparation of execution schedules',
                        5: 'Holding meetings with relevant parties and reviewing documents',
                        6: 'Preparation of interim and final financial statements',
                        7: 'Preparation of weekly and monthly progress reports',
                        8: 'Participation in site handover committees for preliminary and final acceptance',
                        9: 'Approval of crews and materials used in execution',
                    },
                    text4: 'Second: Project management services:',
                    text5: 'We provide project management services using the latest planning systems to ensure the success of each execution phase. We focus on effective cost control using advanced software.',
                    list2: {
                        1: 'Studying and thoroughly reviewing technical drawings before execution begins',
                        2: 'Preparing a comprehensive cost estimate for materials based on quantity surveys',
                        3: 'Drafting contracts and defining technical terms and standard specifications',
                        4: 'Preparing detailed schedules and monitoring progress to ensure alignment with the plan',
                        5: 'Supervising project execution to ensure efficient and effective results',
                    },
                    text6: 'Project Management:',
                    text7: 'Project Supervision:',
                },

                // services8
                hydrological: {
                    text1: '- We provide comprehensive services in preparing hydrological studies, mitigating flood risks, and urban rainwater drainage. Our team conducts a thorough review of the topography and morphological characteristics of the study areas, identifying and analyzing surrounding or intersecting valleys and waterways.',
                    text2: '- Additionally, we estimate the quantities of rainfall and floodwaters the study area might encounter and design the required open or closed drainage channels using advanced statistical analysis models such as Smada and Hyfra. We also employ satellite imagery morphological modeling and digital elevation models using software like ArcGIS and WMS to accurately extract valley paths.',
                    text3: '- We perform hydrological modeling to extract expected flows and readings for various recurrence intervals using Hec HMS software. We also execute one-dimensional hydraulic modeling with software like Sewer-Gems, Hec-Ras, and Culvert Master, as well as two-dimensional models using Rasmapper and Open Flow.',
                    text4: '- We propose suitable solutions for water drainage from the study area and provide guidelines to safeguard the area from flood risks, taking these into account during the design process.',
                    water: {
                        header: 'Water and Flood Drainage Studies:',
                        text1: '- Our technicians utilize the latest engineering software to ensure precise and executable outputs for all water and flood drainage engineering requirements, including:',
                        text2: '- AlMekyal AlSaudi is one of the certified engineering offices in hydrological studies by the Ministry of Municipal and Rural Affairs and Housing, the Riyadh Municipality, and various governmental entities.',
                        text3: '- Hydrological studies are conducted in accordance with the requirements of the Ministry of Municipal and Rural Affairs and Housing, the Saudi Geological Survey, and international standards such as those set by the US Geological Survey, the British Geological Society, and the International Association of Hydrogeologists.',
                        point1: 'Preparation of design and execution plans for water networks and drainage channels',
                        point2: 'Material testing and ensuring suitability for execution',
                    },
                    studies: {
                        text: 'AlMekyal AlSaudi has conducted numerous hydrological studies, including:',
                        list: {
                            1: 'The hydrological study of approved layouts in Afif Governorate',
                            2: 'The hydrological study of approved layouts in Al-Majmaah Governorate',
                            3: 'The hydrological study of approved layouts in Tamir Governorate',
                            4: 'The hydrological study of Al-Olayan layout in Al-Aarid, north of Riyadh',
                            5: 'The hydrological study of Umm Salim residential village layout in Murat Governorate',
                            6: 'The hydrological study of Al-Mughzarat layout in Riyadh – Ouj Development and Real Estate Investment Company',
                        }
                    },
                },                

                ///////Clients page
                ourClients:'Our Clients',
                clientsHeader:'Clients trust is the foundation of our success.',
                clientTextCompany1: "We take pride in serving a diverse range of clients across both governmental and private sectors. Our excellence lies in delivering innovative and efficient engineering solutions that cater to the needs of projects both large and small.",
                clientTextCompany2: "Whether the client is a government entity aiming to achieve its developmental goals or a private company seeking to excel in its projects, we are committed to providing the highest standards of quality and innovation at every stage of execution.",
                clientTextCompany3: "Our diverse expertise across various engineering fields makes us the first choice for many leading institutions in the Kingdom.",
                ourPartners: "Our Partners",
                clientTextCompany4: "We work closely with several international companies specializing in engineering consultancy. These partnerships enable us to offer advanced engineering consultations and successfully execute projects in various sectors such as infrastructure, design, and environmental fields.",
                clientTextCompany5: "We continually strive to expand our network of partners to ensure access to the latest technologies and global expertise.",
                clientsCompanyGov: {
                    client1:'',
                    client2:'',
                    client3:'',
                    client4:'',
                    client5:'',
                    client6:'',
                    client7:'',
                    client8:'',
                    client9:'',
                    client10:'',
                    client11:'',
                    client12:'',
                    client13:'',
                    client14:'',
                    client15:'',
                    client16:'',
                },
                clientsCompanyPri: {
                    client1:'',
                    client2:'',
                    client3:'',
                    client4:'',
                    client5:'',
                    client6:'',
                    client7:'',
                    client8:'',
                    client9:'',
                    client10:'',
                    client11:'',
                    client12:'',
                    client13:'',
                    client14:'',
                    client15:'',
                    client16:'',
                },
                clientsLabsGov: {
                    client1:'SAUDI WATER AUTHORITY',
                    client2:'MODON',
                    client3:'Ministry of municipal rural affairs and housing',
                    client4:'Ministry of environmental water and agricultural ',
                    client5:'Ministry of transport and logistic services ',
                    client6:'Ministry of Defense',
                    client7:'Ministry of education',
                    client8:'Riyadh region municipality ',
                    client9:'Madinah regional municipality ',
                    client10:'Eastern Province Municipality',
                    client11:'Holy Makkah municipality ',
                    client12:'Holy Makkah municipality ',
                    client13:'Presidency of State Security',
                    client14:'National housing company',
                    client15:'Saudi electricity company',
                    client16:'National water company',
                    client17:'Saudi Arabia railways ',
                    client18:'Royal Commission for Jubail and Yanbu',
                },
                clientsLabsPri: {
                    client1:'ROSHN GROUP',
                    client2:'SYSTRA COMPANY',
                    client3:'EGEC - House of Expertise & Engineering consultations',
                    client4:'FIRST PROJECTS COMPANY',
                    client5:'WATHBAH INVESTMENT',
                    client6:'CONSTRUCTION AND PLANNING CO. LTD',
                    client7:'CAP FRANCE BAT GENERAL CONTRACTING',
                    client8:'FREYSSINET SAUDI ARABIA',
                    client9:'Abdul Ilah Al-Muhanna Professional Consulting Company',
                    client10:'Design Dimension Office for Engineering Consultations',
                    client11:'AL-AMR GROUP COMPANY FOR ENGINEERING CONSULTANCY',
                    client12:'East Consulting Engineering Company',
                    client13:'Methaq Contracting Company',
                    client14:'Samir Abu Ghalia office Consulting engineering',
                    client15:'Fahd Saleh Al-Rashudi Engineering Consulting Office',
                    client16:'OMRANIYAT CONSULTING ENGINEERING',
                    client17:'ASAS ENAS REAL ESTATE CO.',
                    client18:'AL DARAH REAL ESTATE DEVELOPMENT CO',
                    client19:`Ta'ahudat holding group`,
                    client20:'Zawaya Properties Co.',
                    client21:'Nojoom Alsalam Holding Co',
                    client22:'Alshurfa for development and investment co.',
                    client23:'Al Bawardi Engineering Consulting Office',
                },

                /////////Quality page
                qualityHeader:'Our policies focus on maintaining quality and safety to enhance sustainable development',
                quality:{
                    quality:'Quality & Development Al-Mekyal Al-Saudi',
                    text1:'Quality control systems have evolved alongside the development of methods and equipment implemented to support them, driven by the growing need for effective quality control systems. AlMekyal AlSaudi has been committed to adopting an advanced program to keep up with these systems, which has played a significant role in interacting with quality control systems, ensuring their success, and enhancing their performance level.',
                    text2:'Al-Mekyal Al-Saudi has a quality system based on : ',
                    iso1Name:'Quality Management System (ISO 9001:2015) : ',
                    iso1Sentence:'It is an international standard dedicated to Quality Management Systems (QMS) that aims to improve quality for any institution/organization/department that aspires to provide services that continually meet customer requirements and expectations in the most efficient manner.',
                    iso2Name:'Occupational Safety and Health Management System (ISO 45001-2018) : ',
                    iso2Sentence:'which is a basic requirement in implementing safety and health procedures for all workers and employees in the facility and at work sites.',
                    iso3Name:'Environmental Management System ISO (14001-2015) : ',
                    iso3Sentence:'It is an internationally agreed standard that helps organizations improve their environmental performance through more efficient use of resources and reducing waste and obtaining a competitive advantage and customer confidence.',
                    iso4Name:'Quality system for general requirements for the competence of testing and calibration laboratories (ISO 17025 -2017)',
                    iso4Text:' Requirements of the Saudi Accreditation Center (SAC) :',
                    iso4Sentence:'ISO/IEC 17025 is more specific in requirements for competence and applies directly to those organizations that produce testing and calibration results and is based on more technical principles. Laboratories use ISO/IEC 17025 to implement a quality system aimed at improving their ability to consistently produce valid results.Material in the standard also forms the basis for accreditation from an accreditation body.',
                    iso4Sentence2:'ALMekyal Alsaudi Labs hold a certificate of competency for testing laboratories in accordance with Specification 17025 from the Saudi Accreditation Center (SAAC) in the Kingdom of Saudi Arabia as a neutral and accredited body.',
                    qualitySystem:'Quality system in AlMekyal AlSaudi is based on the application of:',
                    objectives:{
                        name:'QUALITY OBJECTIVES',
                        text:'Our goal is to continuously measure and improve processes that contribute to our effectiveness and productivity in order to:',
                        text2:'Implement and maintain an international recognized Management System based on ISO/IEC 17025 and ISO 9001 standards and complies with the requirement of the Accreditation, Certification Bodies, statutory and regulation requirements.',
                        1:'Provide good professional practice and best quality of testing services',
                        2:'Satisfaction of our customers at the shortest possible time',
                        3:'Improve qualification and competence of employee',
                        4:'Maintain the highest standard of testing and engineering services ,maintain properly equipments.',
                        5:'Maintain the highest standard of impartiality, integrity and confidentiality of customer information.',
                    },
                    policy:{
                        name:'QUALITY POLICY',
                        text:"lmekyal Alsaudi is committed to achieving and maintaining the highest quality standards in the testing and engineering services.  This commitment is aimed at improving and developing its performance to preserve its distinguished reputation, support the economy, and contribute to meeting the needs of the Saudi market.  Almekyal Alsaudi believes that adhering to a QMS meets the company's needs and aligns with customer expectations and is a key factor in sustaining success. This is achieved through:",
                        1:"Almekyal Alsaudi Management's commitment to neutrality, ensuring that all clients trust the services provided are based on neutrality, efficiency, and confidentiality.",
                        2:'Almekyal Alsaudi dedication to always focusing on customer requirements and improving services to achieve customer satisfaction.',
                        3:'Almekyal Alsaudi commitment to continuous improvement in customer service, quick response, and addressing customer requests, while maintaining the confidentiality of their data and information, and meeting applicable legal and regulatory requirements in accordance with the business fields',
                        4:'Almekyal Alsaudi commitment to providing a healthy and suitable work environment that ensures employees are not subjected to any form of pressure, including financial or any other pressures that could negatively impact the results or quality of their work.',
                        5:"Almekyal Alsaudi commitment to applying the requirements of international standards (ISO/IEC 9001-14001-17025-45001) with continuous improvement of the company's quality management system.",
                        6:'Almekyal Alsaudi commitment to ensuring that all employees are fully aware of and committed to their responsibilities, as well as to the relationships between all employees, in line with the standard operating procedures outlined in the quality manual.',
                        7:'Almekyal Alsaudi commitment to ensuring that services and tasks are carried out by a qualified and adequately trained team, following the approved testing methods in accordance with standard specifications and relevant government bodies in the Kingdom of Saudi Arabia.',
                        8:'Almekyal Alsaudi commitment to providing development opportunities for employees to enhance their skills and gain the necessary experience.',
                        9:'Almekyal Alsaudi commitment to complying with the legislative or regulatory laws specific to the Kingdom of Saudi Arabia.',
                    },
                    code:{
                        name:'CODE OF ETHICS',
                        intro:'Introduction',
                        text:'The Code of Ethics is a pledge to members of the profession through a statement of collective wisdom that reflects the experience of many members and their agreement to a set of obligations. The code also ensures collective responsibility.',
                        text2:'All Employees in almekyal alsaudi are committed to this Code of Ethics',
                        1:{
                            header:'The code itself has several roles:',
                            1:'Promotes worthy practices ',
                            2:'Statement of shared commitment of members of the profession ',
                            3:'Statement of agreed values ',
                            4:'Statement of agreed rules',
                            5:'Sensitizes members to important issues ',
                            6:'Mechanism for educating for those entering the profession, companies and clients',
                        },
                        2:{
                            header:'Integrity and general ethics:',
                            1:'We act with faith, honesty and fairness ',
                            2:'We do what we said. ',
                            3:'We deliver our services in accordance with established contracts and defined actions. ',
                            4:'We follow the policies and procedures of the laboratory. ',
                            5:'We respect the confidentiality of business and data ',
                            6:'We respect and apply professional standards, local ethics of our activities.',
                            7:'We respect our obligations and responsibilities regarding to environment',
                            8:'We respect our obligations and responsibilities regarding to occupational health and safety',
                        },
                        3:{
                            header:'Impartiality and independence:',
                            1:'We are happy that all our work is carried out impartially',
                            2:'We give impartial and unbiased professional advice.',
                            3:'We write reports that accurately reflect our impartiality according to our best practices.',
                            4:'We neither accept nor to comply with any pressure of any kind',
                        },
                        4:{
                            header:'Confidentiality:',
                            1:'We keep all information collected during our services and remain confidential, unless they have been the subject of authorized disclosure.',
                            2:'We take the necessary measures to protect the information available to us, respecting this confidentiality commitment in our personal capacity, and taking care to do so.',
                            3:'We ensure that the protection of confidential information is effectively ensured by the establishment of adequate security measures, ',
                            4:'We ensure the restriction of confidential information. through the access to authorized persons only ',
                            5:'We ensure physical protection of documents.',
                        },
                    },
                    certificates:'Quality and accreditation certificates in Al-Mekyal Al-Saudi :',
                    certificate1:'Quality Management System ISO 9001:2015 ',
                    certificate2:'Occupational Safety and Health Management System ISO 45001-2018',
                    certificate3:'Environmental Management System ISO 14001-2015',
                    certificateSac: {
                        text:'Certificate of Competency for testing Laboratories in Accordance with Specification 17025 from the Saudi Accreditation Center (SAAC):',
                        riyadh:'Almekyal Alsaudi lab in Riyadh',
                        khobar:'Almekyal Alsaudi lab in Al-Khobar',
                        madinah:'Almekyal Alsaudi lab in Al-Madinah',
                    }
                },
                
                /////////Workshops page
                workshopHeader:'We believe in the importance of knowledge exchange for sustainable development.',
                workshops: {
                    text1: 'At AlMekyal AlSaudi, we place great emphasis on continuous knowledge development and transfer. We are committed to organizing regular workshops aimed at enhancing the exchange of expertise and knowledge, contributing to higher professionalism, fostering innovation, and developing skills. We also strive to expand the benefit of this expertise to include others.',
                    text2: 'We have implemented a variety of workshops, most notably:',
                    workshop1: 'A glimpse of the workshops held at the headquarters of the Riyadh Region Municipality Agency for Municipality Affairs, titled "The Importance of Hydrological and Geotechnical Studies."',
                    workshop2: 'A glimpse of the workshops held at the headquarters of the Ministry of Environment, Water, and Agriculture to introduce the project for the study and preparation of the Saudi Code for Water Resources and Their Uses.',
                },  
                trainingLabs: {
                    text1:'Training, transferring knowledge and experiences between individuals and technical and administrative cadres and documenting them in ALMekyal Alsaudi  Labs Group is one of the most important development and performance improvement strategies on which the quality policy is based.',
                    text2:'WHAT ARE THE OBJECTIVES OF TRANSFERRING KNOWLEDGE IN ALMEKYAL ALSAUDI LABS GROUP?',

                    section2: {
                        goal1:'The main objective of a knowledge transfer ',
                        goalSentence1:'It is transfer of knowledge from one individual or group to another within an organization. The primary goal of a knowledge transfer plan is to ensure the smooth transition of critical information, skills, and expertise, particularly when there is a change in personnel, roles, or projects.',
                        goal2:'Minimizing risk, future-proofing performance: ',
                        goalSentence2:'The main objective of a knowledge transfer plan is to minimize the risks associated with the loss of talent and knowledge in an organization.',
                        goal3:'Enhancing Decision-Making',
                        goalSentence3:`Effective transfer of knowledge empowers employees with the information they need to make informed decisions. 
                        When knowledge is shared across the organization, decision-makers have access to diverse insights and historical data, enabling them to consider multiple perspectives and make more well-rounded choices.`,
                        goal4:'Reducing Redundancy',
                        goalSentence4:`Effective transfer of knowledge empowers employees with the information they need to make informed decisions. 
                        When knowledge is shared across the organization, decision-makers have access to diverse insights and historical data, enabling them to consider multiple perspectives and make more well-rounded choices.`,
                        goal5:'Improving Employee Onboarding & Training',
                        goalSentence5:'Knowledge transfer is vital for onboarding new employees and providing ongoing training',
                        goal6:'Ensuring Business Continuity',
                        goalSentence6:'Effective knowledge transfer ensures that critical information is not lost when employees leave the organization.',
                        goal7:'Improve Customer Satisfaction:',
                        goalSentence7:'When employees are well-informed and can access relevant information quickly, they are better equipped to meet customer needs and resolve issues promptly.',
                    },
                    text3:'KNOWLEDGE AND TRAINING ARE TRANSFERRED IN ALMEKYAL ALSAUDI LABS GROUP THROUGH',
                    section3: {
                        text1:'Preparing an annual training plan and documenting its procedures.',
                        text2:'Holding internal training courses periodically.',
                        text3:'Organizing workshops inside the facility periodically.',
                        text4:'External training courses to raise the level of staff in the laboratory.',
                        text5:'Active participation in courses and workshops with governmental, semi-governmental and private agencies.',
                        text6:'Relying on electronic database programs to preserve and document results, documents, procedures, knowledge and experiences.',
                    },
                },          
                
                ////////NotFound Page
                notfoundHeader: 'Page Not Found',
                notfoundText: `Sorry, we can't find the page you're looking for`,
                notfoundBtn: 'Go Home',
            },
        },
    }
})