import React, { useContext, useEffect, useState } from 'react'
import style from './Layout.module.css'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import labLogo from '../../assets/images/logo-mekyal-saudi.webp'
import infoLogo from '../../assets/images/Picture1-150x150.webp'
import logos from '../../assets/images/Picsart_25-02-24_14-36-44-480.png'
import { LangContext } from '../context/LangContext'
import i18n from 'i18next'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Helmet } from 'react-helmet';
export default function Layout() {

  let {language, setLanguage}= useContext(LangContext)
  const [show, setShow] = useState('')
  const [sitePage, setSitePage] = useState(false)
  const site = sessionStorage.getItem('desiredPage')
  const location = useLocation()

    //selection or site
    useEffect(() => {
      if (site===null) {
        setSitePage(false)
      } else {
        setSitePage(true)
      }
    }, [site])

    //choose language
  const toggleLanguage = () => {
      const newLanguage = language === 'en' ? 'ar' : 'en'
      i18n.changeLanguage(newLanguage)
      setLanguage(newLanguage)
    }
  
    // what page?
    useEffect(() => {
      if (location.pathname === "/select-site") {
        sessionStorage.removeItem('desiredPage')
        setSitePage(false)
      } 
      if (location.pathname === "/comingSoon") {
        setShow(false)
      } else {
        setShow(true)
      }
      
      window.scrollTo(0, 0);
    }, [location])
    
    //prevent copy
      const handleCopy = (event) => {
        event.preventDefault();
        alert("Copying is disabled.");
      };
    
    //prevent cut
      const handleCut = (event) => {
        event.preventDefault();
        alert("Cutting is disabled.");
      }

  return <>
  <Helmet>
    <link rel="icon" href={site === null ? logos : site === 'company' ? infoLogo : labLogo}/>
  </Helmet>
  {!sitePage? '':<FloatingWhatsApp
      phoneNumber={site==='company'? '+966558213234':'+966501533348'} 
      accountName="دعم العملاء" 
      chatMessage="مرحبًا! كيف يمكنني مساعدتك؟ 😊" 
      allowClickAway 
      notification 
      notificationSound
      avatar={site==='company'? infoLogo:labLogo}
    />}
    <div className={`${language === 'en' ? 'roboto-font' : 'cairo-font'} no-select`} onCopy={handleCopy} onCut={handleCut}>
      {!show || !sitePage? '':<Navbar/>}
        <span onClick={toggleLanguage} className={`position-fixed py-1 px-2 ${style.zhightest} text-white ${style.top30vh} ${style.bgLang} ${language === 'en' ? 'cairo-font fw-bold end-0' : 'roboto-font fw-semibold'} cursor-pointer`}>
          {language === 'en' ? 'عر' : 'En'}
        </span>
        <Link to={'/select-site'} className={`position-fixed py-1 px-2 ${style.zhightest} text-white ${style.top37vh} ${style.bgLang} ${language === 'en' ? 'cairo-font fw-bold end-0' : 'roboto-font fw-semibold'} cursor-pointer`}>
          <i className='fa-solid fa-house'></i>
        </Link>
        <div className={`position-relative ${site===null? 'h-91 flex-column d-flex align-items-center justify-content-between': !show? '':' mt-5rem'}`}>
          <Outlet></Outlet>
        </div>
        {!show || !sitePage? <span className={`d-flex align-items-center ${!show? (`text-white ${site==='company'? 'bg-comingsoon' : 'bg-main'}`):'text-black'} roboto-font vh-9 justify-content-center w-100 small text-center`}>Copyright © ElMekyal AlSaudi 2024 Powered by&nbsp;
          <Link className={`fw-semibold ${!show? 'text-white':'text-black'}`} to={'https://mas-itc.com'} target='_blank'> MAS-ITC</Link>
        </span>:<Footer/>}
        {/* {isVisible? <button onClick={scrollToTop} className={`btn bg-main2 rounded-circle position-fixed d-flex align-items-center cursor-pointer ${style.zhightest} ${style.toTopPostion} justify-content-center p-2`}>
          <i className="fa-solid fa-chevron-up fs-5 text-white"></i>
        </button>:''} */}
    </div>
  </>
}
