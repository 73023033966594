import React, { useContext } from 'react'
import style from './Electrical.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from './../context/LangContext';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import { Link } from 'react-router-dom';
import project from '../../assets/images/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import contract1 from '../../assets/images/ترسية الطائف.webp'
import contract2 from '../../assets/images/إشعار ترسية (منطقة الرياض).webp'
import contract3 from '../../assets/images/إشعار ترسية (الخرج).webp'
import team1 from '../../assets/images/الطاقم الفني لمشروع كهرباء الطائف.webp'
import team2 from '../../assets/images/الطاقم الفني لمشروع كهرباء منطقة الرياض.webp'
import team3 from '../../assets/images/الطاقم الفني لمشروع كهرباء الخرج.webp'

export default function Electrical() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
  
  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navElectrical')}</p>
      </div>
    </div> 
    <div className='container-md w-90 mx-auto pb-5'>
      <p className='pt-5 fs-5 fw-bold text-justify'>{t('electrical.text')}</p>
      <ul className={`pt-4 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
        <li className='py-3 text-justify'>
          <p className='fw-medium'>{t('electrical.1.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p className='fw-medium'>{t('electrical.2.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p className='fw-medium'>{t('electrical.3.text')}</p>
        </li>
        <li className='py-3 text-justify'>
          <p className='fw-medium'>{t('electrical.4.text')}</p>
        </li>
      </ul>
      <div className="d-flex justify-content-center">
        <p className='pt-2 mb-2 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
      </div>
      <div className='d-flex justify-content-end py-3'>
        <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
      </div>
      <div className="row gx-2 justify-content-center">
        <div className='col-lg-4 col-sm-6'>
          <Link className='position-relative project trans3'>
            <div className='vw-20'>
              <img loading='lazy' className='w-100 h-100 object-center object-cover' src={project} alt="العقد الموحد للأشراف على أعمال إنشاء وصيانة شبكات التوزيع حتى جهد 36 ك.ف" />
            </div>
            <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
              <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>العقد الموحد للأشراف على أعمال إنشاء وصيانة شبكات التوزيع حتى جهد 36 ك.ف</h5>
              <button className='btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center'>{t('details')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <div className="d-flex justify-content-center">
          <p className='pt-5 mb-5 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('electrical.contracts')}</p>
        </div>
        <Slider
          autoplay= {3}
          infinite={false}
          arrows={false}
          dots={false}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
          ]}
        >
          <div className="px-2">
            <div>
              <img loading='lazy' className='w-100' src={contract1} alt="ترسية الطائف" />
            </div>
            <p className='text-center'>عقد المكيال السعودي مع الشركة السعودية للكهرباء <br />(عقد الطائف)</p>
          </div>
          <div className="px-2">
            <div>
              <img loading='lazy' className='w-100' src={contract2} alt="ترسية الرياض" />
            </div>
            <p className='text-center'>عقد المكيال السعودي مع الشركة السعودية للكهرباء <br />(عقد الرياض)</p>
          </div>
          <div className="px-2">
            <div>
              <img loading='lazy' className='w-100' src={contract3} alt="ترسية الخرج" />
            </div>
            <p className='text-center'>عقد المكيال السعودي مع الشركة السعودية للكهرباء <br />(عقد الخرج)</p>
          </div>
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-5'>
      <div className="d-flex justify-content-center">
        <p className='pt-5 mb-5 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('electrical.team')}</p>
      </div>
      <Slider
        autoplay= {3}
        infinite={false}
        arrows={false}
        dots={false}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ]}
    >
        <div className="px-2">
          <div>
            <img loading='lazy' className='w-100' src={team1} alt='الطاقم الفني بالطائف' />
          </div>
          <p className='text-center'>الإدارة الفنية للطاقم المؤهل لدي الشركة السعودية للكهرباء بالطائف</p>
        </div>
        <div className="px-2">
          <div>
            <img loading='lazy' className='w-100' src={team2} alt='الطاقم الفني بالرياض' />
          </div>
          <p className='text-center'>الطاقم الفني المؤهل لدي الشركة السعودية للكهرباء بمنطقة الرياض</p>
        </div>
        <div className="px-2">
          <div>
            <img loading='lazy' className='w-100' src={team3} alt='الطاقم الفني بالخرج' />
          </div>
          <p className='text-center'>الطاقم الفني المؤهل لدي الشركة السعودية للكهرباء بالخرج</p>
        </div>
      </Slider>
    </div>
  </div>
  </>
}
